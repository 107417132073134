import userEvent from '@testing-library/user-event';
import React from 'react';
import './sideDrawer.css';
export default function SideDrawer(props) {
  return (
    <div
      className={props.sideDrawer ? 'sideDrawer show' : 'sideDrawer'}
      style={{ zIndex: 9999999, minHeight: '80vh' }}
    >
      <div className="sidedrawer__box">
        {/* <h5>STATION</h5> */}
        <button
          onClick={() => {
            props.pecuTerminal();
          }}
          style={{ backgroundColor: '#1d3fad' }}
        >
          PECU Station
        </button>
        {props.user && props.user.exception === 'dbt' ? null : (
          <>
            <button
              onClick={() => {
                props.showCryptoIndexLedger();
              }}
              style={{ backgroundColor: 'brown' }}
            >
              Crypto Station
            </button>
            <button
              onClick={() => {
                props.showNftStation();
              }}
              style={{ backgroundColor: 'green' }}
            >
              NFT Minting{' '}
            </button>
            <button
              onClick={() => {
                props.showVault();
              }}
              style={{ backgroundColor: '#0E5374' }}
            >
              MVault
            </button>
            <button
              style={{ backgroundColor: 'brown' }}
              onClick={() => {
                props.showValidator();
              }}
            >
              Validator Node
            </button>
          </>
        )}
      </div>
      {props.user && props.user.exception === 'dbt' ? null : (
        <div className="sidedrawer__box">
          {/* <h5>LEDGERS</h5> */}
          {/* <button onClick={()=>{props.showPublicLedger()}} style={{backgroundColor:'#0095C8'}} >Public Ledger</button> */}
          <button
            onClick={() => {
              props.showNodeLedger();
            }}
            style={{ backgroundColor: '#0095C8' }}
          >
            Public Ledger
          </button>
          <button
            onClick={() => {
              props.showTokenLedger();
            }}
            style={{ backgroundColor: '#0095C8' }}
          >
            Token Ledger
          </button>
          <button
            onClick={() => {
              props.showSendCoin();
            }}
            style={{ backgroundColor: '#0095C8' }}
          >
            Transfer PECU
          </button>
          <button
            onClick={() => {
              props.showTransferWraptoken();
            }}
            style={{ backgroundColor: '#0095C8' }}
          >
            Transfer Token
          </button>
          <button
            onClick={() => {
              props.showTransferConversiontoken();
            }}
            style={{ backgroundColor: '#0095C8' }}
          >
            Transfer Project Token
          </button>
          {props.user.tier === 0 && (
            <button
              onClick={() => {
                props.showTokenContainer();
              }}
              style={{ backgroundColor: '#0095C8' }}
            >
              Wrapped Tokens
            </button>
          )}
          {props.user.tier === 0 && (
            <button
              onClick={() => {
                props.showProjectTokenContainer();
              }}
              style={{ backgroundColor: '#0095C8' }}
            >
              Project Tokens
            </button>
          )}
          {props.user.tier === 0 && (
            <button
              onClick={() => {
                props.showFanTokenContainer();
              }}
              style={{ backgroundColor: '#0095C8' }}
            >
              Fan Tokens
            </button>
          )}
          {props.user.tier === 0 && (
            <button
              // onClick={() => {
              //   props.showProjectTokenContainer();
              // }}
              style={{ backgroundColor: '#0095C8' }}
            >
              Meme Tokens
            </button>
          )}
          {props.user.tier === 0 && (
            <button
              onClick={() => {
                props.showHoldingTokenContainer();
              }}
              style={{ backgroundColor: '#0095C8' }}
            >
              Holding Tokens
            </button>
          )}
          {props.user.tier === 0 && (
            <button
              onClick={() => {
                props.showConversionTokenContainer();
              }}
              style={{ backgroundColor: '#0095C8' }}
            >
              Conversion Tokens
            </button>
          )}
        </div>
      )}

      <div className="sidedrawer__box">
        {/* <h5>USER PANEL</h5> */}

        <button
          onClick={() => props.showQr(true)}
          style={{ backgroundColor: 'grey' }}
        >
          QR Address
        </button>
        <button
          onClick={() => props.showSetting(true)}
          style={{ backgroundColor: 'grey' }}
        >
          Settings
        </button>
      </div>
    </div>
  );
}

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import url from '../../server_url';
import Statements from './statements';
import logo from '../../Assets/icon.ico';
export default function Settingetting({ userData, privateKey }) {
  const [email, setEmail] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [mobile, setMobile] = useState('');
  const [tabs, setTabs] = useState(0);
  const [publicKey, setPublicKey] = useState(userData.pub_key);
  const [copedText, setCopiedtext] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [keys, setKeys] = useState({ access_key: '' });

  useEffect(() => {
    setEmail(userData.email);
    axios
      .get(`${url}/user/${userData.user_id}`)
      .then((res) => {
        let user = res.data[0];
        setFirstname(user.name);
        setLastname(user.last_name);
        setAddress(user.address);
        setCity(user.city);
        setCountry(user.country);
        setMobile(user.mobile);
        setPhone(user.phone);
        setPublicKey(user.pub_key);
      })
      .catch((err) => console.log(err));
  }, []);
  useEffect(() => {
    if (userData && userData.user_id) {
      axios
        .get(`${url}/wallet/keys/${userData.user_id}`)
        .then((res) => {
          setKeys(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [userData]);

  const handleResetPassword = () => {
    if (newPassword !== confirmPassword) {
      alert('Password did not match');
      return;
    }
    axios
      .post(`${url}/user/passwordReset`, {
        email: email,
        password: newPassword
      })
      .then((res) => {
        if (res.data && res.data.message) {
          alert(res.data.message);
          setNewPassword('');
          setConfirmPassword('');
          setTabs(0);
        }
      })
      .catch((err) => {
        alert('Password Change Failed');
      });
  };
  return (
    <div className="sendCoins-main">
      {copedText ? (
        <div
          className="QR-home"
          style={{
            padding: '0px',
            border: 'none',
            maxWidth: '200px',
            maxHeight: '40px',
            zIndex: '9999'
          }}
        >
          <div
            className="header-Qr"
            style={{ padding: '5px', width: '100%', borderRadius: '5px' }}
          >
            <span
              style={{ fontSize: '1rem', color: 'white', fontWeight: '400' }}
            >
              {'key copied'}
            </span>
            <button
              style={{ cursor: 'pointer' }}
              onClick={() => setCopiedtext(false)}
            >
              X
            </button>
          </div>
        </div>
      ) : null}

      <form
        style={{ border: '3px solid #0095C8' }}
        onSubmit={(e) => e.preventDefault()}
      >
        <button
          style={{
            width: '100%',
            backgroundImage: 'none',
            backgroundColor: '#0095C8'
          }}
        >
          USER SETTINGS
        </button>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '90%'
          }}
        >
          <button
            style={{ border: '1px solid #0095C8' }}
            onClick={() => setTabs(0)}
          >
            Profile
          </button>
          <button
            style={{ border: '1px solid #0095C8' }}
            onClick={() => setTabs(1)}
          >
            Change Password
          </button>
          <button
            style={{ border: '1px solid #0095C8' }}
            onClick={() => setTabs(2)}
          >
            Keys
          </button>
          <button
            style={{ border: '1px solid #0095C8' }}
            onClick={() => setTabs(3)}
          >
            Account Statement
          </button>
          <button style={{ border: '1px solid #0095C8' }}>Other Details</button>
        </div>
        <hr
          style={{
            width: '100%',
            height: '3px',
            backgroundColor: '#0095C8',
            border: 'none'
          }}
        ></hr>
        {tabs == 0 ? (
          <>
            {' '}
            <label>Email Address</label>
            <input
              disabled
              value={email}
              style={{
                border: '1px solid #0095C8',
                backgroundColor: 'whitesmoke'
              }}
              placeholder="Email Address"
            />
            <label>First Name</label>
            <input
              value={firstname}
              style={{
                border: '1px solid #0095C8',
                backgroundColor: 'whitesmoke'
              }}
              placeholder="First Name"
            />
            <label>Last Name</label>
            <input
              value={lastname}
              style={{
                border: '1px solid #0095C8',
                backgroundColor: 'whitesmoke'
              }}
              placeholder="Last Name"
            />
            <label>Address</label>
            <input
              value={address}
              style={{
                border: '1px solid #0095C8',
                backgroundColor: 'whitesmoke'
              }}
              placeholder="Address"
            />
            <label>City</label>
            <input
              value={city}
              style={{
                border: '1px solid #0095C8',
                backgroundColor: 'whitesmoke'
              }}
              placeholder="City"
            />
            <label>Country</label>
            <input
              value={country}
              style={{
                border: '1px solid #0095C8',
                backgroundColor: 'whitesmoke'
              }}
              placeholder="Country"
            />
            <label>Phone</label>
            <input
              value={phone}
              style={{
                border: '1px solid #0095C8',
                backgroundColor: 'whitesmoke'
              }}
              placeholder="Phone"
            />
            <label>Mobile</label>
            <input
              value={mobile}
              style={{
                border: '1px solid #0095C8',
                backgroundColor: 'whitesmoke'
              }}
              placeholder="Mobile"
            />
            <button>Submit</button>
          </>
        ) : (
          false
        )}
        {tabs == 1 ? (
          <div className="box-settings">
            <img
              src={logo}
              className="logo-statements"
              style={{ width: '100px', padding: '1rem' }}
            />
            <label>New Password</label>
            <input
              type={'password'}
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              style={{
                border: '1px solid #0095C8',
                backgroundColor: 'whitesmoke'
              }}
              placeholder="Enter New Password"
            />
            <label>Confirm New Pasword</label>
            <input
              type={'text'}
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              style={{
                border: '1px solid #0095C8',
                backgroundColor: 'whitesmoke'
              }}
              placeholder="Confirm New Password"
            />
            <button onClick={handleResetPassword}>Submit</button>
          </div>
        ) : null}

        {tabs == 2 ? (
          <div className="box-settings">
            <img
              src={logo}
              className="logo-statements"
              style={{ width: '100px', padding: '1rem' }}
            />
            <label>Public Key</label>
            <input
              type={'text'}
              disabled
              style={{
                border: '1px solid #0095C8',
                backgroundColor: 'whitesmoke'
              }}
              value={publicKey}
              placeholder="Public Key"
            />
            <button
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigator.clipboard.writeText(publicKey);
                setCopiedtext(true);
              }}
            >
              Copy
            </button>

            <label>Private Key</label>
            <input
              value={privateKey}
              disabled
              type={'password'}
              style={{
                border: '1px solid #0095C8',
                backgroundColor: 'whitesmoke'
              }}
              placeholder="Private Key"
            />
            <button
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigator.clipboard.writeText(privateKey);
                setCopiedtext(true);
              }}
            >
              Copy
            </button>
            <label>Access Key</label>
            <input
              value={keys.access_key}
              disabled
              type={'text'}
              style={{
                border: '1px solid #0095C8',
                backgroundColor: 'whitesmoke'
              }}
              placeholder="Access Key"
            />
            <button
              style={{ cursor: 'pointer' }}
              onClick={() => {
                navigator.clipboard.writeText(keys.access_key);
                setCopiedtext(true);
              }}
            >
              Copy
            </button>
          </div>
        ) : null}
        {tabs == 3 ? (
          <>
            <Statements user={userData} />
          </>
        ) : null}
      </form>
    </div>
  );
}

import React, { useState } from 'react';
import { FaUserShield } from 'react-icons/fa';
import './login.css';
import axios from 'axios';
import logo from '../../Assets/displayicon.png';
import loginRight from '../../Assets/login-right.png';
import url from '../../server_url';
export default function Login(props) {
  const [field, setField] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [loginerror, setLoginerror] = useState(false);
  const handleChange = (event) => {
    setField({ ...field, [event.target.name]: event.target.value });
  };
  const handleSubmit = () => {
    axios
      .post(`${url}/user/login`, field)
      .then((res) => {
        if (res.data.loggedIn == true) {
          localStorage.setItem('pn_secretcookie', JSON.stringify(res.data));
          props.loginData(res.data);
        } else {
          setError(res.data.message);
          setLoginerror(true);
          setTimeout(() => {
            setLoginerror(false);
            setError('');
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleLoginV2 = (target) => {
    axios
      .post(`${url}/api/v2/auth/login`, field)
      .then((res) => {
        if (res.data.token) {
          localStorage.setItem('pn_authtoken', res.data.token);
          if (target === 'wallet') {
            window.location.href = `https://wallet.pecunovus.net/?authToken=${res.data.token}`;
          } else {
            window.location.href = `https://terminal.pecunovus.net/?authToken=${res.data.token}`;
          }
        } else {
          setError(res.data.message);
          setLoginerror(true);
          setTimeout(() => {
            setLoginerror(false);
            setError('');
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
        background: 'linear-gradient(to right, #1F2834 50%, #12181F 50%)',
        position: 'relative'
      }}
    >
      <img
        src={require('../../Assets/leftring.png')}
        style={{
          width: window.innerWidth < 700 ? '200px' : '300px',
          position: 'absolute',
          bottom: 0,
          left: 0
        }}
      />
      <img
        src={require('../../Assets/rightring.png')}
        style={{
          width: window.innerWidth < 700 ? '200px' : '300px',
          position: 'absolute',
          top: -100,
          right: 0
        }}
      />
      {loginerror ? (
        <div
          className="QR-home"
          style={{
            padding: '0px',
            border: 'none',
            maxWidth: '200px',
            maxHeight: '40px',
            zIndex: '9999',
            marginTop: '100px'
          }}
        >
          <div
            className="header-Qr"
            style={{ padding: '5px', width: '100%', borderRadius: '5px' }}
          >
            <span
              style={{ fontSize: '1rem', color: 'white', fontWeight: '400' }}
            >
              {error}
            </span>
            <button
              style={{ cursor: 'pointer' }}
              onClick={() => setLoginerror(false)}
            >
              X
            </button>
          </div>
        </div>
      ) : null}

      <div className="login-card">
        {/* <FaUserShield size={80} className="login-logo" /> */}
        <h3 style={{ color: 'white', textAlign: 'left', width: '80%' }}>
          Access Your Pecu Wallet
        </h3>
        <p
          style={{
            color: 'gray',
            fontSize: '12px',
            textAlign: 'left',
            width: '80%'
          }}
        >
          You will need your Pecu Novus Mobile Wallet to Log in.
        </p>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <label>Email ID</label>
          <input
            required
            placeholder="Email ID"
            name="email"
            onChange={handleChange}
            value={field?.email}
          />
          <lable>Password</lable>
          <input
            required
            autoComplete="off"
            placeholder="password"
            type={'password'}
            name="password"
            onChange={handleChange}
            value={field?.password}
          />

          <div className="extra-info">
            {/* <span>
                            <input type={"checkbox"} defaultChecked />
                            Stay Logged in
                        </span> */}
            <span>Reset Password via security phrases</span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              margin: '1rem 0 0rem 0'
            }}
          >
            <button
              type="submit"
              className="primary loginbtn"
              style={{ flex: 0.5 }}
              onClick={() => handleLoginV2('wallet')}
            >
              Access Wallet
            </button>
            <button
              type="submit"
              className="primary loginbtn"
              style={{
                color: 'white',
                textDecoration: 'none',
                border: '0.5px solid white',
                textAlign: 'center',
                borderRadius: '8px',
                backgroundColor: '#12181F',
                fontSize: '14px',
                flex: 0.45
              }}
              onClick={() => handleLoginV2('terminal')}
            >
              Access Terminal
            </button>
            {/* <button
              style={{
                color: 'white',
                textDecoration: 'none',
                border: '0.5px solid white',
                textAlign: 'center',
                borderRadius: '8px',
                backgroundColor: '#12181F',
                fontSize: '14px'
              }}
              onClick={() => handleLoginV2('terminal')}
            >
              Acess Terminal
            </button> */}
          </div>
          <span
            style={{ width: '100%', textAlign: 'center', fontSize: '10px' }}
          >
            OR
          </span>
          <button className="primary signUpbtn">
            <a
              href="/signup"
              style={{ color: 'white', textDecoration: 'none' }}
            >
              Create New Wallet
            </a>
          </button>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              margin: '1rem 0 0rem 0'
            }}
          >
            <a
              href="/signup"
              style={{
                color: 'white',
                textDecoration: 'none',
                border: '0.5px solid white',
                padding: '0.6rem',
                flex: 0.5,
                textAlign: 'center',
                borderRadius: '8px',
                fontSize: '14px'
              }}
            >
              Learn About Pecu Novus
            </a>

            <button
              style={{
                color: 'white',
                textDecoration: 'none',
                border: '0.5px solid white',
                padding: '0.6rem',
                flex: 0.45,
                textAlign: 'center',
                borderRadius: '8px',
                backgroundColor: '#12181F',
                fontSize: '14px'
              }}
              onClick={() => handleSubmit()}
            >
              Access Legacy Terminal
            </button>
          </div>
          <p style={{ color: '#C2C2C2', fontSize: '12px' }}>
            Connect to the Pecu Terminal from virtually anywhere an internet
            connection is available. Your Pecu Novus Mobile Wallet is your
            Terminal Key which gives you access to the entire Pecu Terminal.{' '}
          </p>
        </form>
      </div>
      {/* <div className="extra-info-banner">
          <p
            style={{
              color: 'white',
              marginTop: '0px',
              fontSize: '12px',
              width: '500px'
            }}
          >
            Connect to the Pecu Terminal from virtually anywhere an internet
            connection is available. Your Pecu Novus Mobile Wallet is your
            Terminal Key which gives you access to the entire Pecu Terminal.
          </p>

          <img src={loginRight} style={{ width: '80%' }} />
        </div> */}
    </div>
  );
}

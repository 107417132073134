import React, { useState } from 'react';
import { FaUserShield } from 'react-icons/fa';
import './login.css';
import axios from 'axios';
import logo from '../../Assets/displayicon.png';
import loginRight from '../../Assets/login-right.png';
import url from '../../server_url';
import { Helmet } from 'react-helmet';
export default function Login(props) {
  const [field, setField] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [loginerror, setLoginerror] = useState(false);
  const handleChange = (event) => {
    setField({ ...field, [event.target.name]: event.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${url}/user/login`, field)
      .then((res) => {
        if (res.data.loggedIn == true) {
          localStorage.setItem('pn_secretcookiee', JSON.stringify(res.data));
          localStorage.setItem('pn_secretcookie', JSON.stringify(res.data));
          props.loginData(res.data);

          axios
            .post(`${url}/user/activateMyNode`, {
              email: res.data.email
            })
            .then((res) => {
              console.log(res.data);
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          setError(res.data.message);
          setLoginerror(true);
          setTimeout(() => {
            setLoginerror(false);
            setError('');
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div style={{ minHeight: '100vh' }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Login</title>
      </Helmet>
      <div
        style={{
          display: 'flex',
          flexFirection: 'row',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {loginerror ? (
          <div
            className="QR-home"
            style={{
              padding: '0px',
              border: 'none',
              maxWidth: '200px',
              maxHeight: '40px',
              zIndex: '9999',
              marginTop: '100px'
            }}
          >
            <div
              className="header-Qr"
              style={{ padding: '5px', width: '100%', borderRadius: '5px' }}
            >
              <span
                style={{ fontSize: '1rem', color: 'white', fontWeight: '400' }}
              >
                {error}
              </span>
              <button
                style={{ cursor: 'pointer' }}
                onClick={() => setLoginerror(false)}
              >
                X
              </button>
            </div>
          </div>
        ) : null}
      </div>

      <div
        className="login-box"
        style={{ flexDirection: 'row', justifyContent: 'space-evenly' }}
      >
        <div className="login-card" style={{ flex: '1' }}>
          {/* <FaUserShield size={80} className="login-logo" /> */}
          <h3 style={{ color: 'white' }}>Log into your Node </h3>
          <p style={{ color: 'gray', marginTop: '0px', fontSize: '12px' }}>
            You need to login to activate the node.{' '}
          </p>
          <form onSubmit={handleSubmit}>
            <label>Email ID</label>
            <input
              required
              placeholder="Email ID"
              name="email"
              onChange={handleChange}
              value={field?.email}
            />
            <lable>Password</lable>
            <input
              required
              autoComplete="off"
              placeholder="password"
              type={'password'}
              name="password"
              onChange={handleChange}
              value={field?.password}
            />

            {/* <div className="extra-info">
                     
                        <span>Forgot Password?</span>
                    </div> */}
            <button type="submit" className="primary loginbtn">
              Login
            </button>
            {/* <span style={{width:'100%',textAlign:'center',fontSize:'10px'}}>OR</span>
                   <button className="primary signUpbtn">
                   <a href="/signup"  style={{color:'white',textDecoration:'none'}} >
                    Create New Wallet
                    </a>
                   </button> */}
            {/* <div className="extra-info">
                        <a href="/signup" className="signup-text">
                            Create New Wallet
                        </a>
                    </div> */}
          </form>
        </div>
      </div>
    </div>
  );
}

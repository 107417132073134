import React, { useEffect, useState } from 'react';
import './header.css';
import logo from '../../Assets/icon.ico';
import {
  IoIosArrowDropdownCircle,
  IoIosArrowDropupCircle,
  IoMdArrowDropdown,
  IoMdArrowDropdownCircle,
  IoMdArrowDropup,
  IoMdArrowDropupCircle
} from 'react-icons/io';
import { CgMenuGridO, CgMenuGridR } from 'react-icons/cg';
import { GiHamburgerMenu } from 'react-icons/gi';
import { MdQrCode2, MdNotificationsNone } from 'react-icons/md';
import { FaClock } from 'react-icons/fa';
import axios from 'axios';
import url from '../../server_url';
export default function Header(props) {
  const [sideDrawer, setSideDrawer] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const userData = props.user;
  const [copedText, setCopiedtext] = useState(false);
  const [user, setUser] = useState([]);
  const [localTime, setLocalTime] = useState(new Date().toLocaleTimeString());
  const [dropDownBox, showDropDownBox] = useState(false);
  const [showLeftMenu, setShowLeftMenu] = useState(true);
  useEffect(() => {
    if (userData) {
      setUser([userData]);
    }
  }, [userData]);

  useEffect(() => {
    setInterval(() => {
      setLocalTime(new Date().toLocaleTimeString());
    }, 1000);
  });

  useEffect(() => {
    props.sideDrawer(sideDrawer);
  }, [sideDrawer]);
  useEffect(() => {
    if (
      window?.location?.pathname ===
      '/75yfjhfhjf7r87tiuhcvbxcdfswrte6tkhkhnbcfstyeut'
    ) {
      setShowLeftMenu(false);
    }
  }, []);

  useEffect(() => {
    setSideDrawer(props.resetSidebar);
  }, [props.resetSidebar]);
  return (
    <div className="header">
      {copedText ? (
        <div
          className="QR-home"
          style={{
            padding: '0px',
            border: 'none',
            maxWidth: '200px',
            maxHeight: '40px',
            zIndex: '9999',
            marginLeft: '40%'
          }}
        >
          <div
            className="header-Qr"
            style={{ padding: '5px', width: '100%', borderRadius: '5px' }}
          >
            <span
              style={{ fontSize: '1rem', color: 'white', fontWeight: '400' }}
            >
              {'Public key copied'}
            </span>
            <button
              style={{ cursor: 'pointer' }}
              onClick={() => setCopiedtext(false)}
            >
              X
            </button>
          </div>
        </div>
      ) : null}

      {showLeftMenu && (
        <div className="header-left">
          {user.length > 0 ? (
            sideDrawer ? (
              <GiHamburgerMenu
                className="menu-open"
                size={30}
                onClick={() => {
                  setSideDrawer(false);
                }}
              />
            ) : (
              <GiHamburgerMenu
                className="menu-open"
                size={30}
                onClick={() => {
                  setSideDrawer(true);
                }}
              />
            )
          ) : null}
          <a href="/" className="logo">
            <img src={logo} />
          </a>
          {user.length == 0 ? (
            <a
              href="https://pecunovus.com"
              style={{ textDecoration: 'none' }}
              target={'_blank'}
            >
              <h5 style={{ textAlign: 'center', color: '#0095C8' }}>
                Learn More About The Pecu Novus Digital Asset Network
              </h5>
            </a>
          ) : null}
        </div>
      )}

      {user.length && user[0]?.pub_key ? (
        <div className="header-center">
          <p>PUBLIC KEY:</p>
          <p className="public_key">{user.length ? user[0].pub_key : ''}</p>
          {props.allowAccess && (
            <>
              {' '}
              <button
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigator.clipboard.writeText(user[0].pub_key);
                  setCopiedtext(true);
                }}
              >
                Copy
              </button>
            </>
          )}

          <MdQrCode2
            style={{ cursor: 'pointer', padding: '5px' }}
            size={20}
            onClick={() => props.showQr(true)}
          />
          <span
            style={{
              width: '110px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <FaClock size={'13px'} style={{ margin: '3px' }} /> {localTime}
          </span>
          <span> {new Date().toLocaleDateString()}</span>
        </div>
      ) : null}

      {/* <div className="header-center">YOUR PECU ADDRESS : g8MszDg1E3OMOAmn...</div> */}
      {user.length && user[0].pub_key ? (
        <div
          className="header-right"
          style={{ display: 'flex', flexDirection: 'row' }}
        >
          <div className="username-header">
            <span>
              {props.allowAccess && user.length ? 'TERMINAL ID: ' : null}{' '}
              {user.length > 0
                ? props.allowAccess
                  ? `PN${user[0]?.email?.slice(0, 8)?.toUpperCase()}${
                      user[0]?.user_id
                    }`
                  : ''
                : 'login'}
            </span>
            <button
              style={{ margin: '5px' }}
              onClick={() => {
                setShowDropdown(!showDropdown);
                props.logOut();
              }}
            >
              Logout
            </button>
          </div>
          <span
            style={{
              width: '35px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              backgroundColor: 'white',
              borderRadius: '50%',
              marginLeft: '10px',
              marginRight: '15px'
            }}
          >
            <MdNotificationsNone
              size={'20px'}
              style={{ margin: '1px', color: '#0095C8' }}
            />
            <small
              style={{
                position: 'absolute',
                right: '9px',
                top: '3px',
                fontSize: '10px',
                fontWeight: 'bold',
                color: '#0095C8'
              }}
            >
              {0}
            </small>
          </span>
        </div>
      ) : null}
    </div>
  );
}

import React from 'react';

export default function TermsOfService() {
  return (
    <div
      style={{
        padding: '2rem',
        color: 'black',
        backgroundColor: 'whitesmoke',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <p
        style={{ lineHeight: '40px', width: '80%', textJustify: 'inter-word' }}
      >
        PRIVACY POLICY Effective May 25, 2018 and last updated January 15, 2022
        The Pecu Novus Digital Asset Network (“Pecu Novus,” “we”, “us”, or
        “our”) is committed to protecting your privacy. We have prepared this
        Privacy Policy to describe to you our practices regarding the Personal
        Data (as defined below) we collect, use, and share in connection with
        the Pecu Novus website, mobile app, and other software provided on or in
        connection with our services, as described in our Terms of Service
        (collectively, the “Service”). “NFT” in this Privacy Policy means a
        non-fungible token or similar digital asset implemented on the Pecu
        Novus blockchain, which uses smart contracts to link to or otherwise be
        associated with certain content or data. Pecu Novus is in complete
        compliance with EU General Data Protection Regulation (GDPR). Please
        note that this privacy policy does not govern the collection and use of
        information by companies that Pecu Novus does not control, nor by
        individuals not employed or managed by Pecu Novus. If you visit a Web
        site that we mention or link to, be sure to review its privacy policy
        before providing the site with information. What we do with your
        personally identifiable information It is always up to you whether to
        disclose personally identifiable information to us, although if you
        elect not to do so, we reserve the right not to register you as a user
        or provide you with any products or services. “Personally identifiable
        information” means data that allows someone to identify you
        individually, including, for example, your name, email address, as well
        as any other non-public information about you that is associated with or
        linked to any of the foregoing. “Anonymous Data” means data, including
        aggregated and de-identified data, that is not associated with or linked
        to your Personal Data; Anonymous Data does not, by itself, permit the
        identification of individual persons. We collect Personal Data and
        Anonymous Data as described below. Information You Provide Us. When you
        use our Service, update your account profile, or contact us, we may
        collect Personal Data from you, such as email address, first and last
        name, user name, and other information you provide. We also collect your
        Pecu Public Key, which becomes associated with Personal Data when you
        use our Service. Our Service lets you store preferences like how your
        content is displayed, notification settings, and other data. We may
        associate these selections with your User ID, browser, or mobile device.
        If you provide us with feedback or contact us, we will collect your name
        and contact information, as well as any other content included in the
        message. We may also collect Personal Data at other points in our
        Service where you voluntarily provide it or where we state that Personal
        Data is being collected. As you navigate through and interact with our
        Service, we may use automatic data collection technologies to collect
        certain information about your equipment, browsing actions, and
        patterns, including: Information Collected by Our Servers. To provide
        our Service and make it more useful to you, we collect information from
        you, including, but not limited to, your browser type, operating system,
        Internet Protocol (“IP”) address, mobile device ID, Pecu Public Key,
        Pecu Wallet version, and date/time stamps. As is true of most websites
        and applications, we may gather certain information automatically and
        store it in log files. This information includes IP addresses, browser
        type, Internet service provider (“ISP”), operating system, date/time
        stamps. Like many online services, we use cookies to collect
        information. We may use both session Cookies (which expire once you
        close your web browser) and persistent Cookies (which stay on your
        computer until you delete them) to analyze how users interact with our
        Service, make improvements to our product quality, and provide users
        with a more personalized experience. In the future we may find a need to
        include an analytics company to set their own cookies or similar tools
        when you visit our Service. This includes third-party analytics services
        (“Analytics”) that we engage to help analyze how users use the Service
        to continue to innovate. The information generated by the Cookies or
        other technologies about your use of our Service (the “Analytics
        Information”) is transmitted to Analytics. Analytics Information is used
        to compile reports on user activity, which we may receive on an
        individual or aggregate basis. We would use the information we get from
        Analytics to improve our Service. By using our Service, you consent to
        the processing of data about you by Analytics Services in the manner and
        for the purposes set out above. Use of Your Personal Data. We process
        your Personal Data to run our business, provide the Service, personalize
        your experience on the Service, and improve the Service. Specifically,
        we use your Personal Data to: facilitate the creation of and secure your
        account; identify you as a user in our system; provide you with our
        Service, including, but not limited to, helping you view and create NFTs
        using our tools and, at your own discretion, connect directly with other
        coinholders to purchase, sell, or transfer NFTs minted on the Pecu Novus
        public blockchain; improve the administration of our Service and quality
        of experience when you interact with our Service, including, but not
        limited to, by analyzing how you and other users find and interact with
        the Service; provide customer support and respond to your requests and
        inquiries; investigate and address user conduct that may violate our
        Terms of Service; detect, prevent, and address fraud, violations of our
        terms or policies, and/or other harmful or unlawful activity; display
        your public key with each transaction and display the NFT public key
        with the NFT listing; send you notification emails and ChatHive messages
        to verify your account and all Pecu Coin and NFT transactions; send you
        administrative notifications, such as security, support, and maintenance
        advisories via email or ChatHive messaging; send you notifications via
        email or ChatHive messaging related to actions on the Service, including
        notifications of offers on your Pecu Coins or NFTs; send you other
        notices related to our Services via email or ChatHive messaging; respond
        to your inquiries or other requests; comply with applicable laws,
        cooperate with investigations by law enforcement or other authorities of
        suspected violations of law, and/or to pursue or defend against legal
        threats and/or claims; and act in any other way we may describe when you
        provide the Personal Data. Disclosure of Your Personal Data. We disclose
        your Personal Data as described below and as described elsewhere in this
        Privacy Policy. We may share your Personal Data with a secure third
        party service providers to: provide technical infrastructure services;
        conduct quality assurance testing; analyze how our Service is used;
        prevent, detect, and respond to unauthorized activities; provide
        technical and customer support; and/or to provide other support to us
        and to the Service. We may share some or all of your Personal Data with
        any subsidiaries, joint ventures, or other companies under MegaHoot’s
        common control, in which case they will also be bound by this Privacy
        Policy. We may share some or all of your Personal Data in connection
        with or during negotiations in regards to any merger, financing,
        acquisition, or dissolution transaction or proceeding involving sale,
        transfer, divestiture, or disclosure of all or a portion of our business
        or assets. In the event of an insolvency, bankruptcy, or receivership,
        Personal Data may also be transferred as a business asset. If another
        company acquires the platform or assets, that company will possess the
        Personal Data collected by us and will assume the rights and obligations
        regarding your Personal Data as described in this Privacy Policy.
        Regardless of any choices you make regarding your Personal Data (as
        described below), Pecu Novus may disclose Personal Data if it believes
        in good faith that such disclosure is necessary: (a) in connection with
        any legal investigation; (b) to comply with relevant laws or to respond
        to subpoenas, warrants, or other legal process served on Pecu Novus; (c)
        to protect or defend the rights or property of Pecu Novus or users of
        the Service; and/or (d) to investigate or assist in preventing any
        violation or potential violation of the law, this Privacy Policy, or our
        Terms of Service. We may also disclose your Personal Data: to fulfill
        the purpose for which you provide it; for any other purpose disclosed by
        us when you provide it; or with your consent. 4. Third-Party Websites.
        Our Service may contain links to third-party websites. When you click on
        a link to any other website or location, you will leave our Service and
        go to another site, and another entity may collect Personal Data from
        you. We have no control over, do not review, and cannot be responsible
        for these third-party websites or their content. Please be aware that
        the terms of this Privacy Policy do not apply to these third-party
        websites or their content, or to any collection of your Personal Data
        after you click on links to such third-party websites. We encourage you
        to read the privacy policies of every website you visit. Any links to
        third-party websites or locations are for your convenience and do not
        signify our endorsement of such third parties or their products,
        content, or websites. Your Choices Regarding Information. You have
        several choices regarding the use of information on our Services: We may
        periodically send you emails or ChatHive messages that directly promote
        the use of our Service or NFT listings. Despite these preferences, we
        may send you occasional transactional service-related informational
        communications. If you decide at any time that you no longer wish to
        accept Cookies from our Service for any of the purposes described above,
        then you can instruct your browser, by changing its settings, to stop
        accepting Cookies or to prompt you before accepting a Cookie from the
        websites you visit. Consult your browser’s technical information. If you
        do not accept Cookies, however, you may not be able to use all portions
        of the Service or all functionality of the Service. Data Access and
        Control. You can view, access, edit, or delete your Personal Data for
        certain aspects of the Service via your Settings page. You may also have
        certain additional rights: If you are a user in the European Economic
        Area or United Kingdom, you have certain rights under the respective
        European and UK General Data Protection Regulations (“GDPR”). These
        include the right to (i) request access and obtain a copy of your
        personal data; (ii) request rectification or erasure; (iii) object to or
        restrict the processing of your personal data; and (iv) request
        portability of your personal data. Additionally, if we have collected
        and processed your personal data with your consent, you have the right
        to withdraw your consent at any time. If you are a California resident,
        you have certain rights under the California Consumer Privacy Act
        (“CCPA”). These include the right to (i) request access to, details
        regarding, and a copy of the personal information we have collected
        about you and/or shared with third parties; (ii) request deletion of the
        personal information that we have collected about you; and (iii) the
        right to opt-out of sale of your personal information. As the terms are
        defined under the CCPA, we do not “sell” your “personal information.” If
        you wish to exercise your rights under the GDPR, CCPA, or other
        applicable data protection or privacy laws, please contact us and we may
        ask you to verify your identity, or ask for more information about your
        request. We will consider and act upon any above request in accordance
        with applicable law. We will not discriminate against you for exercising
        any of these rights. Notwithstanding the above, we cannot edit or delete
        any information that is stored on the Pecu Novus blockchain. The
        information stored on the Pecu Novus blockchain may include purchases,
        sales, and transfers related to your Pecu public key, the coins and NFTs
        held at that address. We may retain your Personal Data as long as you
        continue to use the Service, have an account with us, or for as long as
        is necessary to fulfill the purposes outlined in this Privacy Policy. We
        may continue to retain your Personal Data even after you deactivate your
        wallet/account and/or cease to use the Service if such retention is
        reasonably necessary to comply with our legal obligations, to resolve
        disputes, prevent fraud and abuse, enforce our Terms or other
        agreements, and/or protect our legitimate interests. Where your Personal
        Data is no longer required for these purposes, we will delete it.
        External data storage sites : We may store your data on servers provided
        by third party hosting vendors with whom we have contracted. We care
        about the security of your information and use physical, administrative,
        and technological safeguards to preserve the integrity and security of
        information collected through our Service. However, no security system
        is impenetrable and we cannot guarantee the security of our systems. In
        the event that any information under our custody and control is
        compromised as a result of a breach of security, we will take steps to
        investigate and remediate the situation and, in accordance with
        applicable laws and regulations, notify those individuals whose
        information may have been compromised. You are responsible for the
        security of your Pecu Digital Wallet, and we urge you to take steps to
        ensure it is and remains secure. Secure your private keys and other
        identifying information to protect your digital assets. We do not
        intentionally gather Personal Data from visitors who are under the age
        of 13. Our Terms of Service require all users to be at least 18 years
        old. If a child under 13 submits Personal Data to us and we learn that
        the Personal Data is the information of a child under 13, we will
        attempt to delete the information as soon as possible. If you believe
        that we might have any Personal Data from a child under 13, please
        contact us. If you are a non-U.S. user of the Service, by visiting the
        Service and providing us with data, you acknowledge and agree that your
        Personal Data may be processed for the purposes identified in the
        Privacy Policy. In addition, your Personal Data may be processed in the
        country in which it was collected and in other countries, including the
        United States, where laws regarding processing of Personal Data may be
        less stringent than the laws in your country. By providing your Personal
        Data, you consent to such transfer. Changes to This Privacy Policy. This
        Privacy Policy may be updated periodically for any reason. We will
        notify you of any changes to our Privacy Policy by posting the new
        Privacy Policy at https://pecunovus.com/privacy_policy. The date the
        Privacy Policy was last revised is identified at the beginning of this
        Privacy Policy. You are solely responsible for periodically visiting our
        Service and this Privacy Policy to check for any changes.
      </p>
    </div>
  );
}

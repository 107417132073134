import axios from 'axios';
import React, { useState } from 'react';
import url from '../../server_url';

export default function WithdrawCoinVault({ closeForm, uid }) {
  const [coin, setCoin] = useState(0);

  const handleSumbit = () => {
    if (!coin || coin === 0) {
      alert('please enter coin ');
    }
    axios
      .post(`${url}/vault/withdraw_coins_vault`, {
        coin: coin,
        uid: uid
      })
      .then((res) => {
        if (res.status === 200) {
          alert(res.data.message);
        }
        setTimeout(() => {
          closeForm();
        }, 1000);
      })
      .catch((err) => {
        alert(err.message);
      });
    // closeForm()
  };
  return (
    <div className="sendCoins-main">
      <form
        style={{
          maxWidth: '300px',
          maxHeight: '500px',
          borderRadius: '15px',
          border: '1px solid #0095C8'
        }}
        onSubmit={(e) => {
          e.preventDefault();
          handleSumbit();
        }}
      >
        <img
          src={require('../../Assets/MHVault.png')}
          style={{ width: '200px', height: '200px', padding: '2rem' }}
        />
        <input
          placeholder="Coins"
          value={coin}
          type={'number'}
          onChange={(e) => {
            setCoin(parseInt(e.target.value));
          }}
        />

        <button
          style={{
            backgroundImage: `linear-gradient(160deg,#000000 0%,#0095C8  100%)`
          }}
        >
          WITHDRAW{' '}
        </button>
        <button
          style={{
            backgroundImage: `linear-gradient(160deg,#000000 0%,red  100%)`
          }}
          onClick={() => {
            closeForm();
          }}
        >
          CANCEL
        </button>
      </form>
    </div>
  );
}

/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useState } from 'react';
import './main.css';
import { IoLogoUsd } from 'react-icons/io';
import axios from 'axios';
import { MdQrCode2 } from 'react-icons/md';
import Chart from './chart';
import { HiTrendingDown, HiTrendingUp } from 'react-icons/hi';
import socket from '../../socket';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ResponsiveContainer } from 'recharts';
import logo from '../../Assets/displayicon.png';
import documega from '../../Assets/scroller5.png';
import SideDrawer from '../sideDrawer/sideDrawer';
import QrCode from '../QR/Qr';
import NFTStation from '../NFT/NFTStation';
import SendCoins from '../SendCoins/sendCoins';
import PublicLedger from '../PublicLedger/PublicLedger';
import QRCode from 'react-qr-code';
import Setting from '../settings/setting';
import CryptoLedger from '../PublicLedger/cryptoindex';
import Vault from '../vault/vault';
import NodeLedger from '../nodeLedger/nodeLedger';
import Validator from '../validator/validator';
import url from '../../server_url';
import Tokens from '../../Tokens/tokens';
import TokenLedger from '../PublicLedger/tokenLedger';
import TransferToken from '../../Tokens/transferToken';
import ProjectTokens from '../../Tokens/projectTokens';
import TransferWrapToken from '../../Tokens/transferWrapToken';
import HoldingTokens from '../../Tokens/holdingTokens';
import ConversionTokens from '../../Tokens/conversionToken';
import TransferConversionToken from '../../Tokens/transferConversionToken';
import CoinInfo from '../coinInfo/coinInfo';
import FanTokens from '../../Tokens/fanTokens';
function Main(props) {
  const userData = props.user;
  const [cryptos, setCryptos] = useState([]);
  const [user, setUser] = useState([]);
  const [totalCoins, setTotalCoins] = useState('0.00000000');
  const [totalValue, setTotalValue] = useState('0.00000000');
  const [totalEscrowedCoins, setTotalEscrowedCoins] = useState('0.00000000');
  const [totalEscrowedTokens, setTotalEscrowedTokens] = useState([]);
  const [currentValue, setCurrentValue] = useState(0);
  const [chartData, setChartData] = useState([]);
  const [circulatingSupply, setCirculatingSupply] = useState(281591051);
  const [coinVolume, setCoinVolume] = useState(114826.00453596);
  const [showQr, setShowQr] = useState(false);
  const [showNftStation, setShowNftStation] = useState(false);
  const [showPublicLedger, setShowPublicLedger] = useState(false);
  const [showCryptoIndexLedger, setCryptoIndexLedger] = useState(false);
  const [showSendCoin, setShowSendCoin] = useState(false);
  const [showVault, setShowVault] = useState(false);
  const [escrowData, setEscrowData] = useState([]);
  const [showNodeLedger, setShowNodeLedger] = useState(false);
  const [tokenLedger, showTokenLedger] = useState(false);
  const [allowAccess, setAllowAccess] = useState(false);
  const [trend, setTrend] = useState({});
  const [trendHourly, setTrendHourly] = useState({});
  const [showLedgerModal, setShowLedgerModal] = useState(false);
  const [modaldataLedger, setModaldataLedger] = useState({});
  const [privateKey, setPrivateKey] = useState(null);
  const [latestTransactions, setlatestTransactions] = useState([]);
  const [welcome, setWelcome] = useState(true);
  const [showSetting, setShowSetting] = useState(false);
  const [showTokenContainer, setShowTokenContainer] = useState(false);
  const [showProjectTokenContainer, setShowProjectTokenContainer] =
    useState(false);
  const [showFanTokenContainer, setShowFanTokenContainer] = useState(false);

  const [showHoldingTokenContainer, setShowHoldingTokenContainer] =
    useState(false);
  const [showConversionTokenContainer, setShowConversionTokenContainer] =
    useState(false);
  const [tokens, setTokens] = useState(0);
  const [tokenHoldings, setTokenHolding] = useState(0);
  const [showSideDrawer, setShowSideDrawer] = useState(false);
  const [pecuLedgerOffset, setPecuLedgerOffset] = useState(0);
  const [escrowLedgerOffset, setEscrowLedgerOffset] = useState(0);
  const [marketCap, setMarketCap] = useState(0);
  const [showValidator, setShowValidator] = useState(false);
  const [transferTokenModal, setTransferTokenModal] = useState(false);
  const [transferToken, setTransferToken] = useState(null);
  const [limit, setLimit] = useState(100);
  const [showTransferWraptoken, setShowTransferWraptoken] = useState(false);
  const [showTransferConversiontoken, setShowTransferConversiontoken] =
    useState(false);
  const [conversionToken, setConversionToken] = useState([]);
  const [conversionTokenCompany, setConversionTokenCompany] = useState([]);

  const [quoteNumber, setQuoteNumber] = useState(
    Math.floor(Math.random() * 10)
  );
  useEffect(() => {
    setQuoteNumber(Math.floor(Math.random() * 10));
  }, []);
  const quotation = [
    {
      quote:
        '“I like friends who have independent minds because they tend to make you see problems from all angles.”',
      by: 'Nelson Mandela'
    },
    {
      quote: '“No great discovery was ever made without a bold guess.”',
      by: 'Issac Newton'
    },
    {
      quote:
        '“Tell me and I forget. Teach me and I remember. Involve me and I learn.”',
      by: 'Benjamin Franklin'
    },
    {
      quote:
        '“Tell me and I forget. Teach me and I remember. Involve me and I learn.”',
      by: 'Benjamin Franklin'
    },
    {
      quote:
        '“We cannot solve our problems with the same thinking we used when we created them.”',
      by: 'Albert Einstein'
    },
    {
      quote: '“Be the change that you wish to see in the world.”',
      by: 'Mahatma Gandhi '
    },
    {
      quote:
        "“I'm a success today because I had a friend who believed in me and I didn't have the heart to let him down.”",
      by: 'Abraham Lincoln'
    },
    {
      quote: '“Innovation distinguishes between a leader and a follower”',
      by: 'Steve Jobs'
    },
    {
      quote:
        '“The belief that the world is getting worse, that we can’t solve extreme poverty and disease, isn’t just mistaken. It’s harmful.”',
      by: 'Bill Gates'
    },
    {
      quote:
        '“Success is a lousy teacher. It seduces smart people into thinking they can’t lose.”',
      by: 'Bill Gates'
    }
  ];
  useEffect(() => {
    setShowQr(props.showQr);
  }, [props.showQr]);

  const getChatData = async (filter) => {
    if (filter) {
      let localData = await axios.get(
        `${url}/wallet/get_change_index_coin_${filter}`
      );
      try {
        if (localData.data) {
          setChartData(localData.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const get_current_index_coin = () => {
    axios.get(`${url}/wallet/get_current_index_coin`).then((res) => {
      setCurrentValue(res.data[0].value);
    });
  };

  const calculate_circulating_supply = () => {
    axios.get(`${url}/wallet/calculate_circulating_supply`).then((res) => {
      setCirculatingSupply(res.data.total_amount);
    });
  };

  const marketcap = () => {
    axios.get(`${url}/wallet/marketcap`).then((res) => {
      setMarketCap(res.data.MARKET_CAP);
    });
  };

  const calculate_daily_volume = () => {
    axios.get(`${url}/wallet/calculate_daily_volume`).then((res) => {
      setCoinVolume(res.data.total_volume);
    });
  };

  const getMyCoins = (id) => {
    if (id) {
      axios.get(`${url}/walletBeta/getMycoins?user_id=${id}`).then((res) => {
        const { total_coins, value } = res.data;
        setTotalCoins(total_coins);
        setTotalValue(value);
      });
    }
  };

  const my_latest_transactions = (id, pub_key) => {
    if (id && pub_key) {
      console.log(pub_key);
      axios
        .get(
          `${url}/walletBeta/my_latest_transactions?uid=${id}&pub_key=${pub_key}&offset=${pecuLedgerOffset}`
        )
        .then((res) => {
          setLimit(limit + 10);
          let mydata = res.data;
          if (latestTransactions.length > 0) {
            setlatestTransactions([...latestTransactions, ...mydata]);
          } else {
            setlatestTransactions(mydata);
          }
          setPecuLedgerOffset(parseInt(pecuLedgerOffset) + 10);
        })
        .catch((err) => {
          my_latest_transactions(id, pub_key);
        });
    }
  };
  const my_latest_transactions_escrow = (id) => {
    if (id) {
      axios
        .get(
          `${url}/walletBeta/my_latest_transactions_escrow?limit=${limit}&uid=${id}&offset=${escrowLedgerOffset}`
        )
        .then((res) => {
          setLimit(limit + 10);
          let mydata = res.data;
          if (escrowData.length > 0) {
            setEscrowData([...escrowData, ...mydata]);
          } else {
            setEscrowData(mydata);
          }

          setEscrowLedgerOffset(escrowLedgerOffset + 10);
        })
        .catch((err) => {
          my_latest_transactions_escrow(id);
        });
    }
  };

  const releaseEscrow = (data) => {
    let value = data.value;
    let touid = data.to_uid;
    let tid = data.tid;
    console.log(value, touid);
    axios
      .post(`${url}/wallet/releaseEscrow`, {
        value: value,
        touid: touid,
        tid: tid
      })
      .then((res) => {
        alert('Escrow Release success');
      })
      .catch((err) => console.log(err));
  };

  const cancelEscrow = (data) => {
    let value = data.value;
    let touid = data.to_uid;
    let tid = data.tid;
    console.log(value, touid);
    axios
      .post(`${url}/wallet/cancelEscrow`, {
        value: value,
        touid: touid,
        tid: tid
      })
      .then((res) => {
        alert('Escrow canceled success');
      })
      .catch((err) => console.log(err));
  };
  const get_change_index_coin = () => {
    axios.get(`${url}/wallet/get_change_index_coin`).then((res) => {
      setTrend(res.data);
    });
  };

  const get_change_index_coin_hourly = () => {
    axios.get(`${url}/wallet/get_change_index_coin_hourly`).then((res) => {
      setTrendHourly(res.data);
    });
  };

  const myEscrowedCoins = (id) => {
    if (id) {
      axios
        .get(`${url}/walletBeta/myEscrowedCoins?user_id=${id}`)
        .then((res) => {
          setTotalEscrowedCoins(res.data.total_coins);
        })
        .catch((err) => {
          myEscrowedCoins(id);
        });
    }
  };
  const myEscrowedTokens = (email) => {
    if (email) {
      axios
        .get(`${url}/wallet/get_my_escrow_tokens?email=${email}`)
        .then((res) => {
          if (res.data.status) {
            setTotalEscrowedTokens(res.data.tokens);
          }
        })
        .catch((err) => {
          myEscrowedCoins(email);
        });
    }
  };

  const get_pvt_key_by_uid = (id) => {
    if (id) {
      axios
        .post(`${url}/wallet/get_pvt_key_by_uid`, {
          uid: id
        })
        .then((res) => {
          setPrivateKey(res.data);
        });
    }
  };
  const removeDuplicatedToken = (allData) => {
    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol == allData[j].symbol) {
          allData[i].assetAmount =
            allData[j].assetAmount + allData[i].assetAmount;

          allData = allData.filter((e) => e !== allData[j]);
        }
      }
    }

    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol == allData[j].symbol) {
          return removeDuplicatedToken(allData);
        }
      }
    }

    return allData;
  };

  const getCryptoPrice = async () => {
    setCryptos([]);
    let response = await axios.get(
      `${url}/wallet/get_all_tokens_wrap_new_price`
    );
    try {
      if (response && response.data) {
        setCryptos(response.data.tokens);
      }
    } catch (error) {}
  };

  const getMyTokens = (email) => {
    // email = 'louisvelazquezbp@gmail.com';
    if (email) {
      axios
        .get(`${url}/wallet/get_my_tokens?email=${email}`)
        .then((res) => {
          if (res.data.status) {
            let fixedData = JSON.parse(JSON.stringify(res.data.tokens));
            setTokens(fixedData);

            setTokenHolding(fixedData);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const getMyConversionTokens = (email) => {
    //email = 'louisvelazquezbp@gmail.com';
    if (email) {
      axios
        .get(`${url}/wallet/get_my_tokens_conversion?email=${email}`)
        .then((res) => {
          if (res.data.status) {
            let fixedData = res.data.token;
            if (fixedData && fixedData?.length > 0) {
              setConversionToken(fixedData);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const getMyConversionTokensCompany = (id) => {
    //email = 'mark.hu@thunderbirds.me';
    if (id) {
      axios
        .get(`${url}/wallet/get_my_assetWallet_data?userId=${id}`)
        .then(async (res) => {
          if (res.data) {
            let storedData = await removeDuplicatedToken(res.data.assets);

            setConversionTokenCompany(storedData);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const [holdingTokenCompany, setHoldingTokenCompany] = useState([]);
  const getMyHoldingTokens = (email) => {
    if (email) {
      axios
        .get(`${url}/wallet/get_my_tokens_Holding_email?email=${email}`)
        .then((res) => {
          if (res.data.status) {
            let fixedData = res.data.token;
            if (fixedData && fixedData?.length > 0) {
              setHoldingTokenCompany(fixedData);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  };
  useEffect(() => {
    window.addEventListener('beforeunload', function (e) {
      // Cancel the event

      socket.emit('pn-terminal-access-order', {
        privateKey: privateKey,
        toggle: false
      });
    });
  }, [privateKey]);
  useEffect(() => {
    socket.emit('joinRoompnprivateKey', { privateKey: privateKey });
  }, [privateKey]);
  useEffect(() => {
    socket.on('pn-terminal-access', (e) => {
      if (e !== null && privateKey !== null && e.privateKey === privateKey) {
        //  if(data.toggle){
        //     setAllowAccess(true)
        //  }
        setAllowAccess(e.toggle);
        props.allowAccess(e.toggle);
      }
    });
  }, [privateKey]);

  useEffect(() => {
    get_pvt_key_by_uid(userData.user_id);
  }, []);

  useEffect(async () => {
    getCryptoPrice();
    let allaccess = allowAccess;
    let data = localStorage.getItem('pn_secretcookie');
    let parsedData = await JSON.parse(data);

    if (data) {
      setUser(parsedData);
    }
    if (allaccess && parsedData && parsedData.user_id) {
      marketcap();
      get_change_index_coin();
      get_change_index_coin_hourly();
      calculate_daily_volume();
      calculate_circulating_supply();
      // getChatData('yearly');
      get_current_index_coin();
      getMyCoins(parsedData.user_id);
      myEscrowedCoins(parsedData.user_id);
      getMyTokens(parsedData.email);
      my_latest_transactions(parsedData.user_id, parsedData.pub_key);
      my_latest_transactions_escrow(parsedData.user_id);
      myEscrowedTokens(parsedData.email);
      getMyConversionTokens(parsedData.email);
      getMyConversionTokensCompany(parsedData.user_id);
      getMyHoldingTokens(parsedData.email);
    }
  }, [allowAccess]);

  useEffect(async () => {
    getCryptoPrice();

    let data = localStorage.getItem('pn_secretcookie');
    let parsedData = await JSON.parse(data);

    if (data) {
      setUser(parsedData);
    }
    setInterval(() => {
      if (allowAccess && parsedData && parsedData.user_id) {
        marketcap();
        get_change_index_coin();
        calculate_daily_volume();
        get_change_index_coin_hourly();
        calculate_circulating_supply();
        get_current_index_coin();
        getMyCoins(parsedData.user_id);
        myEscrowedCoins(parsedData.user_id);
        // if (tokens.length > 0) {
        //   getMyTokens(parsedData.email);
        // }
        //my_latest_transactions(parsedData.user_id,parsedData.pub_key)
        //my_latest_transactions_escrow(parsedData.user_id)
      }
    }, 60000);
  }, [allowAccess]);

  useEffect(() => {
    setShowSideDrawer(props.sideDrawer);
  }, [props.sideDrawer]);
  useEffect(() => {
    let accessArray = [
      'tester@gmail.com',
      'officialaakashbhardwaj@gmail.com',
      'info@megahoot.com',
      'hortonglobalindustries@gmail.com',
      'ucwent@gmail.com',
      'info@ucwe.com',
      'megahootinc@gmail.com',
      'louis@falconglobalacquisitions.com',
      'info@falconglobalacquisitions.com',
      'acc1@falconglobalacquisitions.com',
      'acc2@falconglobalacquisitions.com',
      'acc3@falconglobalacquisitions.com',
      'acc4@falconglobalacquisitions.com',
      'acc5@falconglobalacquisitions.com',
      'acc6@falconglobalacquisitions.com',
      'acc7@falconglobalacquisitions.com',
      'acc8@falconglobalacquisitions.com',
      'acc9@falconglobalacquisitions.com'
    ];
    if (accessArray.includes(userData.email)) {
      setAllowAccess(true);
    }
  }, []);
  const handlePecuTerminal = () => {
    setShowNodeLedger(false);
    setShowNftStation(false);
    setShowQr(false);
    setShowPublicLedger(false);
    setShowSendCoin(false);
    setShowVault(false);
    setShowSetting(false);
    setCryptoIndexLedger(false);
    setShowValidator(false);
    setShowTokenContainer(false);
    showTokenLedger(false);
    setTransferTokenModal(false);
    setTransferToken(null);
    setShowProjectTokenContainer(false);
    setShowFanTokenContainer(false);
    setShowTransferWraptoken(false);
    setShowHoldingTokenContainer(false);
    setShowConversionTokenContainer(false);
    setShowTransferConversiontoken(false);
    getMyTokens(userData?.email);
  };
  useEffect(
    (userData) => {
      if (userData) {
        setUser([userData]);
        // getMyCoins(userData)
      }
    },
    [userData]
  );
  const makeid = (length) => {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  return allowAccess ? (
    <div className="main">
      {showNftStation ? <NFTStation /> : null}
      {showPublicLedger ? <PublicLedger /> : null}
      {showCryptoIndexLedger ? <CryptoLedger /> : null}
      {showSendCoin ? <SendCoins userData={userData} /> : null}
      {showTransferWraptoken ? <TransferWrapToken userData={userData} /> : null}
      {showVault ? <Vault userData={userData} /> : null}
      {showNodeLedger ? <NodeLedger /> : null}
      {tokenLedger ? <TokenLedger /> : null}
      {showSetting ? (
        <Setting privateKey={privateKey} userData={userData} />
      ) : null}
      {showValidator ? <Validator /> : null}
      {showTokenContainer ? (
        <Tokens
          user={userData}
          updatepubkey={(pub_key) => {
            props.updatepubkey(pub_key);
          }}
          closeMe={() => {
            handlePecuTerminal();
            setTimeout(() => {
              getMyTokens(user.email);
              setTimeout(() => {
                getMyTokens(user.email);
              }, 1000);
            }, 1000);
          }}
        />
      ) : null}

      {showProjectTokenContainer ? (
        <ProjectTokens
          user={userData}
          updatepubkey={(pub_key) => {
            props.updatepubkey(pub_key);
          }}
          closeMe={() => {
            handlePecuTerminal();
            setTimeout(() => {
              getMyTokens(user.email);
              setTimeout(() => {
                getMyTokens(user.email);
              }, 1000);
            }, 1000);
          }}
        />
      ) : null}
      {showFanTokenContainer ? (
        <FanTokens
          user={userData}
          updatepubkey={(pub_key) => {
            props.updatepubkey(pub_key);
          }}
          closeMe={() => {
            handlePecuTerminal();
            setTimeout(() => {
              getMyTokens(user.email);
              setTimeout(() => {
                getMyTokens(user.email);
              }, 1000);
            }, 1000);
          }}
        />
      ) : null}
      {showHoldingTokenContainer ? (
        <HoldingTokens
          user={userData}
          updatepubkey={(pub_key) => {
            props.updatepubkey(pub_key);
          }}
          closeMe={() => {
            handlePecuTerminal();
            setTimeout(() => {
              getMyTokens(user.email);
              setTimeout(() => {
                getMyTokens(user.email);
              }, 1000);
            }, 1000);
          }}
        />
      ) : null}
      {showConversionTokenContainer ? (
        <ConversionTokens
          user={userData}
          updatepubkey={(pub_key) => {
            props.updatepubkey(pub_key);
          }}
          closeMe={() => {
            handlePecuTerminal();
            setTimeout(() => {
              getMyTokens(user.email);
              setTimeout(() => {
                getMyTokens(user.email);
              }, 1000);
            }, 1000);
          }}
        />
      ) : null}

      {showSideDrawer ? (
        <div
          onMouseLeave={() => {
            setShowSideDrawer(false);
          }}
          style={{
            position: 'sticky',
            left: '0px',
            width: '100%',
            top: '0px',
            padding: '0px',
            margin: '0px',
            zIndex: '9999'
          }}
        >
          <SideDrawer
            user={userData}
            showSendCoin={() => {
              setShowValidator(false);
              setShowNodeLedger(false);
              setShowSetting(false);
              setShowSendCoin(!showSendCoin);
              setShowNftStation(false);
              setShowPublicLedger(false);
              setCryptoIndexLedger(false);
              setShowVault(false);
              setShowTokenContainer(false);
              setShowProjectTokenContainer(false);
              setShowFanTokenContainer(false);
              setShowHoldingTokenContainer(false);
              setShowConversionTokenContainer(false);
              showTokenLedger(false);
              setShowTransferWraptoken(false);
              setShowTransferConversiontoken(false);
            }}
            showNftStation={() => {
              setShowValidator(false);
              setShowNodeLedger(false);
              setShowNftStation(!showNftStation);
              setShowPublicLedger(false);
              setCryptoIndexLedger(false);
              setShowSendCoin(false);
              setShowSetting(false);
              setShowVault(false);
              setShowTokenContainer(false);
              showTokenLedger(false);
              setShowProjectTokenContainer(false);
              setShowFanTokenContainer(false);
              setShowHoldingTokenContainer(false);
              setShowConversionTokenContainer(false);
              setShowTransferWraptoken(false);
              setShowTransferConversiontoken(false);
            }}
            showPublicLedger={() => {
              setShowValidator(false);
              setShowNodeLedger(false);
              setShowPublicLedger(!showPublicLedger);
              setCryptoIndexLedger(false);
              setShowNftStation(false);
              setShowSendCoin(false);
              setShowSetting(false);
              setShowVault(false);
              setShowTokenContainer(false);
              showTokenLedger(false);
              setShowProjectTokenContainer(false);
              setShowFanTokenContainer(false);
              setShowHoldingTokenContainer(false);
              setShowConversionTokenContainer(false);
              setShowTransferWraptoken(false);
              setShowTransferConversiontoken(false);
            }}
            showSetting={() => {
              setShowValidator(false);
              setShowNodeLedger(false);
              setShowPublicLedger(false);
              setCryptoIndexLedger(false);
              setShowNftStation(false);
              setShowSendCoin(false);
              setShowSetting(!showSetting);
              setShowVault(false);
              setShowTokenContainer(false);
              showTokenLedger(false);
              setShowProjectTokenContainer(false);
              setShowFanTokenContainer(false);
              setShowHoldingTokenContainer(false);
              setShowConversionTokenContainer(false);
              setShowTransferWraptoken(false);
              setShowTransferConversiontoken(false);
            }}
            showCryptoIndexLedger={() => {
              setShowValidator(false);
              setShowNodeLedger(false);
              setShowPublicLedger(false);
              setCryptoIndexLedger(!showCryptoIndexLedger);
              setShowNftStation(false);
              setShowSendCoin(false);
              setShowSetting(false);
              setShowVault(false);
              setShowTokenContainer(false);
              showTokenLedger(false);
              setShowProjectTokenContainer(false);
              setShowFanTokenContainer(false);
              setShowHoldingTokenContainer(false);
              setShowConversionTokenContainer(false);
              setShowTransferWraptoken(false);
              setShowTransferConversiontoken(false);
            }}
            showVault={() => {
              setShowValidator(false);
              setShowNodeLedger(false);
              setShowVault(!showVault);
              setShowPublicLedger(false);
              setCryptoIndexLedger(false);
              setShowNftStation(false);
              setShowSendCoin(false);
              setShowSetting(false);
              setShowTokenContainer(false);
              showTokenLedger(false);
              setShowProjectTokenContainer(false);
              setShowFanTokenContainer(false);
              setShowHoldingTokenContainer(false);
              setShowConversionTokenContainer(false);
              setShowTransferWraptoken(false);
              setShowTransferConversiontoken(false);
            }}
            showNodeLedger={() => {
              setShowValidator(false);
              setShowNodeLedger(!showNodeLedger);
              setShowPublicLedger(false);
              setCryptoIndexLedger(false);
              setShowNftStation(false);
              setShowSendCoin(false);
              setShowSetting(false);
              setShowVault(false);
              setShowTokenContainer(false);
              showTokenLedger(false);
              setShowProjectTokenContainer(false);
              setShowFanTokenContainer(false);
              setShowHoldingTokenContainer(false);
              setShowConversionTokenContainer(false);
              setShowTransferWraptoken(false);
              setShowTransferConversiontoken(false);
            }}
            showTokenLedger={() => {
              setShowValidator(false);
              showTokenLedger(!tokenLedger);
              setShowNodeLedger(false);
              setShowPublicLedger(false);
              setCryptoIndexLedger(false);
              setShowNftStation(false);
              setShowSendCoin(false);
              setShowSetting(false);
              setShowVault(false);
              setShowTokenContainer(false);
              setShowProjectTokenContainer(false);
              setShowFanTokenContainer(false);
              setShowHoldingTokenContainer(false);
              setShowConversionTokenContainer(false);
              setShowTransferWraptoken(false);
              setShowTransferConversiontoken(false);
            }}
            showValidator={() => {
              setShowValidator(!showValidator);
              setShowNodeLedger(false);
              setShowPublicLedger(false);
              setCryptoIndexLedger(false);
              setShowNftStation(false);
              setShowSendCoin(false);
              setShowSetting(false);
              setShowVault(false);
              setShowTokenContainer(false);
              showTokenLedger(false);
              setShowProjectTokenContainer(false);
              setShowFanTokenContainer(false);
              setShowHoldingTokenContainer(false);
              setShowConversionTokenContainer(false);
              setShowTransferWraptoken(false);
              setShowTransferConversiontoken(false);
            }}
            showTokenContainer={() => {
              setShowValidator(false);
              setShowNodeLedger(false);
              setShowPublicLedger(false);
              setCryptoIndexLedger(false);
              setShowNftStation(false);
              setShowSendCoin(false);
              setShowSetting(false);
              setShowVault(false);
              setShowTokenContainer(!showTokenContainer);
              showTokenLedger(false);
              setShowHoldingTokenContainer(false);
              setShowConversionTokenContainer(false);
              setShowProjectTokenContainer(false);
              setShowFanTokenContainer(false);
              setShowTransferWraptoken(false);
              setShowTransferConversiontoken(false);
            }}
            showProjectTokenContainer={() => {
              setShowProjectTokenContainer(!showProjectTokenContainer);
              setShowValidator(false);
              setShowNodeLedger(false);
              setShowPublicLedger(false);
              setCryptoIndexLedger(false);
              setShowNftStation(false);
              setShowSendCoin(false);
              setShowSetting(false);
              setShowVault(false);
              setShowTokenContainer(false);
              showTokenLedger(false);
              setShowTransferWraptoken(false);
              setShowTransferConversiontoken(false);
            }}
            showFanTokenContainer={() => {
              setShowFanTokenContainer(!showFanTokenContainer);
              setShowValidator(false);
              setShowNodeLedger(false);
              setShowPublicLedger(false);
              setCryptoIndexLedger(false);
              setShowNftStation(false);
              setShowSendCoin(false);
              setShowSetting(false);
              setShowVault(false);
              setShowTokenContainer(false);
              showTokenLedger(false);
              setShowTransferWraptoken(false);
              setShowTransferConversiontoken(false);
              setShowProjectTokenContainer(false);
            }}
            showHoldingTokenContainer={() => {
              setShowProjectTokenContainer(false);
              setShowValidator(false);
              setShowNodeLedger(false);
              setShowPublicLedger(false);
              setCryptoIndexLedger(false);
              setShowNftStation(false);
              setShowSendCoin(false);
              setShowSetting(false);
              setShowVault(false);
              setShowTokenContainer(false);
              showTokenLedger(false);
              setShowTransferWraptoken(false);
              setShowHoldingTokenContainer(!showHoldingTokenContainer);
              setShowTransferConversiontoken(false);
            }}
            showConversionTokenContainer={() => {
              setShowProjectTokenContainer(false);
              setShowValidator(false);
              setShowNodeLedger(false);
              setShowPublicLedger(false);
              setCryptoIndexLedger(false);
              setShowNftStation(false);
              setShowSendCoin(false);
              setShowSetting(false);
              setShowVault(false);
              setShowTokenContainer(false);
              showTokenLedger(false);
              setShowTransferWraptoken(false);
              setShowHoldingTokenContainer(false);
              setShowConversionTokenContainer(!showConversionTokenContainer);
              setShowTransferConversiontoken(false);
            }}
            showTransferWraptoken={() => {
              setShowTransferWraptoken(!showTransferWraptoken);
              setShowProjectTokenContainer(false);
              setShowHoldingTokenContainer(false);
              setShowConversionTokenContainer(false);
              setShowValidator(false);
              setShowNodeLedger(false);
              setShowPublicLedger(false);
              setCryptoIndexLedger(false);
              setShowNftStation(false);
              setShowSendCoin(false);
              setShowSetting(false);
              setShowVault(false);
              setShowTokenContainer(false);
              showTokenLedger(false);
              setShowTransferConversiontoken(false);
            }}
            showTransferConversiontoken={() => {
              setShowTransferWraptoken(false);
              setShowProjectTokenContainer(false);
              setShowHoldingTokenContainer(false);
              setShowConversionTokenContainer(false);
              setShowValidator(false);
              setShowNodeLedger(false);
              setShowPublicLedger(false);
              setCryptoIndexLedger(false);
              setShowNftStation(false);
              setShowSendCoin(false);
              setShowSetting(false);
              setShowVault(false);
              setShowTokenContainer(false);
              showTokenLedger(false);
              setShowTransferConversiontoken(!showTransferConversiontoken);
            }}
            pecuTerminal={() => {
              setShowNodeLedger(false);
              handlePecuTerminal();
            }}
            showQr={() => {
              setShowQr(true);
            }}
            sideDrawer={props.sideDrawer}
          />
        </div>
      ) : null}

      {transferTokenModal && (
        <TransferToken
          token={transferToken}
          user={userData}
          updatepubkey={(pub_key) => {
            props.updatepubkey(pub_key);
          }}
          closeMe={() => {
            handlePecuTerminal();
            setTimeout(() => {
              getMyTokens(user.email);
              setTimeout(() => {
                getMyTokens(user.email);
              }, 1000);
            }, 1000);
          }}
        />
      )}
      {showTransferConversiontoken && (
        <TransferConversionToken
          // conversionTokens={conversionToken}
          userData={userData}
          updatepubkey={(pub_key) => {
            props.updatepubkey(pub_key);
          }}
          closeMe={() => {
            handlePecuTerminal();
            setTimeout(() => {
              getMyTokens(user.email);
              setTimeout(() => {
                getMyTokens(user.email);
              }, 1000);
            }, 1000);
          }}
        />
      )}
      <div className="main-up">
        {showQr ? (
          <QrCode
            closeQr={() => {
              setShowQr(false);
              props.closeQr();
            }}
            publicKey={userData.pub_key}
          />
        ) : null}
        {showLedgerModal ? (
          <div
            className="QR-home"
            style={{
              backgroundColor: 'black',
              width: '80%',
              justifyContent: 'space-between',
              maxHeight: '400px',
              overflowX: 'hidden',
              overflowY: 'auto'
            }}
          >
            <div className="header-Qr" style={{ backgroundColor: '#0095C8' }}>
              <span
                style={{
                  fontSize: '1.2rem',
                  color: 'white',
                  fontWeight: 400
                }}
              >
                {' '}
                PRIVATE LEDGER
              </span>
              <button
                className="close-btn"
                style={{ width: '45px' }}
                onClick={() => setShowLedgerModal(false)}
              >
                Close
              </button>
            </div>

            <h2 style={{ color: '#0095C8', fontWeight: 400 }}>BLOCK DETAILS</h2>
            {modaldataLedger.escrow_status !== null ? (
              <div
                div
                className="row"
                style={{
                  width: '85%',
                  overflowX: 'clip',
                  color: 'white',
                  fontWeight: 400
                }}
              >
                {modaldataLedger.from_uid == userData.user_id ? (
                  <>
                    {modaldataLedger.escrow_status == '' && (
                      <button
                        style={{
                          backgroundColor: 'green',
                          border: 'none',
                          borderRadius: '5px',
                          margin: '5px',
                          padding: '5px',
                          color: '#fff'
                        }}
                        onClick={() => {
                          releaseEscrow(modaldataLedger);
                        }}
                      >
                        Release Escrow
                      </button>
                    )}
                    {modaldataLedger.escrow_status == '' && (
                      <button
                        style={{
                          backgroundColor: 'red',
                          margin: '5px',
                          padding: '5px',
                          border: 'none',
                          borderRadius: '5px',
                          color: '#fff'
                        }}
                        onClick={() => {
                          cancelEscrow(modaldataLedger);
                        }}
                      >
                        Cancel Escrow
                      </button>
                    )}
                  </>
                ) : (
                  <div>
                    {modaldataLedger.escrow_status == '' && (
                      <button
                        style={{
                          backgroundColor: 'red',
                          margin: '5px',
                          padding: '5px',
                          border: 'none',
                          borderRadius: '5px',
                          color: '#fff'
                        }}
                      >
                        Accept Escrow
                      </button>
                    )}
                  </div>
                )}
              </div>
            ) : null}

            <div
              className="row"
              style={{
                width: '85%',
                overflowX: 'clip',
                color: 'white',
                fontWeight: 400
              }}
            >
              Sender's Public Key:
            </div>

            <div
              className="row"
              style={{
                maxWidth: '85%',
                overflowX: 'clip',
                color: 'white',
                width: '85%',
                fontWeight: 400
              }}
            >
              {modaldataLedger?.public_key}
            </div>
            <br></br>
            <div
              className="row"
              style={{
                width: '85%',
                overflowX: 'clip',
                color: 'white',
                fontWeight: 400
              }}
            >
              Value:
            </div>

            <div
              className="row"
              style={{
                color: 'white',
                width: '85%',
                fontWeight: 400
              }}
            >
              {modaldataLedger?.value}{' '}
              {modaldataLedger?.action?.toLowerCase() === 'send'
                ? 'PECU'
                : modaldataLedger?.action?.split('_').join(' ')?.toUpperCase()}
            </div>
            <br></br>
            <div
              className="row"
              style={{
                width: '85%',
                overflowX: 'clip',
                color: 'white',
                fontWeight: 400
              }}
            >
              Receiver's Public Key:
            </div>

            <div
              className="row"
              style={{
                overflowX: 'hidden',
                maxWidth: '85%',
                overflowX: 'clip',
                color: 'white',
                width: '85%',
                fontWeight: 400
              }}
            >
              {modaldataLedger?.to_public_key}
            </div>
            <br></br>
            <div
              className="row"
              style={{
                width: '85%',
                overflowX: 'clip',
                color: 'white',
                fontWeight: 400
              }}
            >
              Block Address:
            </div>

            <div
              className="row"
              style={{
                overflowX: 'hidden',
                maxWidth: '85%',
                overflowX: 'clip',
                color: 'white',
                width: '85%',
                fontWeight: 400
              }}
            >
              {modaldataLedger?.block_address}
            </div>
            <br></br>
            <br></br>
            <div
              className="row"
              style={{
                width: '85%',
                overflowX: 'clip',
                color: 'white',
                fontWeight: 400
              }}
            >
              Block Size:
            </div>

            <div
              className="row"
              style={{
                overflowX: 'hidden',
                maxWidth: '85%',
                overflowX: 'clip',
                color: 'white',
                width: '85%',
                fontWeight: 400
              }}
            >
              {128} bytes
            </div>
            <br></br>
            <br></br>
            <div
              className="row"
              style={{
                width: '85%',
                overflowX: 'clip',
                color: 'white',
                fontWeight: 400
              }}
            >
              Block Height:
            </div>

            <div
              className="row"
              style={{
                overflowX: 'hidden',
                maxWidth: '85%',
                overflowX: 'clip',
                color: 'white',
                width: '85%',
                fontWeight: 400
              }}
            >
              {modaldataLedger?.id}{' '}
            </div>
            <br></br>
            <br></br>
            <div
              className="row"
              style={{
                width: '85%',
                overflowX: 'clip',
                color: 'white',
                fontWeight: 400
              }}
            >
              Contract Key:
            </div>

            <div
              className="row"
              style={{
                overflowX: 'hidden',
                maxWidth: '85%',
                overflowX: 'clip',
                color: 'white',
                width: '85%',
                fontWeight: 400
              }}
            >
              {modaldataLedger?.contract_key}
            </div>
            <br></br>
            <div
              className="row"
              style={{
                width: '85%',
                overflowX: 'clip',
                color: 'white',
                fontWeight: 400
              }}
            >
              Notes:
            </div>

            <div
              className="row"
              style={{
                overflowX: 'hidden',
                maxWidth: '85%',
                overflowX: 'clip',
                color: 'white',
                width: '85%',
                fontWeight: 400
              }}
            >
              {modaldataLedger?.note}
            </div>
            <br></br>
            <div
              className="row"
              style={{
                width: '85%',
                overflowX: 'clip',
                color: 'white',
                fontWeight: 400
              }}
            >
              Private Notes:
            </div>

            <div
              className="row"
              style={{
                overflowX: 'hidden',
                maxWidth: '85%',
                overflowX: 'clip',
                color: 'white',
                width: '85%',
                fontWeight: 400
              }}
            >
              {modaldataLedger?.pnote}
            </div>
            <br></br>
            <br></br>
            <div
              className="row"
              style={{
                width: '85%',
                overflowX: 'clip',
                color: 'white',
                fontWeight: 400
              }}
            >
              Date and Time of Transaction:
            </div>

            <div
              className="row"
              style={{
                color: 'white',
                width: '85%',
                fontWeight: 400
              }}
            >
              {new Date(modaldataLedger?.date_time).toLocaleString()}
            </div>
          </div>
        ) : null}
        {welcome ? (
          <div className="QR-home" style={{ backgroundColor: 'black' }}>
            <div className="header-Qr" style={{ backgroundColor: '#0095C8' }}>
              <span style={{ fontSize: '1.2rem', color: 'white' }}>
                PECU NOVUS TERMINAL
              </span>
              <button
                className="close-btn"
                style={{ width: '45px' }}
                onClick={() => setWelcome(false)}
              >
                Close
              </button>
            </div>
            <div className="QR">
              <img src={logo} width="100px" />

              <h2 style={{ color: '#0095C8' }}>
                Welcome to Pecu Novus Terminal
              </h2>
            </div>

            <p style={{ fontStyle: 'italic' }}>
              {quotation[quoteNumber].quote} ~ {quotation[quoteNumber].by}
            </p>
            <img src={documega} width="100%" />
          </div>
        ) : null}
        <div className="main-left">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              className="main-sub-box "
              style={{
                minWidth: '250px',
                minHeight: '150px',
                maxHeight: '150px',
                height: '150px',
                overflowY: 'auto',
                overflowX: 'hidden'
              }}
            >
              <div className="last-transaction">TOKEN HOLDINGS</div>
              {tokenHoldings.length > 0 ? (
                <InfiniteScroll
                  dataLength={tokenHoldings.length}
                  next={getMyTokens}
                  hasMore={true}
                  loader={<div></div>}
                >
                  <div style={{ maxWidth: '300px' }}>
                    {tokenHoldings.length > 0
                      ? tokenHoldings.map((e, index) => (
                          <div key={index} className="table">
                            <div
                              className="row"
                              style={{
                                maxWidth: '90%',
                                overflowX: 'clip',
                                padding: '5px',
                                fontSize: '13px'
                              }}
                            >
                              <span
                                style={{
                                  color: '#0095C8',
                                  fontWeight: 'bold'
                                }}
                              >
                                {e?.symbol
                                  ?.split('')
                                  .map((e, i) => (i == 0 ? e.toLowerCase() : e))
                                  .join('')}
                              </span>
                            </div>
                            <div
                              className="row"
                              style={{
                                width: '100%',
                                maxWidth: '100%',
                                overflowX: 'clip',
                                padding: '5px',
                                fontSize: '13px'
                              }}
                            >
                              Available : {e?.assetAmount}
                            </div>

                            <div
                              className="row"
                              style={{
                                padding: '5px',
                                fontSize: '13px',
                                maxWidth: '200px',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              Value : <IoLogoUsd />
                              {parseFloat(
                                e?.assetAmount *
                                  cryptos[e.symbol?.toUpperCase()]
                              ).toLocaleString('en-US')}
                            </div>
                            {/* <button
                                className="transfer-btn"
                                onClick={() => {
                                  setTransferTokenModal(true);
                                  setTransferToken(e);
                                }}
                              >
                                Transfer
                              </button> */}
                          </div>
                        ))
                      : ''}
                  </div>
                </InfiniteScroll>
              ) : null}
              {holdingTokenCompany.length > 0 ? (
                <InfiniteScroll
                  dataLength={holdingTokenCompany.length}
                  next={getMyTokens}
                  hasMore={true}
                  loader={<div></div>}
                >
                  <div style={{ maxWidth: '300px' }}>
                    {holdingTokenCompany.length > 0
                      ? holdingTokenCompany.map((e, index) => (
                          <div key={index} className="table">
                            <div
                              className="row"
                              style={{
                                maxWidth: '90%',
                                overflowX: 'clip',
                                padding: '5px',
                                fontSize: '13px'
                              }}
                            >
                              <span
                                style={{
                                  color: '#0095C8',
                                  fontWeight: 'bold'
                                }}
                              >
                                {e?.token_symbol}
                              </span>
                            </div>
                            <div
                              className="row"
                              style={{
                                width: '100%',
                                maxWidth: '100%',
                                overflowX: 'clip',
                                padding: '5px',
                                fontSize: '13px'
                              }}
                            >
                              Available : {e?.amount_issued}
                            </div>

                            <div
                              className="row"
                              style={{
                                padding: '5px',
                                fontSize: '13px',
                                maxWidth: '200px',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              Value : <IoLogoUsd />
                              {parseFloat(
                                e?.amount_issued * e.token_price
                              ).toLocaleString('en-US')}
                            </div>
                            {/* <button
                                className="transfer-btn"
                                onClick={() => {
                                  setTransferTokenModal(true);
                                  setTransferToken(e);
                                }}
                              >
                                Transfer
                              </button> */}
                          </div>
                        ))
                      : ''}
                  </div>
                </InfiniteScroll>
              ) : null}

              {conversionToken.length > 0 ? (
                <InfiniteScroll
                  dataLength={conversionToken.length}
                  next={getMyConversionTokens}
                  hasMore={true}
                  loader={<div></div>}
                >
                  <div style={{ maxWidth: '300px' }}>
                    {conversionToken.length > 0
                      ? conversionToken.map((e, index) => (
                          <div key={index} className="table">
                            <div
                              className="row"
                              style={{
                                maxWidth: '90%',
                                overflowX: 'clip',
                                padding: '5px',
                                fontSize: '13px'
                              }}
                            >
                              <span
                                style={{
                                  color: '#0095C8',
                                  fontWeight: 'bold'
                                }}
                              >
                                {e.token_symbol}
                              </span>
                            </div>
                            <div
                              className="row"
                              style={{
                                width: '100%',
                                maxWidth: '100%',
                                overflowX: 'clip',
                                padding: '5px',
                                fontSize: '13px'
                              }}
                            >
                              Available : {e.amount_issued}
                            </div>

                            <div
                              className="row"
                              style={{
                                padding: '5px',
                                fontSize: '13px',
                                maxWidth: '200px',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              Value : <IoLogoUsd />
                              {parseFloat(e.token_price).toLocaleString(
                                'en-US'
                              )}
                            </div>
                            {/* <button
                                className="transfer-btn"
                                onClick={() => {
                                  setTransferTokenModal(true);
                                  setTransferToken(e);
                                }}
                              >
                                Transfer
                              </button> */}
                          </div>
                        ))
                      : null}
                  </div>
                </InfiniteScroll>
              ) : null}

              {conversionTokenCompany.length > 0 ? (
                <InfiniteScroll
                  dataLength={conversionTokenCompany.length}
                  next={getMyConversionTokensCompany}
                  hasMore={true}
                  loader={<div></div>}
                >
                  <div style={{ maxWidth: '300px' }}>
                    {conversionTokenCompany.length > 0
                      ? conversionTokenCompany.map((e, index) => (
                          <div key={index} className="table">
                            <div
                              className="row"
                              style={{
                                maxWidth: '90%',
                                overflowX: 'clip',
                                padding: '5px',
                                fontSize: '13px'
                              }}
                            >
                              <span
                                style={{
                                  color: '#0095C8',
                                  fontWeight: 'bold'
                                }}
                              >
                                {e.symbol}
                              </span>
                            </div>
                            <div
                              className="row"
                              style={{
                                width: '100%',
                                maxWidth: '100%',
                                overflowX: 'clip',
                                padding: '5px',
                                fontSize: '13px'
                              }}
                            >
                              Available : {e.assetAmount}
                            </div>

                            {/* <div
                                className="row"
                                style={{
                                  padding: '5px',
                                  fontSize: '13px',
                                  maxWidth: '200px',
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center'
                                }}
                              >
                                Value : <IoLogoUsd />
                                {parseFloat(e.token_price).toLocaleString(
                                  'en-US'
                                )}
                              </div> */}
                            {/* <button
                                className="transfer-btn"
                                onClick={() => {
                                  setTransferTokenModal(true);
                                  setTransferToken(e);
                                }}
                              >
                                Transfer
                              </button> */}
                          </div>
                        ))
                      : null}
                  </div>
                </InfiniteScroll>
              ) : null}
            </div>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
              className="main-sub-box "
              style={{
                minWidth: '250px',
                minHeight: '150px',
                maxHeight: '150px',
                height: '150px',
                overflowY: 'auto',
                overflowX: 'hidden'
              }}
            >
              <div className="last-transaction">PECU HOLDINGS</div>
              <div style={{ maxWidth: '300px' }}>
                <div
                  className="table"
                  style={{
                    width: '250px',
                    minHeight: '80px',
                    height: '80px'
                  }}
                >
                  <div
                    className="row"
                    style={{
                      maxWidth: '100%',
                      width: '100%',
                      overflowX: 'clip',
                      padding: '5px',
                      fontSize: '13px'
                    }}
                  >
                    {' '}
                    <span
                      style={{
                        color: '#0095C8',
                        fontWeight: 'bold'
                      }}
                    >
                      PECU
                    </span>{' '}
                  </div>

                  <div
                    className="row"
                    style={{
                      maxWidth: '100%',
                      width: '100%',
                      overflowX: 'clip',
                      padding: '5px',
                      fontSize: '13px'
                    }}
                  >
                    Available : {parseFloat(totalCoins).toLocaleString('en-US')}
                  </div>
                  {/* <div className="row" style={{width:'100%',maxWidth:'100%',overflowX:'clip',padding:'5px',fontSize:'13px'}}>Value:<IoLogoUsd />{parseFloat(totalValue).toLocaleString('en-US')}</div> */}

                  {/* <div className="row" style={{padding:'5px',fontSize:'13px',maxWidth:'200px',width:'100%',display:'flex',alignItems:'center'}}>Value: <IoLogoUsd />{parseFloat(e.initialFinal).toLocaleString('en-US')}</div>  */}
                  {/* <div className="row" style={{padding:'5px',fontSize:'13px'}}>{new Date(e.date_time).toLocaleString() }</div> */}
                  <div
                    className="row"
                    style={{
                      maxWidth: '100%',
                      width: '100%',
                      maxWidth: '100%',
                      overflowX: 'clip',
                      padding: '5px',
                      fontSize: '13px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    Value : <IoLogoUsd />
                    {parseFloat(totalValue).toLocaleString('en-US')}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="main-sub-box"
            style={{
              minWidth: '250px',
              minHeight: '100px',
              maxHeight: '100px',
              height: '100px',
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
          >
            <div className="total-coin-title">ESCROW</div>
            {/* <BsPiggyBankFill style={{margin:'1rem'}} size={40} color="brown" /> */}

            <div
              className="table"
              style={{
                width: '250px',
                minHeight: totalEscrowedCoins.length > 1 ? '100%' : '50px'
              }}
            >
              {/* <div className="row" style={{ maxWidth: '90%', overflowX: 'clip', padding: '5px', fontSize: '13px' }}><span style={{color:'#0095C8',fontWeight:'bold'}}>Amount : {totalCoins}</span></div> */}
              <div
                className="row"
                style={{
                  maxWidth: '100%',
                  width: '100%',
                  overflowX: 'clip',
                  padding: '5px',
                  fontSize: '13px',
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <span
                  style={{
                    color: '#0095C8',
                    fontWeight: 'bold'
                  }}
                >
                  PECU{' '}
                </span>{' '}
                : {parseFloat(totalEscrowedCoins).toLocaleString('en-US')}
              </div>
              {totalEscrowedTokens.length > 0 &&
                totalEscrowedTokens.map((e, i) => (
                  <div
                    key={i}
                    className="row"
                    style={{
                      maxWidth: '100%',
                      width: '100%',
                      overflowX: 'clip',
                      padding: '5px',
                      fontSize: '13px',
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <span
                      style={{
                        color: '#0095C8',
                        fontWeight: 'bold'
                      }}
                    >
                      {e.symbol
                        .split('')
                        .map((e, i) => (i == 0 ? e.toLowerCase() : e))
                        .join('')}
                    </span>{' '}
                    : {parseFloat(e?.wrapAmount).toLocaleString('en-US')}
                  </div>
                ))}
            </div>
          </div>
          {/* <div className="main-sub-box">
           
          </div>
         */}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}
        >
          {' '}
          <h3 className="total-coin-title">PECU CHART</h3>
          <div className="main-right" style={{ flexDirection: 'row-reverse' }}>
            {/* <button
              className="chart-btn"
              onClick={() => {
                getChatData('yearly');
              }}
            >
              12M
            </button>
            <button
              className="chart-btn"
              onClick={() => {
                getChatData('quaterly');
              }}
            >
              3M
            </button>
            <button
              className="chart-btn"
              onClick={() => {
                getChatData('monthly');
              }}
            >
              1M
            </button>
            <button
              className="chart-btn"
              onClick={() => {
                getChatData('weekly');
              }}
            >
              7D
            </button>
            <button
              className="chart-btn"
              onClick={() => {
                getChatData('hourly');
              }}
            >
              1D
            </button> */}

            <ResponsiveContainer width="100%" height={300}>
              {/* <AreaChart
                  data={chartData}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0
                  }}
                >
                  <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="5%"
                        stopColor="#0095C8"
                        stopOpacity={0.8}
                      />
                      <stop
                        offset="95%"
                        stopColor="#0095C8"
                        stopOpacity={0}
                      />
                    </linearGradient>
                    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                      <stop
                        offset="5%"
                        stopColor="#0095C8"
                        stopOpacity={0.8}
                      />
                      <stop
                        offset="95%"
                        stopColor="#0095C8"
                        stopOpacity={0}
                      />
                    </linearGradient>
                  </defs>
                  <XAxis dataKey="chart_date" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="3 3" />
                  <Tooltip />
                  <Area
                    type="monotone"
                    dataKey="today_value"
                    stroke="#0095C8"
                    fillOpacity={1}
                    fill="url(#colorUv)"
                  />
                </AreaChart> */}
              <CoinInfo coin={{}} />
            </ResponsiveContainer>
          </div>
        </div>

        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            className="main-sub-box "
            style={{
              minWidth: '250px',
              minHeight: '135px',
              maxHeight: '135px',
              height: '135px',
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
            id="scrollableDiv"
          >
            <div className="last-transaction">YOUR PECU LEDGER</div>
            {latestTransactions.length > 0 ? (
              <InfiniteScroll
                dataLength={latestTransactions.length}
                next={() => {
                  my_latest_transactions(user.user_id, user.pub_key);
                }}
                scrollableTarget="scrollableDiv"
                hasMore={true}
                loader={<div style={{ fontSize: '10px' }}>Loading more...</div>}
              >
                <div style={{ maxWidth: '300px' }}>
                  {latestTransactions.length > 0
                    ? latestTransactions.map((e, index) => (
                        <div
                          onClick={() => {
                            if (e.block_address && e.block_address.length > 0) {
                              setModaldataLedger(e);
                            } else {
                              e.block_address = makeid(128);
                              setModaldataLedger(e);
                            }
                            setShowLedgerModal(true);
                          }}
                          className="table"
                          key={index}
                        >
                          <div
                            className="row"
                            style={{
                              maxWidth: '80%',
                              overflowX: 'clip',
                              padding: '5px',
                              fontSize: '13px'
                            }}
                          >
                            {e.to_public_key}
                          </div>
                          <div
                            className="row"
                            style={{
                              padding: '5px',
                              fontSize: '13px'
                            }}
                          >
                            <span
                              style={{
                                color: '#0095C8',
                                fontWeight: 'bold'
                              }}
                            >
                              {' '}
                              {e.value}{' '}
                              {e?.action?.toLowerCase() === 'send'
                                ? 'PECU'
                                : e?.action
                                    ?.split('_')
                                    .join(' ')
                                    ?.toUpperCase()}
                            </span>
                          </div>
                          <div
                            className="row"
                            style={{
                              padding: '5px',
                              fontSize: '13px',
                              maxWidth: '200px',
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            {new Date(e.date_time).toLocaleString()}
                          </div>
                        </div>
                      ))
                    : 'Loading...'}
                </div>
              </InfiniteScroll>
            ) : (
              <h5>Loading</h5>
            )}

            {/* <div className="no-record">No Data Found</div> */}
            {/* <BsGraphUp size={260} style={{border:'none',fontWeight:'300'}} /> */}
          </div>
          {tokens && tokens.length > 0 ? (
            <div
              className="main-sub-box "
              style={{
                minWidth: '250px',
                minHeight: '135px',
                maxHeight: '135px',
                height: '135px',
                overflowY: 'auto',
                overflowX: 'hidden'
              }}
            >
              <div className="last-transaction">YOUR TOKEN LEDGER</div>
              {tokens.length > 0 ? (
                <InfiniteScroll
                  dataLength={tokens.length}
                  next={() => {
                    console.log('not more yet');
                  }}
                  hasMore={false}
                  loader={<div></div>}
                >
                  <div style={{ maxWidth: '300px' }}>
                    {tokens.length > 0
                      ? tokens.map((e, index) => (
                          <div
                            //                 onClick={() => {
                            //         if(e.block_address && e.block_address.length>0){
                            // setModaldataLedger(e)
                            //         }else{

                            //           e.block_address=makeid(128)
                            //           setModaldataLedger(e)
                            //         }
                            //       ;setShowLedgerModal(true)

                            //                 }}
                            className="table"
                          >
                            <div
                              className="row"
                              style={{
                                width: '100%',
                                maxWidth: '100px',
                                overflowX: 'clip',
                                padding: '5px',
                                fontSize: '13px'
                              }}
                            >
                              {e.public_key}
                            </div>
                            <div
                              className="row"
                              style={{
                                maxWidth: '90%',
                                overflowX: 'clip',
                                padding: '5px',
                                fontSize: '13px'
                              }}
                            >
                              <span
                                style={{
                                  color: '#0095C8',
                                  fontWeight: 'bold'
                                }}
                              >
                                {e?.assetAmount} {e?.symbol}
                              </span>
                            </div>
                            {/* <div className="row" style={{width:'100%',maxWidth:'100%',overflowX:'clip',padding:'5px',fontSize:'13px'}}>Available : {e.wrapAmount}</div>
      
       <div className="row" style={{padding:'5px',fontSize:'13px',maxWidth:'200px',width:'100%',display:'flex',alignItems:'center'}}>Value : <IoLogoUsd />{parseFloat(e.initialFinal).toLocaleString('en-US')}</div>  */}
                            <div
                              className="row"
                              style={{
                                padding: '5px',
                                fontSize: '13px',
                                maxWidth: '200px',
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center'
                              }}
                            >
                              {new Date(e.createdAt).toLocaleString()}
                            </div>
                          </div>
                        ))
                      : 'Loading...'}
                  </div>
                </InfiniteScroll>
              ) : (
                <h5>Loading</h5>
              )}
            </div>
          ) : null}

          <div
            className="main-sub-box "
            style={{
              minWidth: '250px',
              minHeight: '135px',
              maxHeight: '135px',
              height: '135px',
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
            id="scrollableDivEscrow"
          >
            <div className="last-transaction"> YOUR ESCROW LEDGER</div>
            {escrowData.length > 0 ? (
              <InfiniteScroll
                dataLength={escrowData.length}
                next={() => {
                  my_latest_transactions_escrow(user.user_id);
                }}
                hasMore={true}
                scrollableTarget="scrollableDivEscrow"
                loader={<div style={{ fontSize: '10px' }}>Loading more...</div>}
              >
                <div style={{ maxWidth: '300px' }}>
                  {escrowData.length > 0
                    ? escrowData.map((e, index) => (
                        <div
                          onClick={() => {
                            if (e.block_address && e.block_address.length > 0) {
                              setModaldataLedger(e);
                            } else {
                              e.block_address = makeid(128);
                              setModaldataLedger(e);
                            }
                            setShowLedgerModal(true);
                          }}
                          className="table"
                        >
                          <div
                            className="row"
                            style={{
                              maxWidth: '80%',
                              overflowX: 'clip',
                              padding: '5px',
                              fontSize: '13px'
                            }}
                          >
                            {e.to_public_key}
                          </div>
                          <div
                            className="row"
                            style={{
                              padding: '5px',
                              fontSize: '13px',
                              flexDirection: 'row',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center'
                            }}
                          >
                            {' '}
                            <span
                              style={{
                                color: '#0095C8',
                                fontWeight: 'bold'
                              }}
                            >
                              {e.value} PECU
                            </span>
                            <div
                              style={{
                                padding: '5px',
                                fontSize: '13px',
                                color: 'yellow'
                              }}
                            >
                              {' '}
                              {e.from_uid == userData.user_id ? `=>` : `<=`}
                            </div>
                          </div>
                          <div
                            className="row"
                            style={{
                              padding: '5px',
                              fontSize: '13px'
                            }}
                          >
                            {new Date(e.date_time).toLocaleString()}
                          </div>

                          <div
                            className="row"
                            style={{
                              padding: '5px',
                              fontSize: '13px',
                              color:
                                e.escrow_status == 'CANCEL' ? 'red' : 'green'
                            }}
                          >
                            Escrow{' '}
                            {e.escrow_status == ''
                              ? 'Pending'
                              : e.escrow_status == 'CANCEL'
                              ? 'Cancelled'
                              : 'Released'}
                          </div>
                        </div>
                      ))
                    : 'Loading...'}
                </div>
              </InfiniteScroll>
            ) : (
              <h3>Loading</h3>
            )}

            {/* <div className="no-record">No Data Found</div> */}
            {/* <BsGraphUp size={260} style={{border:'none',fontWeight:'300'}} /> */}
          </div>
          <div
            className="main-sub-box "
            style={{
              minWidth: '250px',
              minHeight: '135px',
              maxHeight: '135px',
              height: '135px',
              overflowY: 'auto',
              overflowX: 'hidden'
            }}
            id="scrollableDivEscrow"
          >
            <div className="last-transaction">MY NODE REWARDS</div>
            {/* {escrowData.length > 0 ? (
              <InfiniteScroll
                hasMore={true}
                scrollableTarget="scrollableDivEscrow"
                loader={<div style={{ fontSize: '10px' }}>Loading more...</div>}
              >
                <div style={{ maxWidth: '300px' }}></div>
              </InfiniteScroll>
            ) : (
              <h3>Loading</h3>
            )} */}

            {/* <div className="no-record">No Data Found</div> */}
            {/* <BsGraphUp size={260} style={{border:'none',fontWeight:'300'}} /> */}
          </div>
        </div>
      </div>
      <div className="main-down">
        <div
          className="main-sub-box"
          style={{
            maxHeight: '40px',
            maxWidth: '110px',
            color: trend.trend == '-1' ? 'red' : 'green'
          }}
        >
          24h Trend{' '}
          {trend.trend == '-1' ? (
            <HiTrendingDown color="red" size={40} />
          ) : (
            <HiTrendingUp color="green" size={40} />
          )}
        </div>
        <div
          className="main-sub-box"
          style={{
            maxHeight: '40px',
            maxWidth: '110px',
            fontSize: '14px'
          }}
        >
          Current Value
          <span>{`$${parseFloat(currentValue).toFixed(2)} USD`}</span>
        </div>
        <div
          className="main-sub-box"
          style={{
            maxHeight: '40px',
            maxWidth: '110px',
            fontSize: '14px',
            color:
              trendHourly.trend == '-1'
                ? 'red'
                : trendHourly.trend == '1'
                ? 'green'
                : 'white'
          }}
        >
          1h Change
          <span>
            {trendHourly.trend == '-1' ? '' : '+'}{' '}
            {parseFloat(trendHourly.value).toFixed(2)} %
          </span>
        </div>
        <div
          className="main-sub-box"
          style={{
            maxHeight: '40px',
            maxWidth: '110px',
            fontSize: '14px',
            color: trend.trend == '-1' ? 'red' : 'green'
          }}
        >
          24h Change
          <span>
            {trend.trend == '-1' ? '' : '+'} {trend.value} %
          </span>
        </div>

        <div
          className="main-sub-box"
          style={{
            maxHeight: '40px',
            maxWidth: '110px',
            fontSize: '14px'
          }}
        >
          PECU Volume
          <span>
            {parseFloat(coinVolume).toLocaleString('en-US')}
            {/* {parseFloat(coinVolume).toFixed(8).toLocaleString('en-US')} */}
          </span>
        </div>
        <div
          className="main-sub-box"
          style={{
            maxHeight: '40px',
            maxWidth: '110px',
            fontSize: '14px'
          }}
        >
          USD Volume
          <span>{`$${parseFloat(
            (parseFloat(coinVolume) * parseFloat(currentValue)).toFixed(2)
          ).toLocaleString('en-US')}`}</span>
        </div>
        <div
          className="main-sub-box"
          style={{
            maxHeight: '40px',
            maxWidth: '110px',
            fontSize: '14px'
          }}
        >
          Market Cap
          <span>${parseInt(marketCap).toLocaleString('en-US')}</span>
        </div>
        {/* <div
          className="main-sub-box"
          style={{
            maxHeight: '40px',
            maxWidth: '119px',
            fontSize: '14px'
          }}
        >
          Circulating Supply
          <span>{parseInt(circulatingSupply).toLocaleString('en-US')}</span>
        </div> */}
        <div
          className="main-sub-box"
          style={{
            maxHeight: '40px',
            maxWidth: '110px',
            fontSize: '14px'
          }}
        >
          Total Supply
          <span>{parseInt(circulatingSupply).toLocaleString('en-US')}</span>
        </div>
      </div>
    </div>
  ) : (
    <div>
      <div className="main webscanner" style={{ minHeight: '100vh' }}>
        <div className="QR-home" style={{ border: '' }}>
          <div className="header-Qr" style={{ backgroundColor: '#0095C8' }}>
            <span style={{ fontSize: '1.2rem' }}>
              PECU NOVUS DIGITAL WALLET SCANNER CODE
            </span>
            {/* <button className='close-btn' onClick={()=>closeQr()}>X</button> */}
          </div>
          <div className="QR">
            <p>
              Scan This QR Code with your PECU NOVUS Mobile Wallet to access the
              terminal
            </p>

            {privateKey ? (
              <QRCode value={privateKey} bgColor="#ffffff" />
            ) : (
              'Loading,Please wait'
            )}
          </div>

          <p>
            Note:If you already scanned it earlier,just turn on the Terminal
            Connection from your PECU NOVUS Mobile Wallet to access the terminal{' '}
          </p>
        </div>
        ;
      </div>
    </div>
  );
}

export default memo(Main);

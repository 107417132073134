import React, { useState } from 'react';
import { FaUserShield } from 'react-icons/fa';
import '../Login/login.css';
import axios from 'axios';
import logo from '../../Assets/displayicon.png';
import loginRight from '../../Assets/login-right.png';
import url from '../../server_url';
export default function Signup(props) {
  const [field, setField] = useState({
    email: '',
    password: '',
    firstname: '',
    lastname: ''
  });
  const [error, setError] = useState('');
  const [loginerror, setLoginerror] = useState(false);
  const [checkbox, setCheckbox] = useState(false);
  const handleChange = (event) => {
    setField({ ...field, [event.target.name]: event.target.value });
  };
  const handleSubmit = () => {
    axios
      .post(`${url}/api/v2/auth/register`, {
        real_name: field.firstname,
        last_name: field.lastname,
        email: field.email,
        password: field.password
      })
      .then((res) => {
        if (res.data.signup) {
          window.location.replace('/');
        } else {
          setError(res.data.message);
          setLoginerror(true);
          setTimeout(() => {
            setLoginerror(false);
            setError('');
          }, 3000);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
        background: 'linear-gradient(to right, #1F2834 50%, #12181F 50%)',
        position: 'relative'
      }}
    >
      {' '}
      <img
        src={require('../../Assets/leftring.png')}
        style={{
          width: window.innerWidth < 700 ? '200px' : '300px',
          position: 'absolute',
          bottom: 0,
          left: 0
        }}
      />
      <img
        src={require('../../Assets/rightring.png')}
        style={{
          width: window.innerWidth < 700 ? '200px' : '300px',
          position: 'absolute',
          top: -100,
          right: 0
        }}
      />
      {loginerror ? (
        <div
          className="QR-home"
          style={{
            padding: '0px',
            border: 'none',
            maxWidth: '200px',
            maxHeight: '40px',
            zIndex: '9999',
            marginTop: '100px'
          }}
        >
          <div
            className="header-Qr"
            style={{ padding: '5px', width: '100%', borderRadius: '5px' }}
          >
            <span
              style={{ fontSize: '1rem', color: 'white', fontWeight: '400' }}
            >
              {error}
            </span>
            <button
              style={{ cursor: 'pointer' }}
              onClick={() => setLoginerror(false)}
            >
              X
            </button>
          </div>
        </div>
      ) : null}
      <div className="login-card" style={{ flex: '1' }}>
        {/* <FaUserShield size={80} className="login-logo" /> */}
        <h3 style={{ color: 'white' }}>Create your Pecu Wallet</h3>
        <p style={{ color: 'gray', marginTop: '0px', fontSize: '12px' }}>
          You will need your Pecu Novus Mobile Wallet to Log in.{' '}
        </p>
        <form onSubmit={(e) => e.preventDefault()}>
          <label>First Name</label>
          <input
            placeholder="John"
            required
            name="firstname"
            onChange={handleChange}
            value={field?.firstname}
          />

          <label>Last Name</label>
          <input
            placeholder="doe"
            required
            name="lastname"
            onChange={handleChange}
            value={field?.lastname}
          />

          <label>Email ID</label>
          <input
            placeholder="Email ID"
            name="email"
            required
            type={'email'}
            onChange={handleChange}
            value={field?.email}
          />
          <lable>Password</lable>
          <input
            autoComplete="off"
            required
            placeholder="password"
            type={'password'}
            name="password"
            onChange={handleChange}
            value={field?.password}
          />

          <div className="extra-info" style={{ justifyContent: 'flex-start' }}>
            {/* <span>
                            <input type={"checkbox"} defaultChecked />
                            Stay Logged in
                        </span> */}
            <input
              required
              type={'checkbox'}
              onChange={() => setCheckbox(!checkbox)}
            />
            <a
              href="/TermsOfService"
              style={{
                color: '#0095C8',
                marginLeft: '10px',
                textDecoration: 'none'
              }}
            >
              I agree to terms and conditions
            </a>
          </div>
          <button
            onClick={() => {
              handleSubmit();
            }}
            className="primary loginbtn"
          >
            Register
          </button>
        </form>

        <span
          style={{
            width: '100%',
            textAlign: 'center',
            fontSize: '10px',
            color: 'white'
          }}
        >
          OR
        </span>
        <button className="primary signUpbtn">
          <a href="/" style={{ color: 'white', textDecoration: 'none' }}>
            Login
          </a>
        </button>
        {/* <div className="extra-info">
                        <a href="/signup" className="signup-text">
                            Create New Wallet
                        </a>
                    </div> */}
      </div>
    </div>
  );
}

import axios from 'axios';
import React, { useState } from 'react';
import url from '../server_url';

export default function TransferToken({ token, user, updatepubkey, closeMe }) {
  const [transferAmount, setTransferAmount] = useState(1);
  const [loading, setLoading] = useState(false);
  const [publicKey, setPublicKey] = useState('');
  const [escrow, setEscrow] = useState(false);
  const [releasedate, setReleaseDate] = useState('');
  const updatepubkeyFun = (id) => {
    axios
      .post(`${url}/wallet/update-public-key`, {
        user_id: id
      })
      .then((res) => {
        if (res.data.status == 200) {
          console.log('success key update');
          updatepubkey(res.data.pub_key);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleSumbit = (e) => {
    e.preventDefault();
    setLoading(true);
    const bodyData = {
      token,
      publicKey,
      transferAmount,
      newPrice: Math.floor(token.initialFinal / token.wrapAmount).toFixed(2)
    };
    const bodyDataEscrow = {
      token,
      publicKey,
      transferAmount,
      newPrice: Math.floor(token.initialFinal / token.wrapAmount).toFixed(2),
      escrowReleaseDate: releasedate
    };
    if (!publicKey || publicKey.length == 0) {
      alert('Please enter a valid public key');
      setLoading(false);
      return;
    }
    const sendBody = escrow ? bodyDataEscrow : bodyData;
    const apiUrl = escrow
      ? `${url}/wallet/escrow_token`
      : `${url}/wallet/transfer_token`;
    axios
      .post(apiUrl, sendBody)
      .then((res) => {
        if (res.status === 200) {
          updatepubkeyFun(user.user_id);
          alert(res.data.message);
          setPublicKey();
          setTransferAmount(1);
          closeMe();
        }
      })
      .catch((err) => alert(err.message))
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="sendCoins-main" style={{ zIndex: 999 }}>
      <div
        className="total-coin-title"
        style={{ fontSize: '1.5rem', fontWeight: '500' }}
      >
        Transfer Tokens
      </div>
      {/* <div className="loading">Loading</div> */}
      <form onSubmit={handleSumbit}>
        <div className="card flex-col">
          <span style={{ color: '#0095C8', fontWeight: 'bold' }}>
            {token.symbol
              .split('')
              .map((e, i) => (i == 0 ? e.toLowerCase() : e))
              .join('')}
          </span>
          <p>Available amount : {token.wrapAmount} </p>
          <p>Total Value : $ {token.initialFinal}</p>
          <p>
            1{' '}
            {token.symbol
              .split('')
              .map((e, i) => (i == 0 ? e.toLowerCase() : e))
              .join('')}{' '}
            Value : ${' '}
            {Math.floor(token.initialFinal / token.wrapAmount).toFixed(2)}
          </p>
        </div>
        <input
          type={'text'}
          placeholder="Token"
          value={token.symbol
            .split('')
            .map((e, i) => (i === 0 ? e.toLowerCase() : e))
            .join('')}
          disabled
        />

        <input
          type={'text'}
          value={publicKey}
          onChange={(e) => {
            setPublicKey(e.target.value);
          }}
          placeholder="Public key"
        />
        <input
          type={'number'}
          max={token.wrapAmount}
          value={transferAmount}
          onChange={(e) => {
            e.target.value <= token.wrapAmount &&
              setTransferAmount(e.target.value);
          }}
          placeholder="Amount"
        />
        <input
          type={'number'}
          value={Math.floor(
            (transferAmount * token.initialFinal) / token.wrapAmount
          ).toFixed(2)}
          placeholder="Value"
          disabled
        />
        <label style={{ textAlign: 'center' }}>Agree to terms to proceed</label>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',

            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          {' '}
          <input
            type={'checkbox'}
            style={{
              width: !escrow ? '20px' : '50px',
              height: !escrow ? '20px' : '50px'
            }}
            onChange={(e) => {
              setEscrow(!escrow);
            }}
          />
          <br></br>
          <p style={{ minWidth: '300px' }}>
            {' '}
            MVault Escrow Smart Contract (optional)
          </p>
          {escrow && (
            <input
              type={'datetime-local'}
              onChange={(e) => setReleaseDate(e.target.value)}
            />
          )}
        </div>
        <button className="transfer-btn" disabled={loading}>
          {loading ? 'Please Wait' : 'Confirm Transfer'}
        </button>
      </form>
    </div>
  );
}

/* eslint-disable jsx-a11y/alt-text */
const MobileApps = () => {
  return (
    <div className="main downloadApp">
      <div className="QR-home" style={{ border: '' }}>
        <div className="header-Qr" style={{ backgroundColor: '#0095C8' }}>
          <span style={{ fontSize: '1.2rem' }}>Download App</span>
          {/* <button className='close-btn' onClick={()=>closeQr()}>X</button> */}
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly'
          }}
        >
          {' '}
          <a
            href="https://play.google.com/store/apps/details?id=com.pecunovus"
            className="downloadbtn"
            style={{
              backgroundColor: 'transparent',
              border: '1px solid #0095C8',
              paddingTop: 6,
              paddingBottom: 6
            }}
          >
            <img
              src={
                'https://upload.wikimedia.org/wikipedia/commons/7/7a/Google_Play_2022_logo.svg'
              }
              style={{ width: 150 }}
            />
          </a>
          <a
            className="downloadbtn"
            href="https://api.pecunovus.net/Android/fhhf6758758fdffhsfjuyvufgfu87346587bfg835t578fg873t587gsfhg783465fkjf/PecuNovusWallet.apk"
          >
            Download Android App
          </a>
          <a
            className="downloadbtn"
            style={{ backgroundColor: 'gray' }}
            href="#"
          >
            {' '}
            IOS Mobile App
          </a>
          <a className="downloadbtn" href="/login">
            {' '}
            Mobile Web Access
          </a>
        </div>
      </div>
    </div>
  );
};

export default MobileApps;

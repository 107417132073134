import React from 'react'

export default function PrivacyPolicyNFT() {
  return (
    <div style={{padding:'2rem',color:'black',backgroundColor:'whitesmoke',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
    <p style={{lineHeight:'40px',width:'80%',textJustify:'inter-word'}}>
    Acceptance and Understanding of NFT Minting Terms 
    </p>
    <p style={{lineHeight:'40px',width:'80%',textJustify:'inter-word'}}>
I attest that the artwork that I am minting a non-fungible token “NFT” for is my original artwork, the artwork was created by me and I have full ownership and full rights to offer it for sale, transfer or otherwise. That the artwork is not represented by an NFT on any other platform and will only be represented by the NFT minted on the Pecu Novus Network. I agree and understand that in the event that this artwork is not original artwork by you or is an NFT minted on another platform and this is discovered that your NFT minting privileges will be suspended along with your access to the entire Pecu Novus Network. This will cause your Pecu Novus Digital Wallet to become inaccessible until a resolution is made. 
</p>
<p style={{lineHeight:'40px',width:'80%',textJustify:'inter-word'}}>
I understand that all sales of NFT’s minted on the Pecu Novus Network will be solely in Pecu Coins, this is required for the transfer to be validated and to protect the financial interest of you , the creator, for the initial sale and all future sales of this artwork. 
</p> <p style={{lineHeight:'40px',width:'80%',textJustify:'inter-word'}}>
I understand that there is no fee for minting an NFT on the Pecu Novus Network however there is a revenue share for each NFT minted and as follows: 1) 5% of the initial sale price ;  2) 5% of royalties from future transfers or sales.
</p> <p style={{lineHeight:'40px',width:'80%',textJustify:'inter-word'}}>
All NFT’s minted are securely stored in the MegaHoot Vault Safety Deposit Box where only the current NFT owners can access their owned NFT’s. 
</p> <p style={{lineHeight:'40px',width:'80%',textJustify:'inter-word'}}>
By checking below I understand, attest and agree to all of the above.</p>
  </div>
  )
}

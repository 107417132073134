import React from 'react'

export default function PrivacypolicyEscrow() {
  return (
    <div style={{padding:'2rem',color:'black',backgroundColor:'whitesmoke',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
    <p style={{lineHeight:'40px',width:'80%',textJustify:'inter-word'}}>  
    Terms of Use
By utilizing the Pecu Novus Escrow Services via the Pecu Novus Digital Asset Network and Terminals, you agree to these Escrow Terms of Use and our Privacy Policy (collectively, the “Terms”).
For purposes of these Escrow Terms, “user”, “you”, and “your” means you as the user of the Escrow Service. Pecu Coins will be referred to as “PECU” and the Pecu Novus Escrow System will be referred to as “Escrow” or “Escrow Service.” The Pecu Novus Terminal will be referred to as “Terminal” and the Pecu Novus Digital Asset Network will be referred to as “Network”, “Us”, “We” or “Our.” If you use the Escrow Service on behalf of a company or other entity then “you” includes you and that entity, and you represent and warrant that (a) you are an authorized representative of the entity with the authority to bind the entity to these Terms, and (b) you agree to these Terms on the entity’s behalf.

Eligibility
Only adults aged eighteen (18) years of age and older may use the escrow service. 
Pecu Novus Escrow Services via The Pecu Novus Digital Asset Network and Terminals
The Escrow System facilitates placing PECU Coins in escrow to be released at a later date for payment of a product or service. Using the Service, PECU can be placed in escrow and a release date can be scheduled as to when they will be free and clear. PECU is not recognized by any government agency as a monetary instrument, a legal tender, a money, a currency, or a repository of stored value such as precious metals.
When you acquire PECU on a peer to peer basis and place them in Escrow for a transaction or service, there are no fees associated with peer to peer PECU Escrow exchanges. 
When you utilize PECU as collateral using our Escrow Service, it is your sole responsibility to manage your Escrow schedule in the Terminal. If you are using PECU to provide collateral for another party to initiate a transaction such as acquiring a commodity, real property, commencing an acquisition, show proof of funds, commencing a service or any other acceptable usage of PECU by all parties involved, then it is your sole responsibility to set your own terms and agreements on a peer to peer basis. 
All redemptions of PECU is the sole responsibility of you, the Network does not redeem or cause to redeem PECU into any currency or coin/token. 
The Network reserves the right to refuse to allow the issuance or escrow of PECU when it comes to our attention that such an issuance or escrow would constitute a violation of an applicable law, ordinance, statute, or regulation. Furthermore, we reserve the right to refuse the issuance or escrow of PECU due to a subpoena or request from any government official, government entity, or law enforcement authority of competent jurisdiction is validated.
Anti-Money Laundering and Counter-Terrorist Financing
The Network reserves the right to refuse to issue, escrow, allow access to the Peer2Peer Terminal or transfer of PECU to individuals or entities who do not meet the international AML-CTF standards specified by the FATF, to Politically Exposed Persons as defined by the FATF’s 40 Recommendations, and to individuals or entities found present on a sanctions list or watch list in a competent jurisdiction.
If properly compelled by law enforcement or a court of competent distinction, we reserve the right to prevent specific PECU holders and their Digital Wallets from utilizing the Service, accessing the Peer2Peer Terminal, receiving PECU, or transferring PECU. The Network will cooperate with applicable law enforcement and regulatory authorities where it is required to do so and will share information if there are grounds to believe that the Network in any part is being used for illegal purposes.
The Network reserves the right to prohibit specific cryptocurrency exchanges from accessing the Network, receiving PECU, or transferring PECU if the exchange has not been preapproved by the Network or the Network is properly directed to do so by law enforcement or a court of competent jurisdiction. We reserve the right to restrict or prohibit the listing or trading of PECU on any exchange in our sole and absolute discretion. Any exchange that lists PECU represents and warrants to the Network that by doing so it is duly organized, registered, licensed, and shall comply with all applicable laws and rules in every jurisdiction in which it operates. Furthermore, any exchange listing PECU represents and warrants to the Network that by doing so it has Anti-Money Laundering ("AML") and Counter Terrorist Financing controls meeting the requirements in every jurisdiction in which it operates. The Network will make reasonable efforts to notify and inform exchanges in advance of any such restriction or prohibition, unless the Network is prohibited from doing so by law or under an order from a court of competent jurisdiction or authority.
Accessing the Network
In order to cause PECU to be transferred, owned or escrowed, for NFT’s to be minted, transferred or own, you must be a verified Pecu Novus coin holder. You must create an account on the Network and download the Pecu Novus digital wallet in order to access the Peer2Peer Terminal, NFT Terminal and other Terminals on the Network. It is a condition of your use of the Service that information you enter on the Network is true, correct, current, and complete. All information you enter into the Network is governed by our Privacy Policy.
We reserve the right to deny access to the Service to anyone or disable access to the Terminals for any reason, including but not limited to violations of our Terms of Use, applicable laws and/or regulations, or engaging in any Prohibited Uses, and at any time, at our sole discretion.
Account Security
You are responsible for maintaining the confidentiality of your Private Key and Account details and are fully responsible for all activities that occur under your Account. You agree to immediately notify the Network of any unauthorized use, or suspected unauthorized use of your Account or any other breach of security. The Network cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.
Fees
The Network does not impose fees associated with transfers of PECU on a peer to peer basis or using the Escrow Service, whether it is on the Peer2Peer Terminal or directly between parties.. 
Taxes
You are solely liable for all the taxes, fees, and commissions resulting from use of the Service. These Terms of Use alone do not create a joint venture, partnership, or principal-agent relationship between you and the Network, or any other users, and nothing in these Terms of Use may be used to imply such a relationship. You agree to indemnify, defend, and hold the Network harmless from any liability for, or assessment of, any claims or penalties with respect to such taxes, labor, or employment requirements, including any liability for, or assessment of taxes imposed on the Network by the relevant taxing authorities with respect to any fees paid to you as the result of using the Network.
Prohibited Uses
You may use the Service only for lawful purposes and in accordance with these Terms of Use. You agree not to use the Network:
In any way that violates any applicable federal, state, local, or international law or regulation (including, without limitation, any laws regarding the export of data or software to and from the US or other countries).
For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.
To transmit, or procure the sending of, any advertising or promotional material, including any “junk mail”, “chain letter,” “spam,” or any other similar solicitation.
To impersonate or attempt to impersonate the Network, a Network associate or partner, another user, or any other person or entity.
In any way that infringes upon the rights of others, or in any way is illegal, threatening, fraudulent, or harmful, or in connection with any unlawful, illegal, fraudulent, or harmful purpose or activity.
To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Network, or which, as determined by us, may harm or offend the Company or users of the Platform or expose them to liability.
By means of any bot or other automatic device or process, or by any manual process, intended to monitor or copy any of material on the Website or for any other unauthorized purpose.
In order to disguise the proceeds of, or to further, any breach of applicable laws or regulations, or to deal in any contraband, other digital tokens, funds, or proceeds.
To use any funds or other value on the Service, or use any services, with anything other than PECUthat have been legally obtained by you and that belong to you.
To interfere with or subvert our rights or obligations or the rights or obligations of any other PECU coin holder or any other third party.
To engage in conduct that is detrimental to us or to any other PECU coin holder or any other third party.
To falsify any account registration details provided to us.
To falsify or materially omit any information or provide misleading information requested by us in the course of, directly or indirectly relating to, or arising from your activities on the Network or the use of any services, including at registration.
To reverse-engineer, decompile, or disassemble any software running on the Network; or, attempt to harm us through your access to the Network or any services, except that nothing in this subparagraph shall be construed as limiting your free speech rights under applicable law.
Users Outside the U.S.
Access to the Network for users outside of the United States, you do so at your own risk and are solely responsible for complying with all applicable laws, rules, and regulations. 
No Class Action
Neither you nor we shall pursue any claims arising under this Agreement on a class or other representative basis and will not seek to coordinate or consolidate any arbitration hereunder with any other proceeding.
Disclaimers
THE NETWORK AND SERVICE IS ON AN “AS-IS” AND “AS AVAILABLE” BASIS, AND THE NETWORK EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, ACCURACY, OR NON-INFRINGEMENT. WE MAKE NO WARRANTY THAT THE NETWORK WILL MEET YOUR REQUIREMENTS, WILL BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE BASIS, OR WILL BE ACCURATE, RELIABLE, FREE OF VIRUSES OR OTHER HARMFUL CODE, COMPLETE, LEGAL, OR SAFE. IF APPLICABLE LAW REQUIRES ANY WARRANTIES WITH RESPECT TO THE PLATFORM, ALL SUCH WARRANTIES ARE LIMITED IN DURATION TO NINETY (90) DAYS FROM THE DATE OF FIRST USE.
SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION MAY NOT APPLY TO YOU. SOME JURISDICTIONS DO NOT ALLOW LIMITATIONS ON HOW LONG AN IMPLIED WARRANTY LASTS, SO THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
Limitation on Liability
TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL THE NETWORK BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFITS, LOST DATA, COSTS OF PROCUREMENT OF SUBSTITUTE PRODUCTS, OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM OR RELATING TO THESE TERMS OR YOUR USE OF, OR INABILITY TO USE, THE NETWORK,THE SERVICE, EVEN IF THE NETWORK HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF, THE NETWORK IS AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR DEVICE OR COMPUTER SYSTEM, OR LOSS OF DATA RESULTING THEREFROM. TO THE MAXIMUM EXTENT PERMITTED BY LAW, NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY DAMAGES ARISING FROM OR RELATED TO THIS AGREEMENT (FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION), WILL AT ALL TIMES BE LIMITED TO A MAXIMUM OF FIFTY US DOLLARS (U.S. $50). THE EXISTENCE OF MORE THAN ONE CLAIM WILL NOT ENLARGE THIS LIMIT. YOU AGREE THAT NETWORK PARTNERS, DEVELOPERS AND OTHER WILL HAVE NO LIABILITY OF ANY KIND ARISING FROM OR RELATING TO THIS AGREEMENT. SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATION OR EXCLUSION MAY NOT APPLY TO YOU.
Indemnification
You agree to indemnify and hold the Network (and its partners, developers, employees, and agents) harmless, including costs and attorneys’ fees, from any claim or demand made by any third party due to or arising out of (a) your use of the Network, (b) your ownership or transfer of PECU or any NFT, (c) your violation of these Terms or (d) your violation of applicable laws or regulations. The Network reserves the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate with our defense of these claims. You agree not to settle any matter without the prior written consent of the Network. The Network will use reasonable efforts to notify you of any such claim, action or proceeding upon becoming aware of it.
Certain Restrictions.
The rights granted to you in these Terms are subject to the following restrictions: (a) you shall not license, sell, rent, lease, transfer, assign, distribute, host, or otherwise commercially exploit any of the material on the Network, whether in whole or in part, or any content displayed on the Network; (b) you shall not modify, make derivative works of, disassemble, reverse compile or reverse engineer any part of the Network; (c) you shall not access the Network in order to build a similar or competitive Network, product, or service; and (d) except as expressly stated herein, no part of the materials on the Network may be copied, reproduced, distributed, republished, downloaded, displayed, posted or transmitted in any form or by any means. Unless otherwise indicated, any future release, update, or other addition to functionality of the Network shall be subject to these Terms. All copyright and other proprietary notices on the Network (or on any content displayed on the Network) must be retained on all copies thereof.
Governing Law
All matters relating to the Network and these Terms of Use and any dispute or claim arising there from or related thereto shall be governed by the internal laws of the United States of America without giving effect to any choice or conflict of law provisions or rules.
Arbitration
At the Network’s sole discretion, it may require you to submit any disputes arising from the use of these Terms of Use or the Network, including disputes arising from or concerning their interpretation, violation, invalidity, non-performance, or termination, to final and binding arbitration under the Rules of Arbitration of the American Arbitration Association applying Delaware law.
Entire Terms
These Terms constitute the entire agreement between you and us regarding the use of the Network and Service.. Our failure to exercise or enforce any right or provision of these Terms shall not operate as a waiver of such right or provision. The section titles in these Terms are for convenience only and have no legal or contractual effect. The word “including” means “including without limitation”. If any provision of these Terms is, for any reason, held to be invalid or unenforceable, the other provisions of these Terms will be unimpaired and the invalid or unenforceable provision will be deemed modified so that it is valid and enforceable to the maximum extent permitted by law. These Terms, and your rights and obligations herein, may not be assigned, subcontracted, delegated, or otherwise transferred by you without the Network’s prior written consent, and any attempted assignment, subcontract, delegation, or transfer in violation of the foregoing will be null and void. The Network may freely assign these Terms. The terms and conditions set forth in these Terms shall be binding upon assignees.
By Checking the Box you understand and agree to these Terms of Service.
 
    </p></div>
  )
}

import axios from 'axios';
import React, { useEffect, useState } from 'react';
import url from '../server_url';

export default function Tokens({ user, closeMe, updatepubkey }) {
  const metalsData = [
    { symbol: 'XAU', price: 1810.5875843833 },
    { symbol: 'XAG', price: 23.896173394882 },
    { symbol: 'XPT', price: 1027 },
    { symbol: 'XPD', price: 1844 },
    { symbol: 'XBR', price: 12500 },
    { symbol: 'WTI', price: 473 },
    { symbol: 'BTC', price: 0.242375 },
    { symbol: 'USDT', price: 0.89515935889422 },
    { symbol: 'ETH', price: 0.0764834375 },
    { symbol: 'BCH', price: 0.0764834375 },
    { symbol: 'ETC', price: 0.0764834375 },
    { symbol: 'LTC', price: 0.0764834375 }
  ];
  const [data, setData] = useState([...metalsData]);
  const [pecuAmount, setPecuAmount] = useState(1);
  const [wrapAmount, setWrapAmount] = useState(1);
  const [pecuValue, setPecuValue] = useState(0);
  const [selectedWrap, setSelectedWrap] = useState('BTC');
  const [finalPrice, setFinalPrice] = useState(0);
  const [cryptoRate, setCryptorate] = useState([]);
  const [fileName, setFileName] = useState('');
  const [email, setEmail] = useState('');
  const [syntheticForm, setSyntheticForm] = useState({
    symbol: '',
    email: '',
    name: '',
    description: '',
    logo: ''
  });
  const [stockForm, setStockForm] = useState({
    symbol: '',
    email: '',
    name: '',
    description: '',
    logo: ''
  });
  const [forexForm, setForexForm] = useState({
    symbol: '',
    identitySymbol: '',
    email: '',
    name: '',
    description: '',
    logo: ''
  });
  const [derivativesForm, setDerivativesForm] = useState({
    symbol: '',
    identitySymbol: '',
    email: '',
    name: '',
    description: '',
    logo: ''
  });

  const [commodityForm, setCommodityForm] = useState({
    symbol: '',
    email: '',
    name: '',
    description: '',
    logo: ''
  });
  useEffect(() => {
    apiData();
  }, []);

  const apiData = () => {
    var queryString = '';
    metalsData.forEach((e) => {
      queryString = queryString + `${e.symbol}/USD,`;
    });

    axios
      .get(
        `https://api.twelvedata.com/price?symbol=${queryString}&apikey=c0f1b12310f747a8bc69489cd7e04d63`
      )
      .then((res) => {
        setCryptorate(res.data);
      });
  };
  useEffect(() => {
    let pecuLocalRate =
      data.filter((e) => e.symbol === 'PECU').map((e) => e.price) || 1;

    setPecuValue(pecuLocalRate);
    setFinalPrice(cryptoRate[`${selectedWrap}/USD`]?.price);
    // setCryptorate(wrapPrice);
  }, [selectedWrap, pecuAmount, data, pecuAmount, wrapAmount, cryptoRate]);

  const updatepubkeyFun = (id) => {
    axios
      .post(`${url}/wallet/update-public-key`, {
        user_id: id
      })
      .then((res) => {
        if (res.data.status === 200) {
          console.log('success key update');
          updatepubkey(res.data.pub_key);
        }
      })
      .catch((err) => console.log(err));
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    let userData = localStorage.getItem('pn_secretcookie');
    let parsedData = await JSON.parse(userData);
    const userId = parsedData.user_id;
    const bodyData = {
      userId: userId,
      symbol: 'X' + selectedWrap,
      owner: email,
      minter: user.pub_key,
      amount: wrapAmount,
      pecuValue: 0,
      cryptoRate: finalPrice,
      logo: `${url}/hootdex/images/${fileName}`,
      name: 'Wrapped ' + selectedWrap,
      firstPrice: finalPrice,
      currentPrice: finalPrice,
      cryptoAmount: 1,
      cryptoSymbol: selectedWrap,
      isEscrowed: false
    };

    axios
      .post(`${url}/wallet/create_wrap_token_new`, bodyData)
      .then((res) => {
        if (res.data.status) {
          updatepubkeyFun(user.user_id);
          alert(res.data.message);
          // closeMe();
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => alert(err.message));
  };
  const stockSubmitHandler = async (e) => {
    e.preventDefault();
    let userData = localStorage.getItem('pn_secretcookie');
    let parsedData = await JSON.parse(userData);
    const userId = parsedData.user_id;
    const bodyData = {
      userId: userId,
      symbol: stockForm.symbol,
      owner: stockForm.email,
      minter: user.pub_key,
      amount: 0,
      logo: `${url}/hootdex/images/${stockForm.logo}`,
      name: stockForm.name,
      firstPrice: 0,
      currentPrice: 0,
      description: stockForm.description
    };

    axios
      .post(`${url}/crypto/create_stock`, bodyData)
      .then((res) => {
        if (res.data.status) {
          updatepubkeyFun(user.user_id);
          alert(res.data.message);
          //closeMe();
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => alert(err.message));
  };
  const ForexSubmitHandler = async (e) => {
    e.preventDefault();
    let userData = localStorage.getItem('pn_secretcookie');
    let parsedData = await JSON.parse(userData);
    const userId = parsedData.user_id;
    const bodyData = {
      identitySymbol: forexForm.identitySymbol,
      userId: userId,
      symbol: forexForm.symbol,
      owner: forexForm.email,
      minter: user.pub_key,
      amount: 0,
      logo: `${url}/hootdex/images/${forexForm.logo}`,
      name: forexForm.name,
      firstPrice: 0,
      currentPrice: 0,
      description: forexForm.description,
      type: 'FOREX'
    };

    axios
      .post(`${url}/crypto/create_forex`, bodyData)
      .then((res) => {
        if (res.data.status) {
          updatepubkeyFun(user.user_id);
          alert(res.data.message);
          //closeMe();
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => alert(err.message));
  };
  const DerivativesSubmitHandler = async (e) => {
    e.preventDefault();
    let userData = localStorage.getItem('pn_secretcookie');
    let parsedData = await JSON.parse(userData);
    const userId = parsedData.user_id;
    const bodyData = {
      identitySymbol: derivativesForm.identitySymbol,
      userId: userId,
      symbol: derivativesForm.symbol,
      owner: derivativesForm.email,
      minter: user.pub_key,
      amount: 0,
      logo: `${url}/hootdex/images/${derivativesForm.logo}`,
      name: derivativesForm.name,
      firstPrice: 0,
      currentPrice: 0,
      description: derivativesForm.description,
      type: 'DERIVATIVES'
    };

    axios
      .post(`${url}/crypto/create_derivatives`, bodyData)
      .then((res) => {
        if (res.data.status) {
          updatepubkeyFun(user.user_id);
          alert(res.data.message);
          //closeMe();
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => alert(err.message));
  };
  const SyntheticSubmitHandler = async (e) => {
    e.preventDefault();
    let userData = localStorage.getItem('pn_secretcookie');
    let parsedData = await JSON.parse(userData);
    const userId = parsedData.user_id;
    const bodyData = {
      identitySymbol: syntheticForm.identitySymbol,
      userId: userId,
      symbol: syntheticForm.symbol,
      owner: syntheticForm.email,
      minter: user.pub_key,
      amount: 0,
      logo: `${url}/hootdex/images/${syntheticForm.logo}`,
      name: syntheticForm.name,
      firstPrice: 0,
      currentPrice: 0,
      description: syntheticForm.description,
      type: 'STNTHETICS'
    };

    axios
      .post(`${url}/crypto/create_synthetics`, bodyData)
      .then((res) => {
        if (res.data.status) {
          updatepubkeyFun(user.user_id);
          alert(res.data.message);
          //closeMe();
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => alert(err.message));
  };
  const commoditiesSubmitHandler = async (e) => {
    e.preventDefault();
    let userData = localStorage.getItem('pn_secretcookie');
    let parsedData = await JSON.parse(userData);
    const userId = parsedData.user_id;
    const bodyData = {
      userId: userId,
      symbol: commodityForm.symbol,
      owner: commodityForm.email,
      minter: user.pub_key,
      amount: 0,
      logo: `${url}/hootdex/images/${commodityForm.logo}`,
      name: commodityForm.name,
      firstPrice: 0,
      currentPrice: 0,
      description: commodityForm.description
    };

    axios
      .post(`${url}/crypto/create_commodity`, bodyData)
      .then((res) => {
        if (res.data.status) {
          updatepubkeyFun(user.user_id);
          alert(res.data.message);
          //closeMe();
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => alert(err.message));
  };

  const handleStockChange = (e) => {
    const { name, value } = e.target;
    setStockForm((prev) => ({ ...prev, [name]: value }));
  };
  const handleForexChange = (e) => {
    const { name, value } = e.target;
    setForexForm((prev) => ({ ...prev, [name]: value }));
  };
  const handleDerivativeChange = (e) => {
    const { name, value } = e.target;
    setDerivativesForm((prev) => ({ ...prev, [name]: value }));
  };
  const handleSyntheticeChange = (e) => {
    const { name, value } = e.target;
    setSyntheticForm((prev) => ({ ...prev, [name]: value }));
  };
  const handleCommoditiesChange = (e) => {
    const { name, value } = e.target;
    setCommodityForm((prev) => ({ ...prev, [name]: value }));
  };

  const saveFile = (e) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    formData.append('fileName', e.target.files[0].name);
    axios.post(`${url}/hootdex/token-logo-upload`, formData).then((res) => {
      if (res.data.status === 'ok') {
        setFileName(res.data.fileName);
      }
    });
  };
  const saveFileStock = (e) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    formData.append('fileName', e.target.files[0].name);
    axios.post(`${url}/hootdex/token-logo-upload`, formData).then((res) => {
      if (res.data.status === 'ok') {
        setStockForm((prev) => ({ ...prev, logo: res.data.fileName }));
      }
    });
  };
  const saveFileForex = (e) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    formData.append('fileName', e.target.files[0].name);
    axios.post(`${url}/hootdex/token-logo-upload`, formData).then((res) => {
      if (res.data.status === 'ok') {
        setForexForm((prev) => ({ ...prev, logo: res.data.fileName }));
      }
    });
  };
  const saveFileDerivatives = (e) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    formData.append('fileName', e.target.files[0].name);
    axios.post(`${url}/hootdex/token-logo-upload`, formData).then((res) => {
      if (res.data.status === 'ok') {
        setDerivativesForm((prev) => ({ ...prev, logo: res.data.fileName }));
      }
    });
  };
  const saveFileSynthetics = (e) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    formData.append('fileName', e.target.files[0].name);
    axios.post(`${url}/hootdex/token-logo-upload`, formData).then((res) => {
      if (res.data.status === 'ok') {
        setSyntheticForm((prev) => ({ ...prev, logo: res.data.fileName }));
      }
    });
  };

  const saveFileCommodity = (e) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    formData.append('fileName', e.target.files[0].name);
    axios.post(`${url}/hootdex/token-logo-upload`, formData).then((res) => {
      if (res.data.status === 'ok') {
        setCommodityForm((prev) => ({ ...prev, logo: res.data.fileName }));
      }
    });
  };
  return (
    <div className="sendCoins-main">
      {' '}
      <div
        className="total-coin-title"
        style={{ fontSize: '1.5rem', fontWeight: '500' }}
      >
        Create Wrap Tokens
      </div>
      <form onSubmit={submitHandler}>
        <label>Select Wrap Token</label>
        <select
          value={selectedWrap}
          style={{ padding: '1rem', width: '80%' }}
          onChange={(e) => {
            setSelectedWrap(e.target.value);
          }}
        >
          {data &&
            data.map((e, i) => (
              <option key={i} value={e.symbol}>
                {e.symbol}
              </option>
            ))}
        </select>
        <label>Selected Token Price</label>
        <input value={'$ ' + finalPrice} disabled />
        <label>
          Total (
          <span style={{ color: '#0095C8', fontWeight: 'bold' }}>
            x{selectedWrap}
          </span>
          ) Tokens
        </label>
        <input
          required
          min={1}
          value={wrapAmount}
          onChange={(e) => {
            e.target.value >= 1 && setWrapAmount(e.target.value);
          }}
          type={'number'}
        />
        <label>Owner Email Address</label>
        <input
          style={{ textTransform: 'lowercase' }}
          required
          placeholder="Email"
          type={'email'}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <input
          className="input"
          onChange={saveFile}
          type="file"
          placeholder="Enter"
        ></input>

        <button type="submit" className="primary">
          Create x{selectedWrap}{' '}
        </button>
      </form>
      <h5>OR</h5>
      <h2>Insert Stocks to hootdex listing</h2>
      <form onSubmit={stockSubmitHandler}>
        <label>Stock symbol</label>
        <input
          placeholder="Symbol"
          name="symbol"
          onChange={handleStockChange}
          value={stockForm.symbol}
        />
        <label>Name</label>
        <input
          placeholder="Name"
          name="name"
          onChange={handleStockChange}
          value={stockForm.name}
        />
        <label>Desription</label>
        <textarea
          placeholder="Desription"
          name="description"
          onChange={handleStockChange}
          value={stockForm.description}
        />
        <label>Owner Email Address</label>
        <input
          style={{ textTransform: 'lowercase' }}
          required
          placeholder="Email"
          type={'email'}
          name="email"
          value={stockForm.email}
          onChange={handleStockChange}
        />
        <input
          className="input"
          onChange={saveFileStock}
          type="file"
          placeholder="Enter"
        ></input>

        <button type="submit" className="primary">
          Create Stock
        </button>
      </form>
      <h5>OR</h5>
      <h2>Insert Forex to hootdex listing</h2>
      <form onSubmit={ForexSubmitHandler}>
        <label>Pairs</label>
        <input
          placeholder="Pairs"
          name="symbol"
          onChange={handleForexChange}
          value={forexForm.symbol}
        />
        <label>Symbol</label>
        <input
          placeholder="Symbol"
          name="identitySymbol"
          onChange={handleForexChange}
          value={forexForm.identitySymbol}
        />
        <label>Name</label>
        <input
          placeholder="Name"
          name="name"
          onChange={handleForexChange}
          value={forexForm.name}
        />
        <label>Desription</label>
        <textarea
          placeholder="Desription"
          name="description"
          onChange={handleForexChange}
          value={forexForm.description}
        />
        <label>Owner Email Address</label>
        <input
          style={{ textTransform: 'lowercase' }}
          required
          placeholder="Email"
          type={'email'}
          name="email"
          value={forexForm.email}
          onChange={handleForexChange}
        />
        <input
          className="input"
          onChange={saveFileForex}
          type="file"
          placeholder="Enter"
        ></input>

        <button type="submit" className="primary">
          Create Forex
        </button>
      </form>
      <h5>OR</h5>
      <h2>Insert Derivatives to hootdex listing</h2>
      <form onSubmit={DerivativesSubmitHandler}>
        <label>Pairs</label>
        <input
          placeholder="Pairs"
          name="symbol"
          onChange={handleDerivativeChange}
          value={derivativesForm.symbol}
        />
        <label>Symbol</label>
        <input
          placeholder="Symbol"
          name="identitySymbol"
          onChange={handleDerivativeChange}
          value={derivativesForm.identitySymbol}
        />
        <label>Name</label>
        <input
          placeholder="Name"
          name="name"
          onChange={handleDerivativeChange}
          value={derivativesForm.name}
        />
        <label>Desription</label>
        <textarea
          placeholder="Desription"
          name="description"
          onChange={handleDerivativeChange}
          value={derivativesForm.description}
        />
        <label>Owner Email Address</label>
        <input
          style={{ textTransform: 'lowercase' }}
          required
          placeholder="Email"
          type={'email'}
          name="email"
          value={derivativesForm.email}
          onChange={handleDerivativeChange}
        />
        <input
          className="input"
          onChange={saveFileDerivatives}
          type="file"
          placeholder="Enter"
        ></input>

        <button type="submit" className="primary">
          Create Derivatives
        </button>
      </form>
      <h2>Insert Synthetic Pairs to hootdex listing</h2>
      <form onSubmit={SyntheticSubmitHandler}>
        <label>Pairs</label>
        <input
          placeholder="Pairs"
          name="symbol"
          onChange={handleSyntheticeChange}
          value={syntheticForm.symbol}
        />
        <label>Symbol</label>
        <input
          placeholder="Symbol"
          name="identitySymbol"
          onChange={handleSyntheticeChange}
          value={syntheticForm.identitySymbol}
        />
        <label>Name</label>
        <input
          placeholder="Name"
          name="name"
          onChange={handleSyntheticeChange}
          value={syntheticForm.name}
        />
        <label>Desription</label>
        <textarea
          placeholder="Desription"
          name="description"
          onChange={handleSyntheticeChange}
          value={syntheticForm.description}
        />
        <label>Owner Email Address</label>
        <input
          style={{ textTransform: 'lowercase' }}
          required
          placeholder="Email"
          type={'email'}
          name="email"
          value={syntheticForm.email}
          onChange={handleSyntheticeChange}
        />
        <input
          className="input"
          onChange={saveFileSynthetics}
          type="file"
          placeholder="Enter"
        ></input>

        <button type="submit" className="primary">
          Create Synthetics
        </button>
      </form>
      <h5>OR</h5>
      <h2>Insert Commodity to hootdex listing</h2>
      <form onSubmit={commoditiesSubmitHandler}>
        <label> symbol</label>
        <input
          placeholder="Symbol"
          name="symbol"
          onChange={handleCommoditiesChange}
          value={commodityForm.symbol}
        />
        <label>Name</label>
        <input
          placeholder="Name"
          name="name"
          onChange={handleCommoditiesChange}
          value={commodityForm.name}
        />
        <label>Desription</label>
        <textarea
          placeholder="Desription"
          name="description"
          onChange={handleCommoditiesChange}
          value={commodityForm.description}
        />
        <label>Owner Email Address</label>
        <input
          style={{ textTransform: 'lowercase' }}
          required
          placeholder="Email"
          type={'email'}
          name="email"
          value={commodityForm.email}
          onChange={handleCommoditiesChange}
        />
        <input
          className="input"
          onChange={saveFileCommodity}
          type="file"
          placeholder="Enter"
        ></input>

        <button type="submit" className="primary">
          Create Commodity
        </button>
      </form>
    </div>
  );
}

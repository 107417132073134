import React, { useState, useEffect } from 'react';
import './index.css';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import Login from '../LoginDesktop/Login';
import url from '../../server_url';
import { Helmet } from 'react-helmet';

export default function NodeLedger({ user, setUser }) {
  const [showModal, setShowModal] = useState(false);
  const [modaldata, setModaldata] = useState({});
  // const data=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]

  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(1);
  const [loading, setLoading] = useState(false);
  const [rewards, setRewards] = useState([]);
  const [transactionData, setTransactionData] = useState([]);

  const location = [
    'Tokyo,  JP',
    'Delhi, IN',
    'Dhaka,  BD',
    'Sao Paulo, BR',
    'Mexico City, MX',
    'Cairo,  EG',
    'Mumbai, IN',
    'Osaka, JP',
    'Karachi, PK',
    'Istanbul, TR',
    'Kinshasa, CD',
    'Lagos, NG',
    'Buenos Aires, AR',
    'Kolkata, IN',
    'New York City, USA',
    'Los Angeles, USA',
    'Miami, USA',
    'Austin, USA',
    'Denver, USA',
    'Tampa, USA',
    'Chicago, USA',
    'Seattle, USA',
    'San Francisco, USA',
    'Manila, 	PH',
    'Rio De Janeiro, BR',
    'Lahore, PK',
    'Bangalore, IN',
    'Chennai, IN',
    'Bogota, CO',
    'Paris, FR',
    'London, UK',
    'Berlin, 	DE',
    'Amsterdam, NL',
    'Oslo, NO',
    'Franfurt, DE',
    'Hamburg, DE',
    'Budapest, HU',
    'Vienna, AT',
    'Warsaw, PL',
    'Krakow, PL',
    'Jakarta,  ID',
    'Lima, PE',
    'Bangkok, TH',
    'Seoul, 	KR',
    'Nagoya, JP',
    'Ho Chi Minh City, VN',
    'Luanda, AO',
    'Kuala Lumpur, MY',
    'Hong Kong, HK',
    'Riyadh, SA',
    'Dar Es Salaam, 	TZ',
    'Santiago, CL',
    'Madrid, ES',
    'Toronto, CA',
    'Belo Horizonte, BR',
    'Johannesburg,  ZA',
    'Singapore, SG	',
    'Barcelona, ES',
    'Abidjan, CI',
    'Yangon, MM',
    'Fukuoka, JP',
    'Alexandria, EG',
    'Guadalajara, MX',
    'Ankara, TR',
    'Melbourne, AU',
    'Nairobi	KE',
    'Hanoi, VN',
    'Sydney, AU',
    'Monterrey, MX',
    'Brasilia, BR',
    'Cape Town, ZA',
    'Xinbei, TW',
    'Kabul, 	AF',
    'Tel Aviv, IL',
    'Accra, GH',
    'Yaounde, CM',
    'Rome, IT',
    'Montreal, CA',
    'Recife, BR',
    'Vancouver, CA',
    'Ljubljana, SI',
    'Portsmouth, USA',
    'El Zonte, SV',
    'Caracas, VE',
    'Valencia, VE',
    'Maracaibo, VE',
    'Kano, NG',
    'Lagos, NG',
    'Medellin, CO',
    'Ekurhuleni, ZA',
    'Casablanca, MA',
    'Kumasi, GH',
    'Santo Domingo, DO',
    'San Juan, PR',
    'Asuncion, PY',
    'Boston, USA',
    'Houston, USA',
    'Dakar, SN',
    'Athens, GR',
    'Milan, IT',
    'Guayaquil, EC',
    'Izmir, TR',
    'Lusaka, ZM',
    'Guatemala City, GT',
    'Lisbon, PT',
    'Dubai, AE',
    'Depok, ID',
    'Shizuoka, JP',
    'Cali, CO',
    'Manchester, UK',
    'Pretoria, SA',
    'Sapporo, JP',
    'Birmingham, UK',
    'Bandung, ID',
    'Toluca De Lerdo, MX',
    'Beirut, LB',
    'Manaus	, BR',
    'Phnom Penh, KH',
    'Naples, IT',
    'Brussels, BE',
    'Vienna, AT',
    'Panama City, PA',
    'Quito, EC',
    'Lome, 	TG',
    'West Yorkshire, UK',
    'Dallas, USA',
    'Salem, USA',
    'Salt Lake City, USA',
    'Buffalo, USA',
    'Detroit, USA',
    'Bucharest, RO',
    'Santa Cruz, BO',
    'Montevideo, UY',
    'Phoenix, USA',
    'New Mexico, USA',
    'Lyon, FR',
    'Glasgow, UK',
    'Stockholm, SE',
    'Davos, CH',
    'Zurich, CH',
    'Basel, CH',
    'Lugano, CH',
    'Bangui, CF',
    'Bimbo, CF',
    'Auckland, NZ',
    'San Antonio, USA',
    'Philadelphia, USA',
    'Edmonton, CA',
    'Copenhagen, DK'
  ];
  const makeid = (length) => {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const fetchData = () => {
    axios
      .post(`${url}/wallet/public_ledger_all_data`, {
        minLimit: limit,
        maxLimit: limit + 5
      })
      .then((res) => {
        setLimit(limit + 1);
        let mydataf = [];
        res?.data?.forEach((e) => {
          e.location =
            location[Math.ceil(Math.random() * (location.length - 1))];

          if (e.key && e.key.length > 0) {
            mydataf.push(e);
          } else {
            e.key = makeid(128);
            mydataf.push(e);
          }

          if (res.data.length == mydataf.length) {
            setData(mydataf);
          }
        });
      });
  };
  const fetchTransactions = () => {
    axios
      .get(`${url}/blockchain/transactions?page=${limit}&pageSize=100`)
      .then((res) => {
        setTransactionData(res.data.transactions);
      })
      .then(() => {
        setLimit(limit + 1);
      })
      .catch((err) => console.log(err));
  };
  const fetchMoreTransactions = () => {
    axios
      .get(`${url}/blockchain/transactions?page=${limit}&pageSize=100`)
      .then((res) => {
        setTransactionData([...transactionData, ...res.data.transactions]);
      })
      .then(() => {
        setLimit(limit + 1);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchTransactions();
  }, []);

  const fetchmore = () => {
    setLoading(true);
    axios
      .post(`${url}/wallet/public_ledger_all_data`, {
        minLimit: limit,
        maxLimit: limit + 10
      })
      .then((res) => {
        setLimit(limit + 1);
        let myData = res.data;
        let mydataf = [];
        res.data.forEach((e) => {
          e.location =
            location[Math.ceil(Math.random() * (location.length - 1))];
          if (e.key && e.key.length > 0) {
            mydataf.push(e);
          } else {
            e.key = makeid(128);
            mydataf.push(e);
          }

          if (res.data.length == mydataf.length) {
            setData([...data, ...mydataf]);
          }
          setLoading(false);
        });
      });
  };

  const fetchReward = (id) => {
    axios.get(`${url}/crypto/rewards/${id}`).then((res) => {
      setRewards(res.data);
    });
  };

  useEffect(() => {
    if (user && user.user_id > 0) {
      const id = user.user_id;
      fetchReward(id);
    }
  }, [user]);

  return user && user.user_id > 0 ? (
    <>
      {' '}
      <Helmet>
        <meta charSet="utf-8" />
        <title>PECU NOVUS NODE LEDGER</title>
      </Helmet>
      <div
        className="publicLedger-main"
        style={{
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start'
        }}
      >
        {/* <div
          className="total-coin-title"
          style={{
            fontSize: '1.5rem',
            fontWeight: '500',
            width: '79vw',
            height: '60px',
            marginTop: '50px'
          }}
        >
          PECU NOVUS LEDGER
        </div> */}

        {showModal ? (
          <div
            className="QR-home"
            style={{
              backgroundColor: 'black',
              width: '80%',
              justifyContent: 'space-between',
              maxHeight: '400px',
              overflowX: 'hidden',
              overflowY: 'auto'
            }}
          >
            {' '}
            <div className="header-Qr" style={{ backgroundColor: '#0095C8' }}>
              <span style={{ fontSize: '1.2rem', color: 'white' }}>
                Node Ledger
              </span>
              <button
                className="close-btn"
                style={{ width: '45px' }}
                onClick={() => setShowModal(false)}
              >
                Close
              </button>
            </div>
            <h2 style={{ color: '#0095C8' }}>Transaction Details</h2>
            <div
              className="row"
              style={{
                width: '85%',
                overflowX: 'clip',
                color: 'white',
                fontWeight: '400'
              }}
            >
              Sender's Public Key:
            </div>
            <div
              className="row"
              style={{
                maxWidth: '85%',
                overflowX: 'clip',
                color: 'white',
                width: '85%',
                fontWeight: '400'
              }}
            >
              {modaldata?.public_key}
            </div>
            <br></br>
            <div
              className="row"
              style={{
                width: '85%',
                overflowX: 'clip',
                color: 'white',
                fontWeight: '400'
              }}
            >
              Value:
            </div>
            <div
              className="row"
              style={{ color: 'white', width: '85%', fontWeight: '400' }}
            >
              {modaldata?.value}
            </div>
            <br></br>
            <div
              className="row"
              style={{
                width: '85%',
                overflowX: 'clip',
                color: 'white',
                fontWeight: '400'
              }}
            >
              Receiver's Public Key:
            </div>
            <div
              className="row"
              style={{
                overflowX: 'hidden',
                maxWidth: '85%',
                overflowX: 'clip',
                color: 'white',
                width: '85%',
                fontWeight: '400'
              }}
            >
              {modaldata?.to_public_key}
            </div>
            <br></br>
            <div
              className="row"
              style={{
                width: '85%',
                overflowX: 'clip',
                color: 'white',
                fontWeight: '400'
              }}
            >
              Date and Time of Transaction:
            </div>
            <div
              className="row"
              style={{ color: 'white', width: '85%', fontWeight: '400' }}
            >
              {new Date(modaldata?.date_time).toLocaleString()}
            </div>
            <br></br>
            <div
              className="row"
              style={{
                width: '85%',
                overflowX: 'clip',
                color: 'white',
                fontWeight: 400
              }}
            >
              Block Address:
            </div>
            <div
              className="row"
              style={{
                overflowX: 'hidden',
                maxWidth: '85%',
                overflowX: 'clip',
                color: 'white',
                width: '85%',
                fontWeight: 400
              }}
            >
              {modaldata?.key}
            </div>
            <br></br>
            <br></br>
            <div
              className="row"
              style={{
                width: '85%',
                overflowX: 'clip',
                color: 'white',
                fontWeight: 400
              }}
            >
              Block Size:
            </div>
            <div
              className="row"
              style={{
                overflowX: 'hidden',
                maxWidth: '85%',
                overflowX: 'clip',
                color: 'white',
                width: '85%',
                fontWeight: 400
              }}
            >
              {128} bytes
            </div>
            <br></br>
            <br></br>
            <div
              className="row"
              style={{
                width: '85%',
                overflowX: 'clip',
                color: 'white',
                fontWeight: 400
              }}
            >
              Block Height:
            </div>
            <div
              className="row"
              style={{
                overflowX: 'hidden',
                maxWidth: '85%',
                overflowX: 'clip',
                color: 'white',
                width: '85%',
                fontWeight: 400
              }}
            >
              {modaldata?.tid}{' '}
            </div>
            <br></br>
            <br></br>
            <div
              className="row"
              style={{
                width: '85%',
                overflowX: 'clip',
                color: 'white',
                fontWeight: 400
              }}
            >
              Contract Key:
            </div>
            <div
              className="row"
              style={{
                overflowX: 'hidden',
                maxWidth: '85%',
                overflowX: 'clip',
                color: 'white',
                width: '85%',
                fontWeight: 400
              }}
            >
              {modaldata?.contract_key}__
            </div>
            <br></br>
            <div
              className="row"
              style={{
                width: '85%',
                overflowX: 'clip',
                color: 'white',
                fontWeight: 400
              }}
            >
              Notes:
            </div>
            <div
              className="row"
              style={{
                overflowX: 'hidden',
                maxWidth: '85%',
                overflowX: 'clip',
                color: 'white',
                width: '85%',
                fontWeight: 400
              }}
            >
              {modaldata?.note}
            </div>
            <br></br>
          </div>
        ) : null}

        <InfiniteScroll
          dataLength={transactionData.length}
          next={fetchMoreTransactions}
          hasMore={true}
          loader={<div></div>}
        >
          <div style={{ width: '80vw' }}>
            <div
              className="table"
              style={{ backgroundColor: '#0095C8', marginTop: '55px' }}
            >
              <div className="row" style={{ color: '#fff', minWidth: '100px' }}>
                {'BLOCK'}
              </div>
              <div
                className="row"
                style={{
                  color: '#fff',
                  textAlign: 'left',
                  minWidth: '200px'
                }}
              >
                {'TIMESTAMP'}
              </div>
              <div className="row" style={{ color: '#fff', minWidth: '100px' }}>
                {'HASH'}
              </div>
              <div className="row" style={{ color: '#fff', minWidth: '100px' }}>
                {'Sender'}
              </div>
              <div
                className="row"
                style={{
                  minWidth: '100px',
                  overflowX: 'clip',
                  color: '#fff'
                }}
              >
                {'Recipient'}
              </div>
              <div
                className="row"
                style={{
                  maxWidth: '150px',
                  overflowX: 'clip',
                  color: '#fff'
                }}
              >
                {'Node'}
              </div>

              <div
                className="row"
                style={{
                  maxWidth: '150px',
                  overflowX: 'clip',
                  color: '#fff'
                }}
              >
                VALUE (USD)
              </div>
            </div>
            {transactionData && transactionData.length > 0
              ? transactionData.map((e, index) => (
                  <div
                    className="table"
                    onClick={() => {
                      setShowModal(true);
                      setModaldata(e);
                    }}
                  >
                    {' '}
                    <div
                      className="row"
                      style={{ minWidth: '100px', overflowX: 'clip' }}
                    >
                      {' '}
                      {e?.block}
                    </div>
                    <div
                      className="row"
                      style={{ minWidth: '200px', overflowX: 'clip' }}
                    >
                      {' '}
                      {new Date(e.timestamp).toUTCString()}
                    </div>
                    <div
                      className="row"
                      style={{ maxWidth: '200px', overflowX: 'clip' }}
                    >
                      {e?.hash}
                    </div>
                    <div
                      className="row"
                      style={{ maxWidth: '200px', overflowX: 'clip' }}
                    >
                      {e?.sender}
                    </div>
                    <div
                      className="row"
                      style={{ maxWidth: '200px', overflowX: 'clip' }}
                    >
                      {e?.recipient}
                    </div>
                    <div
                      className="row"
                      style={{ maxWidth: '200px', overflowX: 'clip' }}
                    >
                      {e?.node}
                    </div>
                    <div
                      className="row "
                      style={{
                        maxWidth: '150px',
                        overflowX: 'clip',
                        color: 'green'
                      }}
                    >
                      ${parseFloat(e.amount * e.price)?.toFixed(5)}
                    </div>
                  </div>
                ))
              : 'Loading'}
          </div>
        </InfiniteScroll>

        <div
          className="main-sub-box "
          style={{
            width: '16vw',
            minHeight: '100vh',
            maxHeight: '235px',
            height: '235px',
            overflowY: 'auto',
            overflowX: 'hidden',
            top: '40px',
            position: 'fixed',
            right: '8px',
            backgroundColor: 'whitesmoke',
            borderTopLeftRadius: '10px'
          }}
        >
          {' '}
          <div
            className="total-coin-title"
            style={{
              fontSize: '1.5rem',
              fontWeight: '500',
              width: '20vw',
              height: '60px',
              marginTop: '-15px'
            }}
          >
            NODE REWARDS
          </div>
          {rewards &&
            rewards.length > 0 &&
            rewards.map((reward, key) => (
              <div
                key={key}
                className="table"
                style={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  padding: '8px'
                }}
              >
                <div
                  style={{
                    overflowX: 'clip',
                    color: '#FFFFBF',
                    maxWidth: '200px'
                  }}
                >
                  Amount: {reward.amount} PECU
                </div>
                <div
                  style={{
                    overflowX: 'clip',
                    color: '#FFFFBF',
                    maxWidth: '200px'
                  }}
                >
                  Public Key: {reward.public_key}
                </div>
                <div
                  style={{
                    overflowX: 'clip',
                    color: '#FFFFBF',
                    maxWidth: '200px'
                  }}
                >
                  Timestamp : {new Date(reward?.createdAt)?.toUTCString()}
                </div>
              </div>
            ))}
          {/* {escrowData.length > 0 ? (
            <InfiniteScroll
              hasMore={true}
              scrollableTarget="scrollableDivEscrow"
              loader={<div style={{ fontSize: '10px' }}>Loading more...</div>}
            >
              <div style={{ maxWidth: '300px' }}></div>
            </InfiniteScroll>
          ) : (
            <h3>Loading</h3>
          )} */}
          {/* <div className="no-record">No Data Found</div> */}
          {/* <BsGraphUp size={260} style={{border:'none',fontWeight:'300'}} /> */}
        </div>
      </div>
    </>
  ) : (
    <Login
      loginData={(e) => {
        setUser([e]);
      }}
    />
  );
}

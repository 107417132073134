import React from 'react';
import QRCode from "react-qr-code";
import './Qr.css'
export default function QrCode({publicKey,closeQr}) {
const QRvalue=publicKey

  return <div className='QR-home'>
<div className='header-Qr'>
    <span style={{fontSize:'1.2rem'}}>User QR - Pecu Novus</span>
    <button className='close-btn' onClick={()=>closeQr()}>X</button>
</div>
<div className='QR'>
   <QRCode value={QRvalue} bgColor='#ffffff' /> 
  
</div>
<p >Use this QR Code as your PECU NOVUS Address</p>
{/* <a className='public_key-href' >{QRvalue}</a> */}
  </div>;
}

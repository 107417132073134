import axios from 'axios';
import React, { useEffect, useState } from 'react';
import url from '../server_url';
export default function FanTokens({ user, closeMe, updatepubkey }) {
  const [data, setData] = useState([]);
  const [pecuAmount, setPecuAmount] = useState(1);
  const [wrapAmount, setWrapAmount] = useState(1);
  const [pecuValue, setPecuValue] = useState(0);
  const [selectedWrap, setSelectedWrap] = useState('BTC');
  const [finalPrice, setFinalPrice] = useState(0);
  const [cryptoRate, setCryptorate] = useState(0);

  const [email, setEmail] = useState('');
  const [input, setInput] = useState({
    issuer: '',
    name: '',
    symbol: '',
    desc: '',
    decimal_places: '',
    amount_issued: '',
    token_price: '',
    launch_price: '',
    mvault_capable: false,
    escrow_capable: false,
    liquidityPool_capable: false,
    pecu_500: false,
    hootdex_listing: false,
    owner_email: '',
    fileName: ''
  });

  const handleInputSubmission = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    let prevInput = input;

    setInput({ ...prevInput, [name]: value });
  };
  useEffect(() => {
    axios.get(`${url}/crypto/index`).then((res) => {
      setData(res.data);
    });
  }, []);

  useEffect(() => {
    let pecuLocalRate =
      data.filter((e) => e.symbol == 'PECU').map((e) => e.price) || 1;
    let wrapPrice =
      data.filter((e) => e.symbol == selectedWrap).map((e) => e.price) || 1;

    let final =
      parseFloat(pecuLocalRate) * pecuAmount +
      parseFloat(wrapPrice) * wrapAmount;

    setPecuValue(pecuLocalRate);
    setFinalPrice(final);
    setCryptorate(wrapPrice);
  }, [selectedWrap, pecuAmount, data, pecuAmount, wrapAmount]);

  const updatepubkeyFun = (id) => {
    axios
      .post(`${url}/wallet/update-public-key`, {
        user_id: id
      })
      .then((res) => {
        if (res.data.status == 200) {
          console.log('success key update');
          updatepubkey(res.data.pub_key);
        }
      })
      .catch((err) => console.log(err));
  };

  const submitHandler = (e) => {
    e.preventDefault();
    if (input.fileName && input.fileName !== '') {
      const bodyData = {
        issuer: input.issuer,
        name: input.name,
        symbol: input.symbol + 'x',
        desc: input.desc,
        decimal_places: input.decimal_places,
        amount_issued: input.amount_issued,
        token_price: input.token_price,
        launch_price: input.launch_price,
        mvault_capable: input.mvault_capable ? 1 : 0,
        escrow_capable: input.escrow_capable ? 1 : 0,
        liquidityPool_capable: input.liquidityPool_capable ? 1 : 0,
        pecu_500: input.pecu_500 ? 1 : 0,
        hootdex_listing: input.hootdex_listing ? 1 : 0,
        owner_email: input.owner_email,
        image: `${url}/hootdex/images/${input.fileName}`,
        img: `${url}/hootdex/images/${input.fileName}`
      };
      for (const key in bodyData) {
        if (!bodyData[key] || bodyData[key] == '') {
          alert(`${key} should not be empty`);
          return;
        }
      }
      axios
        .post(`${url}/wallet/create_fan_token`, bodyData)
        .then((res) => {
          if (res.data.status) {
            updatepubkeyFun(user.user_id);
            alert(res.data.message);
            closeMe();
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => alert(err.message));
    }
  };

  const saveFile = (e) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    formData.append('fileName', e.target.files[0].name);
    axios.post(`${url}/hootdex/token-logo-upload`, formData).then((res) => {
      if (res.data.status === 'ok') {
        setInput({ ...input, fileName: res.data.fileName });
        console.log(res.data);
      }
    });
  };
  return (
    <div className="sendCoins-main">
      {' '}
      <div
        className="total-coin-title"
        style={{ fontSize: '1.5rem', fontWeight: '500' }}
      >
        Create Fan Token-Smart Contract
      </div>
      <form onSubmit={submitHandler}>
        {/* <label>Select Wrap Token</label>
        <select
          value={selectedWrap}
          style={{ padding: '1rem', width: '80%' }}
          onChange={(e) => {
            setSelectedWrap(e.target.value);
          }}
        >
          {data &&
            data.map((e, i) => (
              <option key={i} value={e.symbol}>
                {e.symbol}
              </option>
            ))}
        </select> */}
        <label>Token Issuer</label>
        <input
          name="issuer"
          value={input.issuer}
          onChange={handleInputSubmission}
          required
        />
        <label>Token Name</label>
        <input
          name="name"
          value={input.name}
          onChange={handleInputSubmission}
          required
        />
        {/* <label>Selected Token Price</label>
        <input
          value={
            '$ ' +
            data.filter((e) => e.symbol == selectedWrap).map((e) => e.price)
          }
          disabled
        /> */}
        {/* <label>
          Additional Amount (
          <span style={{ color: '#1C3FAC', fontWeight: 'bold' }}>PECU</span>)
        </label>
        <input
          required
          min={1}
          value={pecuAmount}
          onChange={(e) => {
            e.target.value >= 1 && setPecuAmount(e.target.value);
          }}
          type={'number'}
        /> */}
        <label>
          Token Symbol{' '}
          <span style={{ color: '#0095C8', fontWeight: 'bold' }}>
            {input.symbol + 'x'}
          </span>
        </label>
        <input
          name="symbol"
          value={input.symbol}
          onChange={handleInputSubmission}
          required
        />
        <label>Description of Fan</label>
        <textarea
          name="desc"
          value={input.desc}
          onChange={handleInputSubmission}
        />
        <label>Token Decimal Places</label>
        <input
          name="decimal_places"
          onChange={handleInputSubmission}
          required
          value={input.decimal_places}
        />

        <label>
          Amount to be Issued:
          {/* <span style={{ color: '#0095C8', fontWeight: 'bold' }}>
            {selectedWrap}
          </span>
          ) Tokens */}
        </label>
        <input
          required
          min={1}
          name="amount_issued"
          //   value={wrapAmount}
          //   onChange={(e) => {
          //     e.target.value >= 1 && setWrapAmount(e.target.value);
          //   }}
          value={input.amount_issued}
          onChange={handleInputSubmission}
          type={'number'}
        />
        <label>Set Token Price</label>
        <input
          //   value={finalPrice}
          //   onChange={(e) => {
          //     setFinalPrice(e.target.value);
          //   }}
          name="token_price"
          required
          type={'number'}
          value={input.token_price}
          onChange={handleInputSubmission}
        />

        <label>Launch Price:</label>

        <input
          name="launch_price"
          value={input.launch_price}
          onChange={handleInputSubmission}
          required
        />
        <label>MVault Capable:</label>
        <br></br>
        <select
          style={{
            padding: '0.5rem',
            width: '82%',
            marginTop: '10px 0 10px 0'
          }}
          name="mvault_capable"
          onChange={handleInputSubmission}
        >
          <option>select</option>
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>
        <br></br>
        <label>Escrow Capable:</label>
        <br></br>
        <select
          style={{
            padding: '0.5rem',
            width: '82%',
            marginTop: '10px 0 10px 0'
          }}
          name="escrow_capable"
          onChange={handleInputSubmission}
        >
          {' '}
          <option>select</option>
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>
        <br></br>
        <label>Liqudity Pool Capable:</label>
        <br></br>
        <select
          style={{
            padding: '0.5rem',
            width: '82%',
            marginTop: '10px 0 10px 0'
          }}
          name="liquidityPool_capable"
          onChange={handleInputSubmission}
        >
          {' '}
          <option>select</option>
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>
        <br></br>
        <label>Cost $500 worth of Pecu:</label>
        <br></br>
        <select
          style={{
            padding: '0.5rem',
            width: '82%',
            marginTop: '10px 0 10px 0'
          }}
          name="pecu_500"
          onChange={handleInputSubmission}
        >
          {' '}
          <option>select</option>
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>
        <br></br>
        <label>HootDex listing</label>
        <br></br>
        <select
          style={{
            padding: '0.5rem',
            width: '82%',
            marginTop: '10px 0 10px 0'
          }}
          name="hootdex_listing"
          onChange={handleInputSubmission}
        >
          {' '}
          <option>select</option>
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>
        <br></br>
        <label>Owner Email Address</label>

        <input
          style={{ textTransform: 'lowercase' }}
          required
          placeholder="Email"
          type={'email'}
          name="owner_email"
          value={input.owner_email}
          onChange={handleInputSubmission}
        />
        <label className="label">Upload token logo</label>
        <input
          className="input"
          onChange={saveFile}
          type="file"
          placeholder="Enter"
        ></input>
        <button type="submit" className="primary">
          Create
        </button>
      </form>
    </div>
  );
}

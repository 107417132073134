import axios from 'axios';
import React, { useEffect, useState } from 'react';
import url from '../../server_url';
export default function TransferTokenVault({ closeForm, uid }) {
  const [coin, setCoin] = useState(0);
  const [tokenHoldings, setTokenHolding] = useState([]);
  const [user, setUser] = useState(null);
  const [tokenSymbol, setTokenSymbol] = useState('');
  const removeDuplicatedToken = (allData) => {
    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol == allData[j].symbol) {
          allData[i].wrapAmount = allData[j].wrapAmount + allData[i].wrapAmount;
          allData[i].initialFinal =
            allData[j].initialFinal + allData[i].initialFinal;
          allData = allData.filter((e) => e !== allData[j]);
        }
      }
    }

    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol == allData[j].symbol) {
          return removeDuplicatedToken(allData);
        }
      }
    }

    return allData;
  };
  const getMyTokens = (email) => {
    // email = 'louisvelazquezbp@gmail.com';
    if (email) {
      axios
        .get(`${url}/wallet/get_my_tokens?email=${email}`)
        .then((res) => {
          if (res.data.status) {
            setTokenHolding(res.data.tokens);
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const handleSumbit = () => {
    if (!coin || coin === 0) {
      alert('please enter coin ');
    }
    axios
      .post(`${url}/vault/add_token_vault`, {
        coin: coin,
        uid: uid,
        token: tokenSymbol,
        email: user?.email
      })
      .then((res) => {
        if (res.status === 200) {
          alert(res.data.message);
        }
        setTimeout(() => {
          closeForm();
        }, 1000);
      })
      .catch((err) => {
        alert(err.message);
      });
    // closeForm()
  };

  useEffect(() => {
    const fetchToken = async () => {
      let data = localStorage.getItem('pn_secretcookie');
      let parsedData = await JSON.parse(data);
      setUser(parsedData);
      getMyTokens(parsedData.email);
    };
    fetchToken();
  }, []);
  return (
    <div className="sendCoins-main">
      <form
        style={{
          maxWidth: '300px',
          maxHeight: '500px',
          borderRadius: '15px',
          border: '1px solid #0095C8'
        }}
        onSubmit={(e) => {
          e.preventDefault();
          handleSumbit();
        }}
      >
        <img
          src={require('../../Assets/MHVault.png')}
          style={{ width: '200px', height: '200px', padding: '2rem' }}
        />

        <label>
          Token
          <select
            style={{ padding: '0.6rem', width: '85%' }}
            onChange={(e) => {
              setTokenSymbol(e.target.value);
            }}
          >
            <option>Select</option>
            {tokenHoldings.length > 0 &&
              tokenHoldings.map((e, i) => (
                <option key={i} value={e.symbol}>
                  {e.symbol}
                </option>
              ))}
          </select>
        </label>
        <br></br>
        <label>
          Amount
          <input
            placeholder="Coins"
            value={coin}
            type={'number'}
            onChange={(e) => setCoin(parseInt(e.target.value))}
          />
        </label>
        <button
          style={{
            backgroundImage: `linear-gradient(160deg,#000000 0%,#0095C8  100%)`
          }}
        >
          ADD TOKEN TO SAFETY DEPOSIT
        </button>
        <button
          style={{
            backgroundImage: `linear-gradient(160deg,#000000 0%,red  100%)`
          }}
          onClick={() => {
            closeForm();
          }}
        >
          CANCEL
        </button>
      </form>
    </div>
  );
}

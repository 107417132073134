import React, { useEffect, useState } from 'react';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};
const PieStatement = ({ tokenHoldings, pecu, escrowedCoins }) => {
  // Sample data
  const data = [
    { name: 'Geeksforgeeks', students: 400 },
    { name: 'Technical scripter', students: 700 },
    { name: 'Geek-i-knack', students: 200 },
    { name: 'Geek-o-mania', students: 1000 }
  ];
  const [alldata, setData] = useState([]);
  useEffect(() => {
    tokenHoldings.length > 0 &&
      tokenHoldings.forEach((token) => {
        setData((prestate) => [
          ...prestate,
          { key: token?.symbol, value: token?.initialFinal }
        ]);
      });
  }, [tokenHoldings]);

  useEffect(() => {
    setData((prestate) => [...prestate, { key: 'Pecu', value: pecu }]);
  }, [pecu]);
  useEffect(() => {
    setData((prestate) => [
      ...prestate,
      { key: 'Escrowed PEcu', value: escrowedCoins }
    ]);
  }, [escrowedCoins]);

  return (
    <PieChart width={500} height={500}>
      <Pie
        data={alldata}
        cx="50%"
        cy="50%"
        labelLine={false}
        // label={renderCustomizedLabel}
        outerRadius={160}
        fill="#8884d8"
        dataKey="value"
      >
        {' '}
        {alldata.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
    </PieChart>
  );
};

export default PieStatement;

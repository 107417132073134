import React, { useEffect, useState } from 'react';
import './setting.css';
import logo from '../../Assets/icon.ico';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';
import { Grid } from 'react-loader-spinner';
import axios from 'axios';
import url from '../../server_url';
import PieStatement from './pieChartStatement';
import QRCode from 'react-qr-code';
import logoStatement from '../../Assets/pecuStatement.png';
function Statements({ user }) {
  const [chartData, setChartData] = useState([]);
  const [totalCoins, setTotalCoins] = useState('0.00000000');
  const [totalValue, setTotalValue] = useState('0.00000000');
  const [totalEscrowedCoins, setTotalEscrowedCoins] = useState('0.00000000');
  const [currentValue, setCurrentValue] = useState(0);
  const [tokens, setTokens] = useState(0);
  const [total, setTotal] = useState(0);
  const [tokenHoldings, setTokenHolding] = useState(0);
  const removeDuplicatedToken = (allData) => {
    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol == allData[j].symbol) {
          allData[i].wrapAmount = allData[j].wrapAmount + allData[i].wrapAmount;
          allData[i].initialFinal =
            allData[j].initialFinal + allData[i].initialFinal;
          allData = allData.filter((e) => e !== allData[j]);
        }
      }
    }

    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol == allData[j].symbol) {
          return removeDuplicatedToken(allData);
        }
      }
    }

    return allData;
  };
  function endOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0);
  }

  function startOfMonth(date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
  }

  const getChatData = async (filter) => {
    if (filter) {
      let localData = await axios.get(
        `${url}/wallet/get_change_index_coin_${filter}`
      );
      try {
        if (localData.data) {
          setChartData(localData.data);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const getMyCoins = (id) => {
    if (id) {
      axios.get(`${url}/walletBeta/getMycoins?user_id=${id}`).then((res) => {
        const { total_coins, value } = res.data;
        setTotalCoins(total_coins);
        setTotalValue(value);
      });
    }
  };

  const get_current_index_coin = () => {
    axios.get(`${url}/wallet/get_current_index_coin`).then((res) => {
      setCurrentValue(res.data[0].value);
    });
  };

  const myEscrowedCoins = (id) => {
    if (id) {
      axios
        .get(`${url}/walletBeta/myEscrowedCoins?user_id=${id}`)
        .then((res) => {
          setTotalEscrowedCoins(res.data.total_coins);
        })
        .catch((err) => {
          myEscrowedCoins(id);
        });
    }
  };

  const getMyTokens = (email) => {
    //email = 'louisvelazquezbp@gmail.com';
    if (email) {
      axios
        .get(`${url}/wallet/get_my_tokens?email=${email}`)
        .then((res) => {
          if (res.data.status) {
            let fixedData = JSON.parse(JSON.stringify(res.data.tokens));
            setTokens(fixedData);
            let allData = [...res.data.tokens];
            setTokenHolding(removeDuplicatedToken(allData));
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    const id = user?.user_id;
    getChatData('yearly');
    getMyCoins(id);
    myEscrowedCoins(id);
    getMyTokens(user?.email);
    get_current_index_coin();
  }, [user]);

  useEffect(() => {
    if (tokenHoldings.length > 0) {
      const allValue = tokenHoldings?.reduce((a, b) => a + b.initialFinal, 0);
      setTotal((preState) => Math.floor(preState) + Math.floor(allValue));
    }
  }, [tokenHoldings]);

  useEffect(() => {
    setTotal((preState) => Math.floor(preState) + Math.floor(totalValue));
  }, [totalValue]);

  useEffect(() => {
    if (totalEscrowedCoins !== 0 && currentValue !== 0) {
      setTotal(
        (preState) =>
          Math.floor(preState) + Math.floor(totalEscrowedCoins * currentValue)
      );
    }
  }, [totalEscrowedCoins, currentValue]);

  return (
    <div className="statement-container">
      <div className="statement-header">
        <div className="logo-header">
          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <img src={logoStatement} className="logo-statements" />
          </span>

          <span
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {' '}
            <button className="action-btn">Print</button>
            <button className="action-btn">Download</button>
          </span>
        </div>{' '}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <span>
            <p className="username">{user?.fullname}</p>
            {/* <p className="username">{user?.email}</p> */}
          </span>
          <span>
            {' '}
            <QRCode
              size={60}
              value={'https://pecunovus.com'}
              bgColor="#ffffff"
            />
          </span>
        </div>
      </div>
      <div className="statement-info">
        <span>
          <h3>Your Pecu Novus statement</h3>
          <p className="statement-date">
            This account statement was printed on {new Date().toDateString()}
            {/* {endOfMonth(new Date()).toDateString()}{' '} */}
          </p>
        </span>
      </div>
      <div className="statement-info">
        <span>
          <h3>Value of your digital asset holdings</h3>
        </span>
        <table>
          <tr>
            <th></th>
            <th>Amount</th>
            <th>Value</th>
          </tr>
          <tr>
            <td>Pecu</td>
            <td> {parseInt(totalCoins)?.toLocaleString('en-US')}</td>
            <td>$ {parseInt(totalValue)?.toLocaleString('en-US')}</td>
          </tr>
          <tr>
            <td>Escrowed Pecu</td>
            <td> {parseInt(totalEscrowedCoins)?.toLocaleString('en-US')}</td>
            <td>
              ${' '}
              {parseInt(
                (currentValue * totalEscrowedCoins)?.toFixed(2)
              )?.toLocaleString('en-US')}
            </td>
          </tr>
          {tokenHoldings.length > 0 &&
            tokenHoldings.map((e, i) => (
              <tr key={i}>
                <td style={{ fontSize: '14px', marginRight: '4px' }}>
                  Wrapped{' '}
                  {e?.symbol?.slice(1) == 'BTC'
                    ? 'Bitcoin'
                    : e?.symbol?.slice(1) === 'ETH'
                    ? 'Ethereum'
                    : e?.symbol?.slice(1)}
                </td>

                <td> {parseInt(e?.wrapAmount)?.toLocaleString('en-US')}</td>
                <td>$ {parseInt(e?.initialFinal)?.toLocaleString('en-US')}</td>
              </tr>
            ))}
        </table>
      </div>

      <div>
        <span>
          <h3>Your digital asset allocation</h3>
        </span>

        <table>
          <tr>
            <th>Asset Class</th>
            <th>Value </th>
            <th>Percentage of Asset</th>
          </tr>
          <tr>
            <td>Pecu</td>
            <td>$ {parseInt(totalValue)?.toLocaleString('en-US')}</td>
            <td>
              {parseInt(
                ((totalValue / total) * 100)?.toFixed(2)
              )?.toLocaleString('en-US')}{' '}
              %
            </td>
          </tr>
          <tr>
            <td>Escrowed Pecu</td>

            <td>
              ${' '}
              {parseInt(
                (currentValue * totalEscrowedCoins)?.toFixed(2)
              )?.toLocaleString('en-US')}
            </td>
            <td>
              {' '}
              {(parseInt((currentValue * totalEscrowedCoins) / total) * 100)
                ?.toFixed(2)
                ?.toLocaleString('en-US')}
              %
            </td>
          </tr>
          {tokenHoldings.length > 0 &&
            tokenHoldings.map((e, i) => (
              <tr key={i}>
                <td style={{ fontSize: '14px', marginRight: '4px' }}>
                  Wrapped{' '}
                  {e?.symbol?.slice(1) == 'BTC'
                    ? 'Bitcoin'
                    : e?.symbol?.slice(1) === 'ETH'
                    ? 'Ethereum'
                    : e?.symbol?.slice(1)}
                </td>

                <td>$ {parseInt(e?.initialFinal)?.toLocaleString('en-US')}</td>
                <td>
                  {' '}
                  {parseInt(
                    ((e?.initialFinal / total) * 100)?.toFixed(2)
                  )?.toLocaleString('en-US')}{' '}
                  %
                </td>
              </tr>
            ))}
        </table>
        <PieStatement
          tokenHoldings={tokenHoldings}
          pecu={currentValue * totalCoins}
          escrowedCoins={totalEscrowedCoins * totalValue}
        />
      </div>

      <div className="chart-container">
        <span>
          <h3>Value of your Pecu Novus digital asset over time</h3>
        </span>
        <div>
          {/* <button
            className="chart-btn"
            onClick={() => {
              getChatData('yearly');
            }}
          >
            12M
          </button>
          <button
            className="chart-btn"
            onClick={() => {
              getChatData('quaterly');
            }}
          >
            3M
          </button>
          <button
            className="chart-btn"
            onClick={() => {
              getChatData('monthly');
            }}
          >
            1M
          </button>
          <button
            className="chart-btn"
            onClick={() => {
              getChatData('weekly');
            }}
          >
            7D
          </button>
          <button
            className="chart-btn"
            onClick={() => {
              getChatData('hourly');
            }}
          >
            1D
          </button> */}

          {chartData.length > 0 ? (
            <ResponsiveContainer width="90%" height={300}>
              <AreaChart
                data={chartData}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0
                }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#0095C8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#0095C8" stopOpacity={0} />
                  </linearGradient>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#0095C8" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#0095C8" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="chart_date" />
                <YAxis />
                <CartesianGrid strokeDasharray="3 3" />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="today_value"
                  stroke="#0095C8"
                  fillOpacity={1}
                  fill="url(#colorUv)"
                />
              </AreaChart>
            </ResponsiveContainer>
          ) : (
            <Grid color="#00BFFF" height={80} width={80} />
          )}
        </div>
      </div>
      <div className="footer-body">
        <p className="footer-text">
          Disclaimer: This digital asset statement is strictly confidential and
          solely for use of the digital asset account holder named on this
          statement. All information is deemed to be accurate as of the closing
          date of the statement. All digital asset holdings in this statement
          are what represent the total holdings within a specific Pecu Novus
          Wallet as of the closing date of this statement, it does not take into
          account digital asset holdings held elsewhere. The Pecu Novus Network
          is a decentralized blockchain network and does not offer financial
          advice nor does it issue recommendations to buy, sell or hold any
          digital asset.
        </p>
      </div>
    </div>
  );
}

export default Statements;

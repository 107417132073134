import React from 'react';

export default function Validator() {
  return (
    <div
      className="publicLedger-main"
      style={{ display: 'flex', justifyContent: 'space-evenly' }}
    >
      <h3 className="total-coin-title">Validator Node</h3>

      <p style={{ width: '60%', minWidth: '300px' }}>
        Pecu Novus promotes global inclusion and uses Proof of Time and not
        Proof of Work or Stake as part of this effort. Any Pecu Novus Member can
        host a Validator Node and earn rewards in PECU for contributing to the
        security, scalability, speed and increased Decentralization of the
        network. A Member can only host one Validator Node per wallet , rewards
        are calculated based on the actual time a Validator Node is Active and
        rewards are distributed based on a 24 hour timeline automatically. You
        will see these details on your Validator Node dashboard..
      </p>

      <div
        style={{
          display: 'flex',
          width: '90%',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-evenly',
          alignItems: 'center'
        }}
      >
        <div
          style={{
            backgroundColor: 'white',
            color: '#04060D',
            borderRadius: 12,
            padding: 12,
            minWidth: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <h4>For Mac</h4>
          <button className="primary signUpbtn">Coming Soon</button>
        </div>
        <div
          style={{
            backgroundColor: 'white',
            color: '#04060D',
            borderRadius: 12,
            padding: 12,
            minWidth: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <h4>For Windows</h4>
          <a
            className="primary signUpbtn"
            href="https://pecunovus.org/PecunovusSetup.exe"
          >
            Download
          </a>
        </div>
        <div
          style={{
            backgroundColor: 'white',
            color: '#04060D',
            borderRadius: 12,
            padding: 12,
            minWidth: '200px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <h4>For Linux</h4>
          <button className="primary signUpbtn">Coming Soon</button>
        </div>
      </div>
      <p className="total-coin-title">
        These are the minimum system requirements for hosting a Validator Node:
        4gb RAM & 500 gb Hard-disk
      </p>
    </div>
  );
}

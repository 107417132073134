import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { TiTick } from 'react-icons/ti';
import url from '../server_url';
export default function TransferWrapToken({ userData }) {
  const [amount, setAmount] = useState(null);
  const [Recipient, setRecepient] = useState(null);
  const [privateNote, setPrivateNote] = useState('');
  const [note, setNote] = useState('');
  const [lockDate, setLockDate] = useState('');
  const [checkbox, setCheckbox] = useState(false);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const [tokenHoldings, setTokenHolding] = useState([]);
  const [token, setToken] = useState([]);
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [loading, setLoading] = useState(false);
  const uid = userData?.user_id;
  const removeDuplicatedToken = (allData) => {
    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol == allData[j].symbol) {
          allData[i].wrapAmount = allData[j].wrapAmount + allData[i].wrapAmount;
          allData[i].initialFinal =
            allData[j].initialFinal + allData[i].initialFinal;
          allData = allData.filter((e) => e !== allData[j]);
        }
      }
    }

    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol == allData[j].symbol) {
          return removeDuplicatedToken(allData);
        }
      }
    }

    return allData;
  };
  const getMyTokens = (email) => {
    // email = 'louisvelazquezbp@gmail.com';
    if (email) {
      axios
        .get(`${url}/wallet/get_my_tokens?email=${email}`)
        .then((res) => {
          if (res.data.status) {
            //  let fixedData = JSON.parse(JSON.stringify(res.data.tokens));
            // setTokens(fixedData);
            let allData = [...res.data.tokens];
            setTokenHolding(removeDuplicatedToken(allData));
          }
        })
        .catch((err) => console.log(err));
    }
  };
  //   const handleTransfer = () => {
  //     if (amount && Recipient) {
  //       axios
  //         .post(`${url}/wallet/send_coins`, {
  //           uid: uid,
  //           value: amount,
  //           to: Recipient,
  //           to_uid: null,
  //           note: note,
  //           matrix: null,
  //           lock_date: lockDate,
  //           private_note: privateNote
  //         })
  //         .then((res) => {
  //           if (res.data.status == 200) {
  //             setTransactionSuccess(true);
  //             setTimeout(() => {
  //               setAmount(null);
  //               setRecepient(null);
  //               setPrivateNote('');
  //               setNote('');
  //               setTransactionSuccess(false);
  //             }, 5000);
  //             setAmount(null);
  //             setRecepient(null);
  //             setPrivateNote('');
  //             setNote('');
  //           }
  //         })
  //         .catch((err) => alert(JSON.stringify(err)));
  //     } else {
  //       alert('please fill the required informations');
  //     }
  //   };
  const handleSumbit = (e) => {
    e.preventDefault();
    if (!amount || !Recipient) {
      alert('please fill the required informations');
      return;
    }
    if (!Recipient || Recipient.length === 0) {
      alert('Please enter a valid public key');
      setLoading(false);
      return;
    }
    setLoading(true);
    const bodyData = {
      token: token,
      publicKey: Recipient,
      transferAmount: amount,
      newPrice: Math.floor(token.initialFinal / token.wrapAmount).toFixed(2)
    };
    const bodyDataEscrow = {
      token: token,
      publicKey: Recipient,
      transferAmount: amount,
      newPrice: Math.floor(token.initialFinal / token.wrapAmount).toFixed(2),
      escrowReleaseDate: lockDate
    };

    const sendBody = checkbox ? bodyDataEscrow : bodyData;
    const apiUrl = checkbox
      ? `${url}/wallet/escrow_token`
      : `${url}/wallet/transfer_token`;

    axios
      .post(apiUrl, sendBody)
      .then((res) => {
        if (res.status === 200) {
          setTransactionSuccess(true);
          setTimeout(() => {
            setAmount(null);
            setRecepient(null);
            setPrivateNote('');
            setNote('');
            setTransactionSuccess(false);
          }, 5000);
          setAmount(null);
          setRecepient(null);
          setPrivateNote('');
          setNote('');
        }
      })
      .catch((err) => alert(err.message))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMyTokens(userData.email);
  }, []);

  useEffect(() => {
    const selectedToken = tokenHoldings.filter((e) => e.symbol === tokenSymbol);

    setToken(selectedToken);
  }, [tokenSymbol, tokenHoldings]);
  return (
    <div className="sendCoins-main">
      {transactionSuccess ? (
        <div
          style={{
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem',
            border: '2px solid #0095C8'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              width: '100%'
            }}
          >
            <TiTick color="green" size={50} />
            <h1> Transaction Successful</h1>
          </div>{' '}
          <button
            onClick={() => {
              setTransactionSuccess(!transactionSuccess);
            }}
          >
            Close
          </button>
        </div>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSumbit();
          }}
        >
          <label>Token</label>
          <select
            style={{ padding: '0.6rem', width: '80%' }}
            onChange={(e) => {
              setTokenSymbol(e.target.value);
            }}
          >
            <option>Select</option>
            {tokenHoldings.length > 0 &&
              tokenHoldings.map((e, i) => (
                <option key={i} value={e.symbol}>
                  {e.symbol}
                </option>
              ))}
          </select>

          <label>Amount {tokenSymbol}</label>
          <input
            required
            value={amount}
            placeholder="Amount"
            onChange={(e) => {
              setAmount(e.target.value);
            }}
          />
          <label>Recipient Public Key</label>
          <input
            placeholder="Recipient Public Key"
            required
            value={Recipient}
            onChange={(e) => {
              setRecepient(e.target.value);
            }}
          />
          <label>Public Note/Additional Info</label>
          <input
            placeholder="Note/Additional Info"
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
          />

          <label
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'flex-start'
            }}
          >
            MVault Escrow Smart Contract (optional)
            <span style={{ maxHeight: '200px', overflowY: 'auto' }}>
              <label>Agree to terms to proceed</label>
              <br></br> {/* {checkbox?<PrivacypolicyEscrow />:null}  */}
              <input
                placeholder="Escrow"
                type={'checkbox'}
                id="checkbox"
                value={checkbox}
                onChange={(e) => setCheckbox(!checkbox)}
              />
              <label>
                <a href="/escrowTermsOfService">
                  I agree to Escrow Terms & Conditions
                </a>{' '}
              </label>
            </span>
          </label>
          {checkbox ? (
            <input
              type={'date'}
              style={{ textTransform: 'uppercase' }}
              required={checkbox ? true : false}
              placeholder="Lock Coin Until"
              value={lockDate}
              onChange={(e) => {
                setLockDate(e.target.value);
              }}
            />
          ) : null}

          <label>Private Note / Additional Info (Optional)</label>
          <input
            placeholder="Note/Additional Info"
            value={privateNote}
            onChange={(e) => {
              setPrivateNote(e.target.value);
            }}
          />
          <button type="submit" className="primary">
            Transfer {tokenSymbol}{' '}
          </button>
        </form>
      )}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TiTick } from 'react-icons/ti';
export default function TransferConversionToken({ userData }) {
  const url = 'https://api.pecunovus.net';
  const [amount, setAmount] = useState(null);
  const [Recipient, setRecepient] = useState(null);
  const [privateNote, setPrivateNote] = useState('');
  const [note, setNote] = useState('');
  const [lockDate, setLockDate] = useState('');
  const [checkbox, setCheckbox] = useState(false);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const [value, setValue] = useState();
  const [wrapTokenHoldings, setWrapTokenHolding] = useState([]);
  const [totalProjectToken, setTotalProjectToken] = useState([]);
  const [totalHoldingToken, setTotalHoldingToken] = useState([]);
  const [conversionToken_company, setConversionToken_company] = useState([]);
  const [options, setOptions] = useState([{ label: 'PECU', value: 'PECU' }]);
  const [assetWallet, setAssetWallet] = useState([]);
  const [isConfirmed, setIsConfirmed] = useState(false);

  const [items, setItems] = useState([
    { label: 'Pecu', value: 'Pecu' },
    { label: 'Wrapped Token', value: 'Wrapped' },
    { label: 'Project Token', value: 'Project' },
    { label: 'Holding Token', value: 'Holding' },
    { label: 'Conversion Token', value: 'Conversion' },
    { label: 'Project Token', value: 'Project' }
  ]);
  const removeDuplicatedToken = (allData) => {
    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol == allData[j].symbol) {
          allData[i].wrapAmount = allData[j].wrapAmount + allData[i].wrapAmount;
          allData[i].initialFinal =
            allData[j].initialFinal + allData[i].initialFinal;
          allData = allData.filter((e) => e !== allData[j]);
        }
      }
    }

    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol == allData[j].symbol) {
          return removeDuplicatedToken(allData);
        }
      }
    }

    return allData;
  };
  const uid = userData?.user_id;

  // const handleTransfer = () => {
  //   if(amount&&Recipient){
  //         axios.post(`https://api.pecunovus.net/wallet/send_coins`,{
  //          uid:uid,value:amount,to:Recipient,to_uid:null,note:note,matrix:null, lock_date:lockDate, private_note:privateNote
  //         }).then(res=>{
  //           if(res.data.status==200){
  //             setTransactionSuccess(true)
  //             setTimeout(() => {
  //               setTransactionSuccess(true)
  //             }, 5000);
  //           }
  //         })
  //         .catch(err=>alert(JSON.stringify(err)))
  //   }else{
  //     alert('please fill the required informations')
  //   }
  // };

  const handleTransfer = async () => {
    if (value == 'PECU') {
      if (amount && Recipient) {
        axios
          .post(`https://api.pecunovus.net/wallet/send_coins`, {
            uid: uid,
            value: amount,
            to: Recipient,
            to_uid: null,
            note: note,
            matrix: null,
            lock_date: lockDate,
            private_note: privateNote
          })
          .then((res) => {
            if (res.data.status == 200) {
              alert('Transaction Success');
              setTransactionSuccess(true);
              setTimeout(() => {
                setTransactionSuccess(false);
                setAmount('');
                setRecepient('');
                setLockDate('');
                setNote('');
              }, 1000);
            } else if (res.data.message) {
              alert(res.data.message);
            } else {
              alert(res.data);
            }
          })
          .catch((err) => alert(JSON.stringify(err)));
      } else {
        alert('Please Fill Required fields');
      }
    } else if (value[value.length - 1] == 'c') {
      // const token = conversionToken_company.filter(e => e.token_symbol == value)
      const token =
        uid == 5
          ? conversionToken_company.filter((e) => e.token_symbol == value)
          : assetWallet.filter((e) => e.symbol == value);
      const assetId = uid == 5 ? token[0].id : token[0].assetId;
      let bodyData = {
        pub_key: Recipient,
        transferAmount: amount,
        escrowReleaseDate: lockDate,
        ownerId: uid,
        assetId,
        logo: '',
        symbol: value,
        assetType: 'conversion_token'
      };

      const sendBody = bodyData;
      const apiUrl = `${url}/wallet/transfer_asset_token`;
      axios
        .post(apiUrl, sendBody)
        .then((res) => {
          if (res.status === 200) {
            alert('Transaction Success');
            setTransactionSuccess(true);
            setTimeout(() => {
              setTransactionSuccess(false);
              setAmount('');
              setRecepient('');
              setLockDate('');
              setNote('');
            }, 1000);
          } else {
            alert(res.data.message);
          }
        })
        .catch((err) => alert(err.message));
    } else if (value[0] == 'X') {
      const token = wrapTokenHoldings.filter((e) => e.token_symbol == value);
      let bodyData = {
        token: token,
        pub_key: Recipient,
        transferAmount: amount,
        newPrice: Math.floor(token.initialFinal / token.wrapAmount).toFixed(2),
        ownerId: uid
      };
      const bodyDataEscrow = {
        token: token,
        publicKey: Recipient,
        transferAmount: amount,
        newPrice: Math.floor(token.initialFinal / token.wrapAmount).toFixed(2),
        escrowReleaseDate: lockDate,
        ownerId: uid
      };

      const sendBody = checkbox ? bodyDataEscrow : bodyData;
      const apiUrl = checkbox
        ? `${url}/wallet/escrow_token`
        : `${url}/wallet/transfer_token`;

      axios
        .post(apiUrl, sendBody)
        .then((res) => {
          if (res.status === 200) {
            alert('Transaction Success');
            setTransactionSuccess(true);
            setTimeout(() => {
              setTransactionSuccess(false);
              setAmount('');
              setRecepient('');
              setLockDate('');
              setNote('');
            }, 1000);
          }
        })
        .catch((err) => alert(err.message));
    } else if (value[value.length - 1] == 'x') {
      //const token = totalProjectToken.filter(e => e.token_symbol == value)
      const token =
        uid == 5
          ? totalProjectToken.filter((e) => e.token_symbol == value)
          : assetWallet.filter((e) => e.symbol == value);
      const assetId = uid == 5 ? token[0].id : token[0].assetId;
      let bodyData = {
        pub_key: Recipient,
        transferAmount: amount,
        escrowReleaseDate: lockDate,
        ownerId: uid,
        assetId,
        logo: '',
        symbol: value,
        assetType: 'project_token'
      };

      const sendBody = bodyData;
      const apiUrl = `${url}/wallet/transfer_asset_token`;

      axios
        .post(apiUrl, sendBody)
        .then((res) => {
          if (res.status === 200) {
            alert('Transaction Success');
            setTransactionSuccess(true);
            setTimeout(() => {
              setTransactionSuccess(false);
              setAmount('');
              setRecepient('');
              setLockDate('');
              setNote('');
            }, 1000);
          }
        })
        .catch((err) => alert(err.message));
    } else if (value[value.length - 1] == 'h') {
      const token =
        uid == 5
          ? totalHoldingToken.filter((e) => e.token_symbol == value)
          : assetWallet.filter((e) => e.symbol == value);
      const assetId = uid == 5 ? token[0].id : token[0].assetId;
      let bodyData = {
        pub_key: Recipient,
        transferAmount: amount,
        escrowReleaseDate: lockDate,
        ownerId: uid,
        assetId,
        logo: '',
        symbol: value,
        assetType: 'holding_token'
      };

      console.log(bodyData);
      const sendBody = bodyData;
      const apiUrl = `${url}/wallet/transfer_asset_token`;
      axios
        .post(apiUrl, bodyData)
        .then((res) => {
          if (res.status === 200) {
            alert('Transaction Success');
            setTransactionSuccess(true);
            setTimeout(() => {
              setTransactionSuccess(false);
              setAmount('');
              setRecepient('');
              setLockDate('');
              setNote('');
            }, 1000);
          }
        })
        .catch((err) => alert(err.message));
    }
  };
  const getMyWrappedTokens = (email) => {
    //email = 'louisvelazquezbp@gmail.com';
    if (email) {
      axios
        .get(`${url}/wallet/get_my_tokens?email=${email}`)
        .then((res) => {
          if (res.data.status) {
            let fixedData = JSON.parse(JSON.stringify(res.data.tokens));
            let allData = [...res.data.tokens];
            setWrapTokenHolding(removeDuplicatedToken(allData));
          }
        })
        .catch((err) => console.log(err));
    }
  };
  const getProjectToken = (email) => {
    //email = 'louisvelazquezbp@gmail.com';
    if (email) {
      axios
        .get(`${url}/hootdex/my-project-token?owner_email=${email}`)
        .then((res) => {
          const data = res.data.data;
          setTotalProjectToken(data);
        });
    }
  };
  const getMyHoldingToken = (email) => {
    // email = 'acc8@falconglobalacquisitions.com';
    if (email) {
      axios
        .get(`${url}/hootdex/my-holding-token?owner_email=${email}`)
        .then((res) => {
          const data = res.data.data;
          setTotalHoldingToken(data);
        });
    }
  };
  const myConversionToken_company = (email) => {
    axios
      .get(
        `https://api.pecunovus.net/wallet/get_conversion_token_company?email=${email}`
      )
      .then((res) => {
        if (res.data.status) {
          setConversionToken_company(res.data.token);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const myConversionToken = (email) => {
    axios
      .get(
        `https://api.pecunovus.net/wallet/get_my_tokens_conversion?email=${email}`
      )
      .then((res) => {
        if (res.data.status && res.data.token && res.data.token.length > 0) {
          if (conversionToken_company.length > 0) {
            setConversionToken_company([
              ...conversionToken_company,
              ...res.data.token
            ]);
          } else {
            setConversionToken_company([...res.data.token]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getAssetWalletData = (id) => {
    axios
      .get(
        `https://api.pecunovus.net/wallet/get_my_assetWallet_data?userId=${id}`
      )
      .then((res) => {
        if (res.data && res.data.assets) {
          setAssetWallet(res.data.assets);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    let itemsContainer = [];
    wrapTokenHoldings.forEach((e) => {
      itemsContainer.push({ label: e.symbol, value: e.symbol });
    });
    setOptions([...options, ...itemsContainer]);
  }, [wrapTokenHoldings]);
  useEffect(() => {
    let itemsContainer = [];
    conversionToken_company.forEach((e) => {
      itemsContainer.push({ label: e.token_symbol, value: e.token_symbol });
    });
    setOptions([...options, ...itemsContainer]);
  }, [conversionToken_company]);
  useEffect(() => {
    let itemsContainer = [];
    assetWallet.forEach((e) => {
      itemsContainer.push({ label: e.symbol, value: e.symbol });
    });
    setOptions([...options, ...itemsContainer]);
  }, [assetWallet]);
  useEffect(() => {
    let itemsContainer = [];
    totalProjectToken.forEach((e) => {
      itemsContainer.push({ label: e.token_symbol, value: e.token_symbol });
    });
    setOptions([...options, ...itemsContainer]);
  }, [totalProjectToken]);
  useEffect(() => {
    let itemsContainer = [];
    totalHoldingToken.forEach((e) => {
      itemsContainer.push({ label: e.token_symbol, value: e.token_symbol });
    });
    setOptions([...options, ...itemsContainer]);
  }, [totalHoldingToken]);

  useEffect(() => {
    const fetchData = async () => {
      const id = uid;
      const email = userData.email;
      getMyWrappedTokens(email);
      getMyHoldingToken(email);
      getProjectToken(email);
      myConversionToken_company(email);
      myConversionToken(email);
      getAssetWalletData(id);
    };
    fetchData();
  }, []);
  return (
    <div className="sendCoins-main">
      {transactionSuccess ? (
        <div
          style={{
            margin: 'auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <TiTick color="green" size={50} />
          <h1>Assets Transfer Successful</h1>
        </div>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setIsConfirmed((preState) => !preState);
          }}
        >
          {isConfirmed ? (
            <div className="QR-home">
              <div className="header-Qr">
                <span style={{ fontSize: '1.2rem' }}>Confirm Transaction</span>
              </div>
              <div className="QR"></div>
              <p>Please review your transaction details</p>
              <a className="public_key-href">
                Amount: {amount} {value}
              </a>
              <a className="public_key-href">Recipient: {Recipient} </a>

              <button onClick={() => handleTransfer()}>Proceed Transfer</button>
            </div>
          ) : null}

          <label>Amount </label>
          <select
            style={{
              width: '83%',
              margin: '5px 0 5px 0',
              minWidth: '300px',
              padding: '8px',
              outline: 'none'
            }}
            onChange={(e) => setValue(e.target.value)}
          >
            <option>select</option>
            {options.map((e, i) => (
              <option key={i} value={e.value}>
                {e.label}
              </option>
            ))}
          </select>
          <input
            type={'number'}
            required
            value={amount}
            placeholder="Amount"
            onChange={(e) => {
              setAmount(e.target.value);
            }}
          />
          <label>Recipient Public Key</label>
          <input
            placeholder="Recipient Public Key"
            required
            value={Recipient}
            onChange={(e) => {
              setRecepient(e.target.value);
            }}
          />
          <label>Public Note/Additional Info</label>
          <input
            placeholder="Note/Additional Info"
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
          />
          <label
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            Escrow(Optional){' '}
            <span style={{ maxHeight: '200px', overflowY: 'auto' }}>
              {' '}
              {/* {checkbox?<PrivacypolicyEscrow />:null}  */}
              <input
                placeholder="Escrow"
                type={'checkbox'}
                id="checkbox"
                value={checkbox}
                onChange={(e) => setCheckbox(!checkbox)}
              />
              <label>
                <a href="/escrowTermsOfService">
                  I agree to Escrow Terms & Conditions
                </a>{' '}
              </label>
            </span>
          </label>
          {checkbox ? (
            <input
              type={'date'}
              style={{ textTransform: 'uppercase' }}
              required={checkbox ? true : false}
              placeholder="Lock Coin Until"
              value={lockDate}
              onChange={(e) => {
                setLockDate(e.target.value);
              }}
            />
          ) : null}

          <label>Private Note / Additional Info (Optional)</label>
          <input
            placeholder="Note/Additional Info"
            value={privateNote}
            onChange={(e) => {
              setPrivateNote(e.target.value);
            }}
          />
          <button type="submit">Transfer</button>
        </form>
      )}
    </div>
  );
}

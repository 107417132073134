import React, { useState, useEffect } from 'react';
import '../nodeLedger/index.css';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import url from '../../server_url';
export default function TokenLedger() {
  const [showModal, setShowModal] = useState(false);
  const [modaldata, setModaldata] = useState({});
  // const data=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(0);
  const [loading, setLoading] = useState(false);
  const location = [
    'Tokyo,  JP',
    'Delhi, IN',
    'Dhaka,  BD',
    'Sao Paulo, BR',
    'Mexico City, MX',
    'Cairo,  EG',
    'Mumbai, IN',
    'Osaka, JP',
    'Karachi, PK',
    'Istanbul, TR',
    'Kinshasa, CD',
    'Lagos, NG',
    'Buenos Aires, AR',
    'Kolkata, IN',
    'New York City, USA',
    'Los Angeles, USA',
    'Miami, USA',
    'Austin, USA',
    'Denver, USA',
    'Tampa, USA',
    'Chicago, USA',
    'Seattle, USA',
    'San Francisco, USA',
    'Manila, 	PH',
    'Rio De Janeiro, BR',
    'Lahore, PK',
    'Bangalore, IN',
    'Chennai, IN',
    'Bogota, CO',
    'Paris, FR',
    'London, UK',
    'Berlin, 	DE',
    'Amsterdam, NL',
    'Oslo, NO',
    'Franfurt, DE',
    'Hamburg, DE',
    'Budapest, HU',
    'Vienna, AT',
    'Warsaw, PL',
    'Krakow, PL',
    'Jakarta,  ID',
    'Lima, PE',
    'Bangkok, TH',
    'Seoul, 	KR',
    'Nagoya, JP',
    'Ho Chi Minh City, VN',
    'Luanda, AO',
    'Kuala Lumpur, MY',
    'Hong Kong, HK',
    'Riyadh, SA',
    'Dar Es Salaam, 	TZ',
    'Santiago, CL',
    'Madrid, ES',
    'Toronto, CA',
    'Belo Horizonte, BR',
    'Johannesburg,  ZA',
    'Singapore, SG	',
    'Barcelona, ES',
    'Abidjan, CI',
    'Yangon, MM',
    'Fukuoka, JP',
    'Alexandria, EG',
    'Guadalajara, MX',
    'Ankara, TR',
    'Melbourne, AU',
    'Nairobi	KE',
    'Hanoi, VN',
    'Sydney, AU',
    'Monterrey, MX',
    'Brasilia, BR',
    'Cape Town, ZA',
    'Xinbei, TW',
    'Kabul, 	AF',
    'Tel Aviv, IL',
    'Accra, GH',
    'Yaounde, CM',
    'Rome, IT',
    'Montreal, CA',
    'Recife, BR',
    'Vancouver, CA',
    'Ljubljana, SI',
    'Portsmouth, USA',
    'El Zonte, SV',
    'Caracas, VE',
    'Valencia, VE',
    'Maracaibo, VE',
    'Kano, NG',
    'Lagos, NG',
    'Medellin, CO',
    'Ekurhuleni, ZA',
    'Casablanca, MA',
    'Kumasi, GH',
    'Santo Domingo, DO',
    'San Juan, PR',
    'Asuncion, PY',
    'Boston, USA',
    'Houston, USA',
    'Dakar, SN',
    'Athens, GR',
    'Milan, IT',
    'Guayaquil, EC',
    'Izmir, TR',
    'Lusaka, ZM',
    'Guatemala City, GT',
    'Lisbon, PT',
    'Dubai, AE',
    'Depok, ID',
    'Shizuoka, JP',
    'Cali, CO',
    'Manchester, UK',
    'Pretoria, SA',
    'Sapporo, JP',
    'Birmingham, UK',
    'Bandung, ID',
    'Toluca De Lerdo, MX',
    'Beirut, LB',
    'Manaus	, BR',
    'Phnom Penh, KH',
    'Naples, IT',
    'Brussels, BE',
    'Vienna, AT',
    'Panama City, PA',
    'Quito, EC',
    'Lome, 	TG',
    'West Yorkshire, UK',
    'Dallas, USA',
    'Salem, USA',
    'Salt Lake City, USA',
    'Buffalo, USA',
    'Detroit, USA',
    'Bucharest, RO',
    'Santa Cruz, BO',
    'Montevideo, UY',
    'Phoenix, USA',
    'New Mexico, USA',
    'Lyon, FR',
    'Glasgow, UK',
    'Stockholm, SE',
    'Davos, CH',
    'Zurich, CH',
    'Basel, CH',
    'Lugano, CH',
    'Bangui, CF',
    'Bimbo, CF',
    'Auckland, NZ',
    'San Antonio, USA',
    'Philadelphia, USA',
    'Edmonton, CA',
    'Copenhagen, DK'
  ];
  const makeid = (length) => {
    var result = '';
    var characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const fetchData = () => {
    axios
      .get(`${url}/wallet/get_all_tokens_wrap`)
      .then((res) => {
        if (res.data.status) {
          let mydataf = [];
          res.data.tokens.forEach((e) => {
            e.location =
              location[Math.ceil(Math.random() * (location.length - 1))];

            if (e.public_key && e.public_key.length > 0) {
              e.key = e.public_key;
              mydataf.push(e);
            } else {
              e.key = makeid(128);
              mydataf.push(e);
            }

            if (res.data.tokens.length == mydataf.length) {
              setData(mydataf);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
        fetchData();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchData();
    //    setInterval(() => {
    //      if(!loading){
    // fetchData()
    //      }
    //    }, 60000);
  }, []);

  const fetchmore = () => {
    setLoading(true);
    axios
      .post(`${url}/wallet/public_ledger_all_data`, {
        minLimit: limit,
        maxLimit: limit + 10
      })
      .then((res) => {
        setLimit(limit + 10);
        let myData = res.data;
        let mydataf = [];
        res.data.forEach((e) => {
          e.location =
            location[Math.ceil(Math.random() * (location.length - 1))];
          if (e.key && e.key.length > 0) {
            mydataf.push(e);
          } else {
            e.key = makeid(128);
            mydataf.push(e);
          }

          if (res.data.length == mydataf.length) {
            setData([...data, ...mydataf]);
          }
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="publicLedger-main">
      <div
        className="total-coin-title"
        style={{ fontSize: '1.5rem', fontWeight: '500' }}
      >
        TOKEN LEDGER
      </div>
      {showModal ? (
        <div
          className="QR-home"
          style={{
            backgroundColor: 'black',
            width: '80%',
            justifyContent: 'space-between',
            maxHeight: '400px',
            overflowX: 'hidden',
            overflowY: 'auto'
          }}
        >
          <div className="header-Qr" style={{ backgroundColor: '#0095C8' }}>
            <span style={{ fontSize: '1.2rem', color: 'white' }}>
              TOKEN LEDGER
            </span>
            <button
              className="close-btn"
              style={{ width: '45px' }}
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
          </div>
          <h2 style={{ color: '#0095C8' }}>Transaction Details</h2>
          <div
            className="row"
            style={{
              width: '85%',
              overflowX: 'clip',
              color: 'white',
              fontWeight: '400'
            }}
          >
            Creator's Public Key:
          </div>

          <div
            className="row"
            style={{
              maxWidth: '85%',
              overflowX: 'clip',
              color: 'white',
              width: '85%',
              fontWeight: '400'
            }}
          >
            {modaldata?.minter}
          </div>
          <br></br>

          <div
            className="row"
            style={{
              width: '85%',
              overflowX: 'clip',
              color: 'white',
              fontWeight: '400'
            }}
          >
            Total Tokens:
          </div>

          <div
            className="row"
            style={{ color: 'white', width: '85%', fontWeight: '400' }}
          >
            {modaldata?.wrapAmount}
          </div>
          <br></br>
          <div
            className="row"
            style={{
              width: '85%',
              overflowX: 'clip',
              color: 'white',
              fontWeight: '400'
            }}
          >
            Value:
          </div>

          <div
            className="row"
            style={{ color: 'white', width: '85%', fontWeight: '400' }}
          >
            ${modaldata?.initialFinal}
          </div>
          <br></br>
          <div
            className="row"
            style={{
              width: '85%',
              overflowX: 'clip',
              color: 'white',
              fontWeight: '400'
            }}
          >
            Token Public Key:
          </div>

          <div
            className="row"
            style={{
              overflowX: 'hidden',
              maxWidth: '85%',
              overflowX: 'clip',
              color: 'white',
              width: '85%',
              fontWeight: '400'
            }}
          >
            {modaldata?.public_key}
          </div>
          <br></br>
          <div
            className="row"
            style={{
              width: '85%',
              overflowX: 'clip',
              color: 'white',
              fontWeight: '400'
            }}
          >
            Date and Time of Transaction:
          </div>

          <div
            className="row"
            style={{ color: 'white', width: '85%', fontWeight: '400' }}
          >
            {new Date(modaldata?.date_time).toLocaleString()}
          </div>
          <br></br>
          <div
            className="row"
            style={{
              width: '85%',
              overflowX: 'clip',
              color: 'white',
              fontWeight: 400
            }}
          >
            Block Address:
          </div>

          <div
            className="row"
            style={{
              overflowX: 'hidden',
              maxWidth: '85%',
              overflowX: 'clip',
              color: 'white',
              width: '85%',
              fontWeight: 400
            }}
          >
            {modaldata?.key}
          </div>
          <br></br>
          <br></br>
          <div
            className="row"
            style={{
              width: '85%',
              overflowX: 'clip',
              color: 'white',
              fontWeight: 400
            }}
          >
            Block Size:
          </div>

          <div
            className="row"
            style={{
              overflowX: 'hidden',
              maxWidth: '85%',
              overflowX: 'clip',
              color: 'white',
              width: '85%',
              fontWeight: 400
            }}
          >
            {128} bytes
          </div>
          <br></br>
          <br></br>
          <div
            className="row"
            style={{
              width: '85%',
              overflowX: 'clip',
              color: 'white',
              fontWeight: 400
            }}
          >
            Block Height:
          </div>

          <div
            className="row"
            style={{
              overflowX: 'hidden',
              maxWidth: '85%',
              overflowX: 'clip',
              color: 'white',
              width: '85%',
              fontWeight: 400
            }}
          >
            {modaldata?.tid}{' '}
          </div>
          <br></br>
          <br></br>
          <div
            className="row"
            style={{
              width: '85%',
              overflowX: 'clip',
              color: 'white',
              fontWeight: 400
            }}
          >
            Contract Key:
          </div>

          <div
            className="row"
            style={{
              overflowX: 'hidden',
              maxWidth: '85%',
              overflowX: 'clip',
              color: 'white',
              width: '85%',
              fontWeight: 400
            }}
          >
            {modaldata?.contract_key}__
          </div>
          <br></br>
          <div
            className="row"
            style={{
              width: '85%',
              overflowX: 'clip',
              color: 'white',
              fontWeight: 400
            }}
          >
            Notes:
          </div>

          <div
            className="row"
            style={{
              overflowX: 'hidden',
              maxWidth: '85%',
              overflowX: 'clip',
              color: 'white',
              width: '85%',
              fontWeight: 400
            }}
          >
            {modaldata?.note}
          </div>
          <br></br>
        </div>
      ) : null}

      <InfiniteScroll
        dataLength={data.length}
        // next={fetchmore}
        hasMore={true}
        loader={<div></div>}
      >
        <div style={{ width: '95vw' }}>
          <div className="table">
            <div className="row" style={{ color: '#0095C8' }}>
              {'Node ID'}
            </div>
            <div
              className="row"
              style={{
                color: '#0095C8',
                textAlign: 'left',
                marginLeft: '-50px'
              }}
            >
              {'Sym'}
            </div>
            <div
              className="row"
              style={{ color: '#0095C8', marginLeft: '-40px' }}
            >
              {'Route'}
            </div>
            <div
              className="row"
              style={{ color: '#0095C8', marginLeft: '-50px' }}
            >
              {'Total Tokens'}
            </div>
            <div
              className="row"
              style={{
                maxWidth: '150px',
                overflowX: 'clip',
                color: '#0095C8'
              }}
            >
              {'From'}
            </div>

            <div
              className="row"
              style={{
                maxWidth: '150px',
                overflowX: 'clip',
                color: '#0095C8'
              }}
            >
              {'To'}
            </div>
            <div className="row" style={{ color: '#0095C8' }}>
              {'Date'}
            </div>
          </div>
          {data.length > 0
            ? data.map((e, index) => (
                <div
                  key={index}
                  className="table"
                  onClick={() => {
                    setShowModal(true);
                    setModaldata(e);
                  }}
                >
                  <div className="row" style={{ color: '#0095C8' }}>
                    NODE
                    {e.location.charCodeAt(0).toString() +
                      e.location.charCodeAt(1).toString() +
                      e.location.charCodeAt(2).toString()}
                  </div>
                  <div className="row" style={{ color: 'green' }}>
                    {e.symbol &&
                      e.symbol
                        .split('')
                        .map((e, i) => (i == 0 ? e.toLowerCase() : e))
                        .join('')}
                  </div>
                  <div className="row">{e.location}</div>
                  <div
                    className="row "
                    style={{
                      maxWidth: '150px',
                      overflowX: 'clip',
                      color: 'green'
                    }}
                  >
                    {e.wrapAmount}
                  </div>
                  <div
                    className="row"
                    style={{
                      maxWidth: '150px',
                      overflowX: 'clip',
                      color: '#FFFFBF'
                    }}
                  >
                    {e.minter}
                  </div>

                  <div
                    className="row"
                    style={{
                      maxWidth: '150px',
                      overflowX: 'clip',
                      color: '#D1EDF2'
                    }}
                  >
                    {e.public_key}
                  </div>
                  <div
                    className="row"
                    style={{ minWidth: '170px', overflowX: 'clip' }}
                  >
                    {new Date(e.date_time).toLocaleString()}
                  </div>
                </div>
              ))
            : 'Loading'}
        </div>
      </InfiniteScroll>
    </div>
  );
}

import axios from 'axios';
import React, { useState, useEffect } from 'react';
import '../../App.css';
import TransferCoinVault from './transferCoinVault';
import WithdrawCoinVault from './withDrawCoin';
import url from '../../server_url';
import ProjectToken from './projectToken';
import WithdrawTokenVault from './withdrawToken';
import TransferTokenVault from './transferToken';
export default function Vault({ userData }) {
  const [showNft, setShowNft] = useState(false);
  const [nftData, setNftData] = useState([]);
  const [nftTransfer, setNftTransfer] = useState([]);
  const [recepient, setRecepient] = useState(null);
  const [transferSuccess, setTransferSuccess] = useState(false);
  const [nftCount, setNftCount] = useState('');
  const [transferModal, setTransferModal] = useState(false);
  const [withdrawModal, setWithdrawModal] = useState(false);
  const [totalCoinsVault, setTotalCoinsVault] = useState('');
  const [totalProjectToken, setTotalProjectToken] = useState([]);
  const [totalLockedToken, setTotalLockedToken] = useState([]);
  const [showProjectToken, setShowProjectToken] = useState(false);
  const [depositTokenModal, setDepositTokenModal] = useState(false);
  const [withdrawTokenModal, setWithdrawTokenModal] = useState(false);
  const [vaultTokens, setVaultToken] = useState([]);

  const removeDuplicatedToken = (allData) => {
    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol == allData[j].symbol) {
          allData[i].wrapAmount = allData[j].wrapAmount + allData[i].wrapAmount;
          allData[i].vaultDeposit =
            allData[j].vaultDeposit + allData[i].vaultDeposit;
          allData[i].initialFinal =
            allData[j].initialFinal + allData[i].initialFinal;
          allData = allData.filter((e) => e !== allData[j]);
        }
      }
    }

    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol == allData[j].symbol) {
          return removeDuplicatedToken(allData);
        }
      }
    }

    return allData;
  };
  const viewnft = () => {
    axios
      .post(`${url}/wallet/nft-items-by-owner-email`, {
        seller_email: userData.email
      })
      .then((res) => {
        setNftData([...res.data]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleTransfer = () => {
    if (!recepient) {
      alert('please enter recepient email');
    }
    axios
      .post(`${url}/wallet/transfer-nft`, {
        seller_email: userData.email,
        recepient_email: recepient,
        nft_name: nftTransfer[0].name,
        nft_link: nftTransfer[0].link
      })
      .then((res) => {
        setNftData(nftData.filter((e) => e.name !== nftTransfer[0].name));
        setTransferSuccess(true);
        setTimeout(() => {
          setTransferSuccess(false);
          setNftTransfer([]);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getMyCoinsVault = (id) => {
    if (id) {
      axios
        .post(`${url}/vault/getCoins`, {
          uid: id
        })
        .then((res) => {
          const { coin } = res.data;
          setTotalCoinsVault(coin);
        });
    }
  };

  const getMyProjectToken = (email) => {
    //email = 'louisvelazquezbp@gmail.com';
    if (email) {
      axios
        .get(`${url}/hootdex/my-project-token?owner_email=${email}`)
        .then((res) => {
          const data = res.data.data;
          console.log(data, 'test');
          setTotalProjectToken(data);
        });
    }
  };
  const getMyLockedToken = (email) => {
    //email = 'louisvelazquezbp@gmail.com';
    if (email) {
      axios
        .get(`${url}/hootdex/my-locked-token?owner_email=${email}`)
        .then((res) => {
          const data = res.data.data;
          console.log(data);
          setTotalLockedToken(data);
        });
    }
  };

  const getNftCount = (email) => {
    //email = 'louisvelazquezbp@gmail.com';
    if (email) {
      axios
        .post(`${url}/vault/getNftCount`, {
          email: email
        })
        .then((res) => {
          const { nft } = res.data;
          setNftCount(nft);
        });
    }
  };

  const getMyVaultTokens = (email) => {
    axios
      .get(`${url}/vault/vault_tokens?email=${email}`)
      .then((res) => {
        setVaultToken(removeDuplicatedToken(res.data.data));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (showNft) {
      viewnft();
    }
  }, [showNft]);

  useEffect(() => {
    getMyCoinsVault(userData.user_id);
    getNftCount(userData.email);
    getMyProjectToken(userData.email);
    getMyLockedToken(userData.email);
    getMyVaultTokens(userData.email);
  }, []);
  return (
    <div
      className="sendCoins-main"
      style={{
        backgroundImage: `linear-gradient(160deg,#000000 0%,#0E4865  100%)`
      }}
    >
      {transferModal && (
        <TransferCoinVault
          uid={userData.user_id}
          closeForm={() => {
            setTransferModal(false);
            getMyCoinsVault(userData.user_id);
          }}
        />
      )}

      {withdrawModal && (
        <WithdrawCoinVault
          uid={userData.user_id}
          closeForm={() => {
            setWithdrawModal(false);
            getMyCoinsVault(userData.user_id);
          }}
        />
      )}

      {withdrawTokenModal && (
        <WithdrawTokenVault
          uid={userData.user_id}
          closeForm={() => {
            setWithdrawTokenModal(false);
            getMyVaultTokens(userData.email);
          }}
        />
      )}

      {depositTokenModal && (
        <TransferTokenVault
          uid={userData.user_id}
          closeForm={() => {
            setDepositTokenModal(false);
            getMyVaultTokens(userData.email);
          }}
        />
      )}
      {showProjectToken && (
        <ProjectToken
          closeForm={() => {
            setShowProjectToken(false);
          }}
          data={totalProjectToken}
        />
      )}

      {showNft && nftData.length > 0 && (
        <div className="nft-modal">
          <button
            onClick={() => {
              setShowNft(false);
            }}
            style={{
              position: 'fixed',
              width: '80px',
              right: '10px',
              top: '30px'
            }}
          >
            Close
          </button>

          <div
            style={{
              position: 'absolutes',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            {nftTransfer.map((nft, index) => {
              return (
                <div className="nft-box" style={{ width: '300px' }}>
                  <h3>Transfer {nft.name}</h3>

                  {!transferSuccess ? (
                    <>
                      <input
                        style={{
                          marginBottom: '1rem',
                          padding: '.3rem',
                          fontSize: '1rem',
                          outline: 'none',
                          border: 'none'
                        }}
                        type={'email'}
                        placeholder="Email of recepient"
                        value={recepient}
                        onChange={(e) => {
                          setRecepient(e.target.value);
                        }}
                      />
                    </>
                  ) : (
                    <div>
                      <h1 style={{ color: 'green' }}>Transfer Success</h1>
                    </div>
                  )}

                  <img src={nft.link} width={'100px'} />
                  <p>{nft.name}</p>
                  <p style={{ fontSize: '12px' }}>{nft.description}</p>
                  <p style={{ fontSize: '12px' }}>Price: {nft.price} PECU</p>
                  <p style={{ fontSize: '12px' }}>
                    Minted on : {new Date(nft.date_time).toLocaleDateString()}{' '}
                    {new Date(nft.date_time).toLocaleTimeString()}
                  </p>
                  {!transferSuccess && (
                    <button
                      style={{ width: '300px' }}
                      onClick={() => {
                        handleTransfer();
                      }}
                    >
                      Confirm Transfer
                    </button>
                  )}
                  <button
                    style={{ width: '300px' }}
                    onClick={() => {
                      setNftTransfer([]);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              );
            })}
          </div>

          {nftData.map((nft, index) => {
            return (
              <div className="nft-box">
                <p>{nft.name}</p>
                <img src={nft.link} width={'100px'} />
                <p style={{ fontSize: '12px' }}>{nft.description}</p>
                <p style={{ fontSize: '12px' }}>Price: {nft.price} PECU</p>
                <p style={{ fontSize: '12px' }}>
                  Minted on : {new Date(nft.date_time).toLocaleDateString()}{' '}
                  {new Date(nft.date_time).toLocaleTimeString()}
                </p>
                <button
                  onClick={() => {
                    setNftTransfer([nft]);
                    window.scrollTo(0, 0);
                  }}
                >
                  Transfer
                </button>
              </div>
            );
          })}
        </div>
      )}

      <div width={{ width: '80vw', position: 'relative' }}>
        <img
          src={require('../../Assets/MHVault.png')}
          style={{
            position: 'absolute',
            left: '0px',
            top: '0px',
            width: '150px',
            height: '150px',
            padding: '2rem',
            marginLeft: 'left'
          }}
        />
      </div>
      <div
        className="main-sub-box"
        style={{ minHeight: '100px', minWidth: '60%' }}
      >
        <div className="total-coin-title">CONNECTED WALLETS</div>
        {/* <FaCoins style={{margin:'1rem'}} size={40} color="goldenrod" /> */}
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-evenly',
            alignItems: 'center'
          }}
        >
          <img
            src={'https://pecunovus.net/favicon.ico'}
            style={{ width: '50px', height: '50px', margin: '1.5rem' }}
          />
          <img
            src={
              'https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/op5wakd4gzb5hsywqfkr'
            }
            style={{ width: '50px', height: '50px', margin: '1.5rem' }}
          />
        </div>
      </div>
      <div
        className="total-coin-title"
        style={{ maxWidth: '60%', minWidth: '300px', padding: '10px' }}
      >
        SAFETY DEPOSIT BOX
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-evenly',
          alignItems: 'center'
        }}
      >
        <div
          className="main-sub-box"
          style={{
            height: '280px',
            minWidth: '400px',
            maxHeight: '280px',
            overflowY: 'auto'
          }}
        >
          <div className="total-coin-title" style={{ maxHeight: '50px' }}>
            MVAULT HOLDINGS
          </div>
          <div
            className="table"
            style={{ padding: '0 1rem 0 1rem', maxWidth: '350px' }}
          >
            {' '}
            <span style={{ color: '#0095C8', fontWeight: 'bold' }}>
              NFT
            </span>{' '}
            <p>Total : {nftCount}</p>
            <button
              style={{
                fontSize: '12px',
                borderRadius: '8px',
                maxWidth: 'fit-content'
              }}
              onClick={() => {
                setShowNft(!showNft);
              }}
            >
              VIEW NFT
            </button>
          </div>

          <div
            className="table"
            style={{ padding: '0 1rem 0 1rem', maxWidth: '350px' }}
          >
            <span style={{ color: '#0095C8', fontWeight: 'bold' }}>PECU</span>
            <p style={{ marginLeft: '1rem' }}>Total : {totalCoinsVault}</p>
          </div>

          {vaultTokens.map((e, i) => (
            <div
              key={i}
              className="table"
              style={{ padding: '0 1rem 0 1rem', maxWidth: '350px' }}
            >
              <span style={{ color: '#0095C8', fontWeight: 'bold' }}>
                {e.symbol}
              </span>
              <p style={{ marginLeft: '1rem' }}>Total :{e.vaultDeposit}</p>
            </div>
          ))}
          {/* <div
            onClick={() => {
              setShowProjectToken(!showProjectToken);
            }}
            className="table"
            style={{ padding: '0 1rem 0 1rem', maxWidth: '350px' }}
          >
            <span style={{ color: '#0095C8', fontWeight: 'bold' }}>
              Project Tokens
            </span>
            <p style={{ marginLeft: '1rem' }}>
              Total : {totalProjectToken.length}
            </p>
          </div> */}
          {/* <div
          
            className="table"
            style={{ padding: '0 1rem 0 1rem', maxWidth: '350px' }}
          >
            <span style={{ color: '#0095C8', fontWeight: 'bold' }}>
              Locked (wrapped Tokens)
            </span>
            <p style={{ marginLeft: '1rem' }}>
              Total :{' '}
              {totalLockedToken.reduce((a, b) => a + b.amount_locked, 0)}
            </p>
          </div> */}
        </div>

        <div
          className="main-sub-box"
          style={{
            height: '280px',
            minWidth: '400px',
            maxHeight: '280px',
            overflowY: 'auto'
          }}
        >
          <div className="total-coin-title" style={{ maxHeight: '22px' }}>
            LIQUIDITY POOL STAKE
          </div>
          {totalLockedToken.length > 0 &&
            totalLockedToken.map((e, i) => (
              <div
                key={i}
                className="table"
                style={{ padding: '0 1rem 0 1rem', maxWidth: '350px' }}
              >
                {' '}
                <span style={{ color: '#0095C8', fontWeight: 'bold' }}>
                  {e.symbol}
                </span>{' '}
                <p>Total : {e.amount_locked}</p>
                {/* <button
                  style={{
                    fontSize: '12px',
                    borderRadius: '8px',
                    maxWidth: 'fit-content'
                  }}
                  onClick={() => {
                    setShowNft(!showNft);
                  }}
                >
                  VIEW NFT
                </button> */}
              </div>
            ))}

          {totalProjectToken.length > 0 &&
            totalProjectToken.map((e, i) => (
              <div
                key={i}
                className="table"
                style={{ padding: '0 1rem 0 1rem', maxWidth: '350px' }}
              >
                {' '}
                <span style={{ color: '#0095C8', fontWeight: 'bold' }}>
                  {e.token_symbol}
                </span>{' '}
                <p>Total : {e.amount_issued}</p>
                {/* <button
                  style={{
                    fontSize: '12px',
                    borderRadius: '8px',
                    maxWidth: 'fit-content'
                  }}
                  onClick={() => {
                    setShowNft(!showNft);
                  }}
                >
                  VIEW NFT
                </button> */}
              </div>
            ))}
          {/* <div
            onClick={() => {
              setShowProjectToken(!showProjectToken);
            }}
            className="table"
            style={{ padding: '0 1rem 0 1rem', maxWidth: '350px' }}
          >
            <span style={{ color: '#0095C8', fontWeight: 'bold' }}>
              Project Tokens
            </span>
            <p style={{ marginLeft: '1rem' }}>
              Total : {totalProjectToken.length}
            </p>
          </div> */}
          {/* <div
          
            className="table"
            style={{ padding: '0 1rem 0 1rem', maxWidth: '350px' }}
          >
            <span style={{ color: '#0095C8', fontWeight: 'bold' }}>
              Locked (wrapped Tokens)
            </span>
            <p style={{ marginLeft: '1rem' }}>
              Total :{' '}
              {totalLockedToken.reduce((a, b) => a + b.amount_locked, 0)}
            </p>
          </div> */}
        </div>
      </div>
      <div
        className="total-coin-title"
        style={{ maxWidth: '60%', minWidth: '300px', padding: '10px' }}
      >
        SAFETY DEPOSIT BOX ACTIONS
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          maxWidth: '70%'
        }}
      >
        <div
          className="main-sub-box"
          style={{ minHeight: '70px', minWidth: '400px' }}
        >
          {' '}
          <div
            className="total-coin-title"
            style={{ maxWidth: '100%', padding: '1px', maxHeight: '20px' }}
          >
            DEPOSIT
          </div>
          {/* <FaCoins style={{margin:'1rem'}} size={40} color="goldenrod" /> */}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <button
              onClick={() => {
                setTransferModal(true);
              }}
              style={{
                width: '150px',
                maxWidth: '200px',
                padding: '5px',
                margin: '1rem'
              }}
            >
              PECU
            </button>
            <button
              onClick={() => {
                setDepositTokenModal(true);
              }}
              style={{
                width: '150px',
                maxWidth: '200px',
                padding: '5px',
                margin: '1rem'
              }}
            >
              TOKEN
            </button>
          </div>
        </div>
        <div
          className="main-sub-box"
          style={{ minHeight: '70px', minWidth: '400px' }}
        >
          {/* <FaCoins style={{margin:'1rem'}} size={40} color="goldenrod" /> */}
          <div
            className="total-coin-title"
            style={{ maxWidth: '100%', padding: '1px', maxHeight: '20px' }}
          >
            WITHDRAW
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <button
              onClick={() => {
                setWithdrawModal(true);
              }}
              style={{
                width: '150px',
                maxWidth: '200px',
                padding: '5px',
                margin: '1rem'
              }}
            >
              PECU
            </button>
            <button
              onClick={() => {
                setWithdrawTokenModal(true);
              }}
              style={{
                width: '150px',
                maxWidth: '200px',
                padding: '5px',
                margin: '1rem'
              }}
            >
              TOKEN
            </button>
          </div>
        </div>

        <div
          className="main-sub-box"
          style={{ minHeight: '50px', minWidth: '88%' }}
        >
          {/* <FaCoins style={{margin:'1rem'}} size={40} color="goldenrod" /> */}
          {/* <button
            onClick={() => {
              setWithdrawModal(true);
            }}
          >
            Withdraw
          </button> */}
          <div
            onClick={() => {
              setShowProjectToken(!showProjectToken);
            }}
            className="table"
            style={{ padding: '0 1rem 0 1rem', width: '90%' }}
          >
            <span style={{ color: '#0095C8', fontWeight: 'bold' }}>
              Project Tokens
            </span>
            <p style={{ marginLeft: '1rem' }}>
              Total : {totalProjectToken.length}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

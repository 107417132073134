/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import './sendCoins.css';
import axios from 'axios';
import { TiTick } from 'react-icons/ti';
import PrivacypolicyEscrow from '../../privacypolicyEscrow';
import url from '../../server_url';
export default function SendCoins({ userData }) {
  const [amount, setAmount] = useState(null);
  const [Recipient, setRecepient] = useState(null);
  const [privateNote, setPrivateNote] = useState('');
  const [note, setNote] = useState('');
  const [lockDate, setLockDate] = useState('');
  const [checkbox, setCheckbox] = useState(false);
  const [transactionSuccess, setTransactionSuccess] = useState(false);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const uid = userData?.user_id;

  const handleTransfer = () => {
    if (amount && Recipient) {
      axios
        .post(`${url}/wallet/send_coins`, {
          uid: uid,
          value: amount,
          to: Recipient,
          to_uid: null,
          note: note,
          matrix: null,
          lock_date: lockDate,
          private_note: privateNote
        })
        .then((res) => {
          if (res.data.status == 200) {
            setTransactionSuccess(true);
            setTimeout(() => {
              setAmount(null);
              setRecepient(null);
              setPrivateNote('');
              setNote('');
              setTransactionSuccess(false);
            }, 5000);
            setAmount(null);
            setRecepient(null);
            setPrivateNote('');
            setNote('');
          }
        })
        .catch((err) => alert(JSON.stringify(err)));
    } else {
      alert('please fill the required informations');
    }
  };

  return (
    <div className="sendCoins-main">
      {transactionSuccess ? (
        <div
          style={{
            margin: 'auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '1rem',
            border: '2px solid #0095C8'
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              width: '100%'
            }}
          >
            <TiTick color="green" size={50} />
            <h1> Transaction Successful</h1>
          </div>{' '}
          <button
            onClick={() => {
              setTransactionSuccess(!transactionSuccess);
            }}
          >
            Close
          </button>
        </div>
      ) : (
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setIsConfirmed((preState) => !preState);
          }}
        >
          {isConfirmed ? (
            <div className="QR-home">
              <div className="header-Qr">
                <span style={{ fontSize: '1.2rem' }}>Confirm Transaction</span>
              </div>
              <div className="QR"></div>
              <p>Please review your transaction details</p>
              <a className="public_key-href">Amount: {amount} PECU</a>
              <a className="public_key-href">Recipient: {Recipient} </a>

              <button onClick={() => handleTransfer()}>Proceed Transfer</button>
            </div>
          ) : null}
          <label>Amount (PECU)</label>
          <input
            required
            value={amount}
            placeholder="Amount"
            onChange={(e) => {
              setAmount(e.target.value);
            }}
          />
          <label>Recipient Public Key</label>
          <input
            placeholder="Recipient Public Key"
            required
            value={Recipient}
            onChange={(e) => {
              setRecepient(e.target.value);
            }}
          />
          <label>Public Note/Additional Info</label>
          <input
            placeholder="Note/Additional Info"
            value={note}
            onChange={(e) => {
              setNote(e.target.value);
            }}
          />
          <label
            style={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            MVault Escrow Smart Contract (optional)
            <span style={{ maxHeight: '200px', overflowY: 'auto' }}>
              <label>Agree to terms to proceed</label>
              <br></br> {/* {checkbox?<PrivacypolicyEscrow />:null}  */}
              <input
                placeholder="Escrow"
                type={'checkbox'}
                id="checkbox"
                value={checkbox}
                onChange={(e) => setCheckbox(!checkbox)}
              />
              <label>
                <a href="/escrowTermsOfService">
                  I agree to Escrow Terms & Conditions
                </a>{' '}
              </label>
            </span>
          </label>
          {checkbox ? (
            <input
              type={'date'}
              style={{ textTransform: 'uppercase' }}
              required={checkbox ? true : false}
              placeholder="Lock Coin Until"
              value={lockDate}
              onChange={(e) => {
                setLockDate(e.target.value);
              }}
            />
          ) : null}
          <label>Private Note / Additional Info (Optional)</label>
          <input
            placeholder="Note/Additional Info"
            value={privateNote}
            onChange={(e) => {
              setPrivateNote(e.target.value);
            }}
          />
          <button type="submit" className="primary">
            Transfer PECU{' '}
          </button>
        </form>
      )}
    </div>
  );
}

import React, { useState, useEffect } from 'react';
import './publicLedger.css';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import url from '../../server_url';
export default function CryptoLedger() {
  const [showModal, setShowModal] = useState(false);
  const [modaldata, setModaldata] = useState({});
  // const data=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
  const [data, setData] = useState([]);

  useEffect(() => {
    axios.get(`${url}/crypto/index`).then((res) => {
      setData(res.data);
    });
  }, []);

  useEffect(() => {
    setInterval(() => {
      axios.get(`${url}/crypto/index`).then((res) => {
        setData(res.data);
      });
    }, 5400);
  }, []);

  return (
    <div className="publicLedger-main">
      {/* <div className="total-coin-title" style={{fontSize:'1.5rem',fontWeight:'500'}}>CYRPTO LEDGER</div>
       */}
      {/* {showModal?
        <div className='QR-home' style={{backgroundColor:'black',width:'80%',justifyContent:'space-between'}}>
        <div className='header-Qr' style={{backgroundColor:'#0095C8'}}>
            <span style={{fontSize:'1.2rem',color:'white'}}>Public Ledger</span>
            <button className='close-btn' style={{width:'45px'}} onClick={()=>setShowModal(false)}>Close</button>
        </div>
        <h2 style={{color:'#0095C8'}}>Transaction Details</h2>
        <div className="row" style={{width:'85%',overflowX:'clip',color:'white'}} >Sender's Public Key:</div>
 
        <div className="row" style={{maxWidth:'85%',overflowX:'clip',color:'white',width:'85%'}} >{modaldata?.public_key}</div>
       <br></br>
       <div className="row" style={{width:'85%',overflowX:'clip',color:'white'}} >Value:</div>
       
        <div className="row" style={{color:'white',width:'85%'}}>{modaldata?.value}</div>
        <br></br>
        <div className="row" style={{width:'85%',overflowX:'clip',color:'white'}} >Receiver's Public Key:</div>
       
        <div className="row" style={{overflowX:'hidden',maxWidth:'85%',overflowX:'clip',color:'white',width:'85%'}}>{modaldata?.to_public_key}</div>
        <br></br>
        <div className="row" style={{width:'85%',overflowX:'clip',color:'white'}} >Date and Time of Tranaction:</div>
       
        <div className="row" style={{color:'white',width:'85%'}}>{new Date(modaldata?.date_time).toLocaleString() }</div>
      
          </div>
      :null} */}
      <div className="table total-coin-title">
        <div
          className="row"
          style={{ maxWidth: '150px', minWidth: '100px', overflowX: 'clip' }}
        >
          {'Sym'}
        </div>

        <div
          className="row"
          style={{ maxWidth: '150px', minWidth: '100px', overflowX: 'clip' }}
        >
          {'Market Cap'}
        </div>
        <div
          className="row"
          style={{ maxWidth: '150px', minWidth: '100px', overflowX: 'clip' }}
        >
          {'Price'}
        </div>
        <div
          className="row"
          style={{ maxWidth: '150px', minWidth: '100px', overflowX: 'clip' }}
        >
          {'24h Change'}
        </div>
        <div
          className="row"
          style={{ maxWidth: '150px', minWidth: '100px', overflowX: 'clip' }}
        >
          {'24h Volume'}
        </div>
        <div
          className="row"
          style={{ maxWidth: '150px', minWidth: '100px', overflowX: 'clip' }}
        >
          {'Supply'}
        </div>
      </div>
      <div style={{ width: '95vw' }}>
        {data.length > 0
          ? data.map((e, index) => (
              <div
                className="table"
                onClick={() => {
                  setShowModal(true);
                  setModaldata(e);
                }}
              >
                <div
                  className="row"
                  style={{
                    maxWidth: '150px',
                    minWidth: '100px',
                    overflowX: 'clip'
                  }}
                >
                  {e.symbol}
                </div>

                <div
                  className="row"
                  style={{
                    maxWidth: '150px',
                    minWidth: '100px',
                    overflowX: 'clip'
                  }}
                >
                  {parseFloat(e.market_cap).toLocaleString('en-US')}
                </div>
                <div
                  className="row"
                  style={{
                    maxWidth: '150px',
                    minWidth: '100px',
                    overflowX: 'clip'
                  }}
                >
                  {e.price}
                </div>
                <div
                  className="row"
                  style={{
                    maxWidth: '150px',
                    minWidth: '100px',
                    overflowX: 'clip'
                  }}
                >
                  {e.change24hr}
                </div>
                <div
                  className="row"
                  style={{
                    maxWidth: '150px',
                    minWidth: '100px',
                    overflowX: 'clip'
                  }}
                >
                  {e.volume24hr}
                </div>
                <div
                  className="row"
                  style={{
                    maxWidth: '150px',
                    minWidth: '100px',
                    overflowX: 'clip'
                  }}
                >
                  {e.circu_supply}
                </div>
              </div>
            ))
          : 'Loading'}
      </div>
      <div
        className="table"
        style={{ padding: '10px', fontSize: '12px', color: 'lightgrey' }}
      >
        <p>
          <span style={{ color: 'red' }}>Disclaimer</span> : Data is provided by
          exchanges and data providers may be delayed as specified by exchanges
          or our data providers.
        </p>

        <p style={{ fontSize: '10px', color: 'lightgrey' }}>
          MegaHoot Technologies, Inc may disclaim the accuracy, adequacy, or
          completeness of any data and shall not be liable for any errors,
          omissions or other defects in, delays or interruptions in such data,
          or for any actions taken in reliance thereon. Megahoot will not be
          liable for any damages relating to your use of the information
          provided herein.
        </p>
        <p style={{ fontSize: '10px  ', color: 'lightgrey' }}>
          It is agreed upon that this is informational in nature and no one has
          the right to copy, modify, reformat, download, store, reproduce,
          reprocess, transmit or redistribute any data or information found
          herein or use any such data or information in a commercial enterprise
          without obtaining prior written consent. All data and information is
          provided “as is” for personal informational purposes only, and is not
          intended for trading purposes or advice. Please consult your broker or
          financial representative to verify pricing before executing any trade.
        </p>
        <p style={{ fontSize: '10px', color: 'lightgrey' }}>
          MegaHoot Technologies, Inc cannot guarantee the accuracy of the
          exchange rates displayed. You should confirm current rates before
          making any transactions that could be affected by changes in the
          exchange rates.
        </p>
      </div>
    </div>
  );
}

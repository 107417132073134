import React from 'react';
import './footer.css';
import { FaRegCopyright } from 'react-icons/fa';
export default function Footer() {
  return (
    <div className="footer">
      <p style={{ fontWeight: '400', fontSize: '11px' }}>
        Pecu Terminal is managed by MegaHoot, LLC. All information provided
        herein is believed to come from reliable sources but there is no
        guarantee to its accuracy. No content, opinions or other media herein
        constitutes to be a solicitation to buy or sell securities, commodities
        or cryptocurrencies.
      </p>
      <div style={{ width: '100%' }}>
        <div style={{ width: '100%' }}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexDirection: 'row',
              fontSize: '11px',
              width: '100%',
              fontWeight: '300'
            }}
          >
            {' '}
            <span>
              <FaRegCopyright />
              {new Date().getFullYear()} Pecu Novus. All Rights Reserved.
            </span>
            <div>
              <a
                href="/TermsOfService"
                style={{ color: 'grey', fontSize: '11px', margin: '5px' }}
              >
                TOS
              </a>
              <a
                href="/PrivacyPolicy"
                style={{ color: 'grey', fontSize: '11px', margin: '5px' }}
              >
                PRIVACY POLICY
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';

export default function ProjectToken({ closeForm, data }) {
  console.log(data);
  return (
    <div className="nft-modal">
      <button
        onClick={() => {
          closeForm(false);
        }}
        style={{
          position: 'fixed',
          width: '80px',
          right: '10px',
          top: '30px'
        }}
      >
        Close
      </button>

      <div
        style={{
          position: 'absolutes',
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {/* {data.map((nft, index) => {
        return (
          <div className="nft-box" style={{ width: '300px' }}>
            <h3>Transfer {nft.token_symbol}</h3>

            

            <img src={nft.img} width={'100px'} />
            <p>{nft.name}</p>
            <p style={{ fontSize: '12px' }}>{nft.description}</p>
            <p style={{ fontSize: '12px' }}>Price: {nft.price} PECU</p>
            <p style={{ fontSize: '12px' }}>
              Minted on : {new Date(nft.date_time).toLocaleDateString()}{' '}
              {new Date(nft.date_time).toLocaleTimeString()}
            </p>
         
            <button
              style={{ width: '300px' }}
              onClick={() => {
                closeForm();
              }}
            >
              Cancel
            </button>
          </div>
        );
      })} */}
      </div>
      {data.length == 0 && (
        <div>
          {' '}
          <h3>No Available Tokens </h3>
          <button
            onClick={() => {
              closeForm(false);
            }}
            style={{
              backgroundImage: `linear-gradient(160deg,#000000 0%,red  100%)`,
              width: '200px'
            }}
          >
            Close
          </button>
        </div>
      )}
      {data.map((nft, index) => {
        return (
          <div key={index} className="nft-box" style={{ minWidth: '300px' }}>
            <p>Symbol : {nft.token_symbol}</p>

            <img
              src={`${'https://api.pecunovus.net'}/hootdex/images/${nft?.img}`}
              width={'100px'}
            />
            <p>Name : {nft.token_name}</p>
            <p style={{ fontSize: '12px', lineHeight: '25px' }}>
              <h5>Description</h5>
              {nft.token_desc}
            </p>
            <p style={{ fontSize: '12px' }}>Price: {nft.token_price} USD</p>
            <p style={{ fontSize: '12px' }}>Amount: {nft.amount_issued}</p>

            <p style={{ fontSize: '12px' }}>{nft.description}</p>
            <p style={{ fontSize: '12px' }}>
              Created on : {new Date(nft.date_time).toLocaleDateString()}{' '}
              {new Date(nft.date_time).toLocaleTimeString()}
            </p>
          </div>
        );
      })}
    </div>
  );
}

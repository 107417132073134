import axios from 'axios';
import React, { useEffect, useState } from 'react';
import url from '../server_url';
export default function ConversionTokens({ user, closeMe, updatepubkey }) {
  const [data, setData] = useState([]);
  const [pecuAmount, setPecuAmount] = useState(1);
  const [wrapAmount, setWrapAmount] = useState(1);
  const [pecuValue, setPecuValue] = useState(0);
  const [selectedWrap, setSelectedWrap] = useState('BTC');
  const [finalPrice, setFinalPrice] = useState(0);
  const [cryptoRate, setCryptorate] = useState(0);
  const [projectTokens, setProjectTokens] = useState([]);
  const [email, setEmail] = useState('');
  const [projects, setProjects] = useState([]);
  const [input, setInput] = useState({
    issuer: '',
    name: '',
    symbol: '',
    desc: '',
    decimal_places: '',
    amount_issued: '',
    token_price: '',
    launch_price: '',
    mvault_capable: false,
    escrow_capable: false,
    liquidityPool_capable: false,
    pecu_500: false,
    hootdex_listing: false,
    owner_email: '',
    fileName: '',
    projects: []
  });

  const handleInputSubmission = (e) => {
    const name = e.target.name;
    let value = e.target.value;
    let prevInput = input;

    setInput({ ...prevInput, [name]: value });
  };
  useEffect(() => {
    axios.get(`${url}/crypto/index`).then((res) => {
      setData(res.data);
    });
  }, []);

  useEffect(() => {
    let pecuLocalRate =
      data.filter((e) => e.symbol == 'PECU').map((e) => e.price) || 1;
    let wrapPrice =
      data.filter((e) => e.symbol == selectedWrap).map((e) => e.price) || 1;

    let final =
      parseFloat(pecuLocalRate) * pecuAmount +
      parseFloat(wrapPrice) * wrapAmount;

    setPecuValue(pecuLocalRate);
    setFinalPrice(final);
    setCryptorate(wrapPrice);
  }, [selectedWrap, pecuAmount, data, pecuAmount, wrapAmount]);

  const updatepubkeyFun = (id) => {
    axios
      .post(`${url}/wallet/update-public-key`, {
        user_id: id
      })
      .then((res) => {
        if (res.data.status == 200) {
          updatepubkey(res.data.pub_key);
        }
      })
      .catch((err) => console.log(err));
  };

  const submitHandler = (e) => {
    e.preventDefault();

    const bodyData = {
      issuer: input.issuer,
      name: input.name,
      symbol: input.symbol + 'c',
      desc: input.desc,
      decimal_places: input.decimal_places,
      amount_issued: input.amount_issued,
      token_price: projects[0]?.token_price,
      launch_price: projects[0]?.token_price,
      mvault_capable: input.mvault_capable,
      escrow_capable: input.escrow_capable,
      liquidityPool_capable: input.liquidityPool_capable,
      pecu_500: input.pecu_500,
      hootdex_listing: input.hootdex_listing,
      owner_email: input.owner_email,
      image: `${url}/hootdex/images/${input.fileName}`,
      projects: JSON.stringify(projects),
      isConversionToken: true
    };
    // for (const key in bodyData) {
    //   if (!bodyData[key] || bodyData[key] == '') {
    //     alert(`${key} should not be empty`);
    //     return;
    //   }
    // }
    axios
      .post(`${url}/wallet/create_holding_token`, bodyData)
      .then((res) => {
        if (res.data.status) {
          updatepubkeyFun(user.user_id);
          alert(res.data.message);
          closeMe();
        } else {
          alert(res.data.message);
        }
      })
      .catch((err) => alert(err.message));
  };

  const saveFile = (e) => {
    const formData = new FormData();
    formData.append('image', e.target.files[0]);
    formData.append('fileName', e.target.files[0].name);
    axios.post(`${url}/hootdex/token-logo-upload`, formData).then((res) => {
      if (res.data.status === 'ok') {
        setInput({ ...input, fileName: res.data.fileName });
      }
    });
  };

  const fetchProjectTokens = () => {
    axios.get(`${url}/wallet/get_all_tokens_holding`).then((res) => {
      setProjectTokens(res.data.tokens);
    });
  };

  const handleChangeProject = (e) => {
    let value = e.target.value;
    let pt = projectTokens.filter((e) => e.token_symbol === value);

    if (pt) {
      setInput({
        ...input,
        token_price: pt[0].token_price,
        launch_price: pt[0].token_price
      });
      setProjects(pt);
    }
  };
  useEffect(() => {
    fetchProjectTokens();
  }, []);

  return (
    <div className="sendCoins-main">
      {' '}
      <div
        className="total-coin-title"
        style={{ fontSize: '1.5rem', fontWeight: '500' }}
      >
        Create Conversion Token-Smart Contract
      </div>
      <form onSubmit={submitHandler}>
        {/* <label>Select Wrap Token</label>
        <select
          value={selectedWrap}
          style={{ padding: '1rem', width: '80%' }}
          onChange={(e) => {
            setSelectedWrap(e.target.value);
          }}
        >
          {data &&
            data.map((e, i) => (
              <option key={i} value={e.symbol}>
                {e.symbol}
              </option>
            ))}
        </select> */}
        <label>Token Issuer</label>
        <input
          name="issuer"
          value={input.issuer}
          onChange={handleInputSubmission}
          required
        />
        <label>Token Name</label>
        <input
          name="name"
          value={input.name}
          onChange={handleInputSubmission}
          required
        />
        {/* <label>Selected Token Price</label>
        <input
          value={
            '$ ' +
            data.filter((e) => e.symbol == selectedWrap).map((e) => e.price)
          }
          disabled
        /> */}
        {/* <label>
          Additional Amount (
          <span style={{ color: '#1C3FAC', fontWeight: 'bold' }}>PECU</span>)
        </label>
        <input
          required
          min={1}
          value={pecuAmount}
          onChange={(e) => {
            e.target.value >= 1 && setPecuAmount(e.target.value);
          }}
          type={'number'}
        /> */}
        <label>
          Token Symbol{' '}
          <span style={{ color: '#0095C8', fontWeight: 'bold' }}>
            {input.symbol + 'c'}
          </span>
        </label>
        <input
          name="symbol"
          value={input.symbol}
          onChange={handleInputSubmission}
          required
        />
        <label>Description of Holding Token</label>
        <textarea
          name="desc"
          value={input.desc}
          onChange={handleInputSubmission}
        />
        <label>Token Decimal Places</label>
        <input
          name="decimal_places"
          onChange={handleInputSubmission}
          required
          value={input.decimal_places}
        />

        <label>
          Amount to be Issued:
          {/* <span style={{ color: '#0095C8', fontWeight: 'bold' }}>
            {selectedWrap}
          </span>
          ) Tokens */}
        </label>
        <input
          required
          min={1}
          name="amount_issued"
          //   value={wrapAmount}
          //   onChange={(e) => {
          //     e.target.value >= 1 && setWrapAmount(e.target.value);
          //   }}
          value={input.amount_issued}
          onChange={handleInputSubmission}
          type={'number'}
        />
        <label>Set Token Price</label>
        <input
          //   value={finalPrice}
          //   onChange={(e) => {
          //     setFinalPrice(e.target.value);
          //   }}
          disabled
          name="token_price"
          type={'number'}
          value={input.token_price}
          onChange={handleInputSubmission}
        />

        <label>Launch Price:</label>

        <input
          name="launch_price"
          value={input.token_price}
          onChange={handleInputSubmission}
          disabled
        />
        <label>MVault Capable:</label>
        <br></br>
        <select
          style={{
            padding: '0.5rem',
            width: '82%',
            marginTop: '10px 0 10px 0'
          }}
          name="mvault_capable"
          onChange={handleInputSubmission}
        >
          <option>select</option>
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>
        <br></br>
        <label>Escrow Capable:</label>
        <br></br>
        <select
          style={{
            padding: '0.5rem',
            width: '82%',
            marginTop: '10px 0 10px 0'
          }}
          name="escrow_capable"
          onChange={handleInputSubmission}
        >
          {' '}
          <option>select</option>
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>
        <br></br>
        <label>Liqudity Pool Capable:</label>
        <br></br>
        <select
          style={{
            padding: '0.5rem',
            width: '82%',
            marginTop: '10px 0 10px 0'
          }}
          name="liquidityPool_capable"
          onChange={handleInputSubmission}
        >
          {' '}
          <option>select</option>
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>
        <br></br>
        <label>Cost $500 worth of Pecu:</label>
        <br></br>
        <select
          style={{
            padding: '0.5rem',
            width: '82%',
            marginTop: '10px 0 10px 0'
          }}
          name="pecu_500"
          onChange={handleInputSubmission}
        >
          {' '}
          <option>select</option>
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>
        <br></br>
        <label>HootDex listing</label>
        <br></br>
        <select
          style={{
            padding: '0.5rem',
            width: '82%',
            marginTop: '10px 0 10px 0'
          }}
          name="hootdex_listing"
          onChange={handleInputSubmission}
        >
          {' '}
          <option>select</option>
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>
        <br></br>
        <label>Owner Email Address</label>

        <input
          style={{ textTransform: 'lowercase' }}
          required
          placeholder="Email"
          type={'email'}
          name="owner_email"
          value={input.owner_email}
          onChange={handleInputSubmission}
        />

        <label className="label">Select Holding Tokens</label>
        {projectTokens.map((e, i) => (
          <span
            key={i}
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              width: '100px'
            }}
          >
            <input
              type={'checkbox'}
              style={{ height: '18px' }}
              value={e.token_symbol}
              onChange={handleChangeProject}
            />{' '}
            <label className="label">{e.token_symbol}</label>
          </span>
        ))}

        <label className="label">Upload token logo</label>
        <input
          className="input"
          onChange={saveFile}
          type="file"
          placeholder="Enter"
        ></input>
        <button type="submit" className="primary">
          Create
        </button>
      </form>
    </div>
  );
}
